import { useMutation } from '@tanstack/react-query'

import type { AccountInfoData } from '../../types/Dashboard'
import type { User } from '../../types/User'
import { auth } from '../../config/firebase'

interface AccountSettingsProps {
  user: User
  data: Partial<AccountInfoData>
}

const updateAccountSettings = async ({ user, data }: AccountSettingsProps) => {
  const token: string = await auth.currentUser.getIdToken()

  const dataToUpdate: any = {
    first_name: data.firstName,
    last_name: data.lastName,
    preferred_first_name: data.preferredName,
  }

  if (data.phoneNumber) dataToUpdate.phone_number = data.phoneNumber

  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/users/${user.data.id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user: dataToUpdate }),
    }
  ).then((response) => response.json())

  if (result?.error) throw new Error('Something went wrong.')
}

export const useSetAccountSettings = () =>
  useMutation((params: AccountSettingsProps) => updateAccountSettings(params))
