import type { Therapist } from './Therapist'

export type UserWhoScheduled = {
  name: string
  id: string
}

export type Session = {
  patientId: string
  patientName: string
  type: string
  therapist: Therapist
  zoomLink: string
  startTime: string
  endTime: string
  status: string
  rating: number | null
  id: string
  carePlanId: string
  conditionId: string
  canceledAt: string
  name: string
  scheduledBy?: UserWhoScheduled
  sessionType?: string
}

export const mapSessionStatus = (status: string, isIep: boolean): string => {
  if (isIep)
    switch (status) {
      case 'completed':
      case 'completed_makeup_session':
        return 'completed'
      case 'no_show':
        return 'absent'
      case 'pending':
        return 'pending'
      case 'canceled_by_patient':
      case 'canceled_by_therapist':
      case 'tech_trouble_cancellation':
      case 'canceled_no_payment':
        return 'cancelled'
    }
  else
    switch (status) {
      case 'completed':
      case 'completed_makeup_session':
        return 'completed'
      case 'no_show':
        return 'no show'
      case 'pending':
        return 'pending'
      case 'canceled_by_patient':
      case 'canceled_by_therapist':
      case 'tech_trouble_cancellation':
      case 'canceled_no_payment':
        return 'cancelled'
    }
}

export const mapSessionName = (
  therapyType: string,
  sessionType: string
): string => {
  return `${therapyType} ${
    Boolean(sessionType) ? sessionType?.replace('_', ' ') : 'Session'
  }`
}

/*
  session types from backend

  unassigned: 0, 
  free_intake: 1, 
  assessment: 2, 
  direct_session: 3, 
  re_assessment: 4, 
  indirect_session: 5, 
  consult: 6, 
  compensatory_time_session: 7, 
  screening: 8, 
  rti: 9
*/

/*
  session statuses from backend

  pending: STATUS_PENDING,
  completed: STATUS_COMPLETED,
  canceled_by_patient: STATUS_CANCELED_BY_PATIENT,
  canceled_by_therapist: STATUS_CANCELED_BY_THERAPIST,
  no_show: STATUS_NO_SHOW,
  completed_makeup_session: STATUS_COMPLETED_MAKEUP_SESSION,
  created_in_error: STATUS_CREATED_IN_ERROR,
  iep_meeting_attendance: STATUS_IEP_MEETING_ATTENDANCE,
  direct_supervision: STATUS_DIRECT_SUPERVISION,
  tech_trouble_cancellation: STATUS_TECH_TROUBLE_CANCELLATION,
  canceled_no_payment: CANCELED_NO_PAYMENT
*/
