import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface SaveAppointmentProps {
  start: string
  end: string
  carePlanId: string
}

const createNewAppointment = async ({
  carePlanId,
  start,
  end,
}: SaveAppointmentProps): Promise<any> => {
  const token: string = await auth.currentUser.getIdToken()

  // make the appointment
  const result = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_URL
    }api/v1/care_plans/${carePlanId}/appointments`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        appointment: {
          start,
          end,
        },
      }),
    }
  ).then((response) => response.json())

  if (result?.error) throw new Error('Something went wrong.')

  return result
}

export const useSaveAppointment = () =>
  useMutation((params: SaveAppointmentProps) => createNewAppointment(params))
