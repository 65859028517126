import { useState, type FC, useEffect } from 'react'

import { UseGetAllowedToBookHistory } from '../../queries/booking/UseGetAllowedToBookHistory'
import { useAuth } from '../../contexts/AuthProvider'
import { usePatient } from '../../contexts/PatientProvider'
import handleClickSupportEmail from '../../helpers/supportClick'

// DCT-2933
const NotAllowedToBookAnymoreSessions: FC = () => {
  const { patient } = usePatient()
  const { user } = useAuth()
  const [shouldShow, setShouldShow] = useState<boolean>(false)

  const {
    data: allowedToBookHistories,
    isLoading: isLoadingAllowedToBookHistories,
  } = UseGetAllowedToBookHistory({
    patients: user.roster,
  })

  useEffect(() => {
    if (!user?.roster?.length || isLoadingAllowedToBookHistories || !patient)
      return

    // This messaging should show when the current page patient was allowed to book sessions and then lost their ability to book sessions
    setShouldShow(
      (!patient.allowedToBook &&
        allowedToBookHistories[patient.id]?.[0]?.changedTo === false &&
        allowedToBookHistories[patient.id]?.[1]?.changedTo === true) ||
        (user.data.clientData.requireEligibility &&
          !patient.isEligible &&
          !patient.isIep)
    )
  }, [user, patient, isLoadingAllowedToBookHistories, allowedToBookHistories])

  if (!shouldShow) return <></>

  return (
    <div className="flex flex-col gap-2 rounded-2xl bg-background-offwhite p-4 sm:mb-12">
      {patient?.relationship?.key === 'myself' ? (
        <p className="text-sm font-semibold sm:text-base">
          You cannot schedule any therapy sessions at this time.
        </p>
      ) : (
        <p className="text-sm font-semibold sm:text-base">
          You cannot schedule any therapy sessions for{' '}
          <span className="text-cta-default">{patient.firstName}</span> at this
          time.
        </p>
      )}
      <p className="text-xs font-medium sm:text-sm">
        Please contact your school or organization regarding the service
        eligibility. For all other questions, contact{' '}
        <a
          href="mailto:support@huddleupcare.com"
          className="font-medium text-cta-default underline "
          onClick={() => handleClickSupportEmail(user, location)}
        >
          support@huddleupcare.com
        </a>
        .
      </p>
    </div>
  )
}

export default NotAllowedToBookAnymoreSessions
