import React, { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { flushSync } from 'react-dom'

import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import AppLogo from '../../../components/AppLogo'
import { useRegister } from '../../../contexts/RegisterContext'
import type { EligibilityInfo } from '../../../types/Register'
import {
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../../constants/classConstants'
import Tooltip from '../../../components/Tooltip'
import type { VerifyEligibilityResponse } from '../../../mutations/eligibility/VerifyUserEligibility'
import { useVerifyEligibility } from '../../../mutations/eligibility/VerifyUserEligibility'
import { DEFAULT_DATE_REGEX } from '../../../constants/regex'
import DatePicker from '../../../components/DatePicker'
import { calculateAge, isValidDefaultDate } from '../../../helpers/generic'
import { useGetClientInfo } from '../../../queries/onboarding/GetClientInfo'
import { useAuth } from '../../../contexts/AuthProvider'

const formSchema = (
  is4thFieldEmail: boolean,
  isEmployer: boolean,
  forSelf: boolean
) => {
  let schema = Yup.object().shape({
    firstName: Yup.string().required('First name is required.').trim(),
    lastName: Yup.string().required('Last name is required.').trim(),
    birthDate: Yup.string()
      .required('Birthdate is required.')
      .matches(
        DEFAULT_DATE_REGEX,
        'Birthdate must be in valid MM/DD/YYYY format.'
      )
      .test('valid-date', 'Birthdate is not valid.', (enteredDate: string) =>
        isValidDefaultDate(enteredDate)
      )
      .test(
        'test-birth-date-future',
        'Birthdate cannot be in the future.',
        (enteredDate: string) => {
          const date = new Date(enteredDate)
          const now = new Date()
          return date <= now
        }
      )
      .test(
        'test-under-18',
        'Those who are younger than 18 must have their parent/guardian create their account.',
        (enteredDate: string) => {
          const age = calculateAge(new Date(enteredDate))
          return forSelf ? age >= 18 : true
        }
      )
      .trim(),
  })

  if (is4thFieldEmail)
    schema = schema.concat(
      Yup.object().shape({
        externalId: Yup.string().required('Email is required.').trim(),
      })
    )
  else
    schema = schema.concat(
      Yup.object().shape({
        externalId: Yup.string()
          .required(
            isEmployer ? 'Employee ID is required.' : 'Student ID is required.'
          )
          .trim(),
      })
    )

  return schema
}

const VerifyEligibility: React.FC = () => {
  const navigate = useNavigate()
  const { forSelf: userForSelf, setUserInfo } = useRegister()
  const { data: clientInfo } = useGetClientInfo()
  const is4thFieldEmail: boolean = clientInfo?.importer4thId.includes('EMAIL')
  const isEmployer: boolean = clientInfo?.clientType === 'EMPLOYER'
  const forSelf: boolean = isEmployer || userForSelf

  if (!clientInfo?.requireEligibility) return <Navigate to="/sign-in" />

  return (
    <div className="flex max-w-lg flex-col gap-8 text-text-primary">
      {/* Header */}
      <AppLogo classes="flex self-center" logoWithText />
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="whitespace-pre-wrap text-center text-lg font-semibold">
          Let's verify {forSelf ? 'your' : 'their'} eligibility
        </p>
        <p className="text-center text-sm">
          {clientInfo?.clientType === 'EMPLOYER'
            ? `Please provide the following information as it would appear on file with your employer`
            : ` Please provide the following student information as it would appear on
            school files`}
        </p>
      </div>

      <EligibilityForm
        userForSelf={userForSelf}
        onSuccess={(userInfo: EligibilityInfo) => {
          flushSync(() => {
            setUserInfo({ ...userInfo })
            if (clientInfo?.clientType === 'EMPLOYER' && is4thFieldEmail)
              navigate('/sign-up/eligibility-verified')
            else navigate('/sign-up/create-login')
          })
        }}
        onFailRedirectUrl="/sign-up/verify/invalid"
      />

      {/* Already have an account */}
      <div className="flex justify-center sm:col-span-2">
        <Link to={'/sign-in'} className="text-center underline">
          I already have an account
        </Link>
      </div>
    </div>
  )
}

export default VerifyEligibility

export const EligibilityForm: React.FC<{
  onSuccess: (data: EligibilityInfo) => void
  onFailRedirectUrl: string
  userForSelf: boolean
  isLoadingExternal?: boolean
}> = ({ onSuccess, onFailRedirectUrl, userForSelf, isLoadingExternal }) => {
  const location = useLocation()
  const { user } = useAuth()
  const { data: clientInfo } = useGetClientInfo()
  const navigate = useNavigate()
  const is4thFieldEmail: boolean = clientInfo?.importer4thId.includes('EMAIL')
  const isEmployer: boolean = clientInfo?.clientType === 'EMPLOYER'
  const forSelf: boolean = isEmployer || userForSelf
  const { mutate: callVerify, isLoading } = useVerifyEligibility()
  const [errorCounter, setErrorCounter] = useState<number>(0)
  const [eligibilityError, setEligibilityError] = useState<string>(null)

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty, errors },
  } = useForm<EligibilityInfo>({
    mode: 'all',
    resolver: yupResolver(formSchema(is4thFieldEmail, isEmployer, forSelf)),
  })

  const submit = (userInfo: EligibilityInfo) =>
    callVerify(
      { userInfo, clientId: clientInfo?.clientId },
      {
        onError: () => {
          setEligibilityError(
            "Hmm, something's not right. Please make sure that the information you've entered matches what's on file with the school/organization."
          )

          if (errorCounter > 0)
            navigate(onFailRedirectUrl, { state: { userInfo } })

          setErrorCounter(errorCounter + 1)
        },
        onSuccess: (data: VerifyEligibilityResponse) => {
          // check for duplicate patient
          if (
            Boolean(user) &&
            user?.roster?.some((p) => p.personId === data.personId)
          )
            setEligibilityError(
              'Looks like this person is already added to your roster.'
            )
          else
            onSuccess({
              ...userInfo,
              isIep: data.isIep,
              locationId: data.locationId,
              personId: data.personId,
            })
        },
      }
    )

  return (
    <form
      onSubmit={handleSubmit((data: EligibilityInfo) => submit(data))}
      className="grid w-full grid-cols-2 gap-4 xs:px-4"
    >
      {/* First name */}
      <div className="xs:col-span-2">
        <label
          htmlFor="firstName"
          className="block text-base font-semibold text-text-primary xs:text-sm"
        >
          {forSelf ? 'First name' : "Child's first name"}
        </label>
        <div className="relative mt-1">
          <input
            type="text"
            className={inputValidClass}
            placeholder="First"
            {...register('firstName')}
          />
          {errors.firstName && (
            <p className="mt-1 text-sm text-status-error">
              {errors.firstName.message}
            </p>
          )}
        </div>
      </div>

      {/* Last name */}
      <div className="xs:col-span-2">
        <label
          htmlFor="lastName"
          className="block text-base font-semibold text-text-primary xs:text-sm"
        >
          {forSelf ? 'Last name' : "Child's last name"}
        </label>
        <div className="relative mt-1">
          <input
            type="text"
            className={inputValidClass}
            placeholder="Last"
            {...register('lastName')}
          />
          {errors.lastName && (
            <p className="mt-1 text-sm text-status-error">
              {errors.lastName.message}
            </p>
          )}
        </div>
      </div>

      {/* Date of birth */}
      <div className="xs:col-span-2">
        <label
          htmlFor="birthDate"
          className="block text-base font-semibold text-text-primary xs:text-sm"
        >
          {forSelf ? 'Date of birth' : "Child's date of birth"}
        </label>
        <div className="relative mt-1">
          <DatePicker
            setValue={setValue}
            control={control}
            fieldKey="birthDate"
          />
        </div>
        {errors.birthDate && (
          <p className="mt-2 text-sm text-status-error">
            {errors.birthDate.message}
          </p>
        )}
      </div>

      {/* Student ID or Email */}
      {!is4thFieldEmail ? (
        <div className="xs:col-span-2">
          <label
            htmlFor="externalId"
            className="flex items-center gap-1 text-base font-semibold text-text-primary xs:text-sm"
          >
            {clientInfo?.clientType === 'EMPLOYER'
              ? 'Employee ID'
              : 'Student ID'}{' '}
            {clientInfo?.clientType === 'EDUCATION' && (
              <Tooltip
                content="You can find this information on the school ID card, school portal, or from the school administrators."
                position="right"
              >
                <InformationCircleIcon className="h-4 w-4" />
              </Tooltip>
            )}
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={inputValidClass}
              placeholder="Enter"
              {...register('externalId')}
            />
            {errors.externalId && (
              <p className="mt-1 text-sm text-status-error">
                {errors.externalId.message}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="xs:col-span-2">
          <label
            htmlFor="externalId"
            className="flex items-center gap-1 text-base font-semibold text-text-primary xs:text-sm"
          >
            {clientInfo?.clientType === 'EMPLOYER'
              ? 'Work email'
              : 'Student email'}
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={inputValidClass}
              placeholder="Enter"
              {...register('externalId')}
            />
            {errors.externalId && (
              <p className="mt-1 text-sm text-status-error">
                {errors.externalId.message}
              </p>
            )}
          </div>
        </div>
      )}

      {eligibilityError && (
        <div className="col-span-2 mt-2 flex items-start gap-1">
          <div>
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
          <p className="text-xs text-status-error">{eligibilityError}</p>
        </div>
      )}

      {/* Buttons */}
      <div className="col-span-2 my-8 flex w-full items-center">
        <button
          type="button"
          className={`mr-2 w-1/2 ${tertiaryButtonClass}`}
          onClick={() => navigate(location?.state?.from || -1)}
          disabled={isLoading || isLoadingExternal}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Back
        </button>
        <button
          type="submit"
          className={`w-1/2 ${primaryButtonClass}`}
          disabled={
            !isValid ||
            !isDirty ||
            isLoading ||
            !clientInfo?.clientId ||
            isLoadingExternal
          }
        >
          {isLoading || isLoadingExternal ? (
            <>
              <RefreshIcon
                className="loader h-5 w-5 text-white"
                aria-hidden="true"
              />
              Loading
            </>
          ) : (
            <>
              Next
              <ChevronRightIcon className="h-5 w-5 text-white" />
            </>
          )}
        </button>
      </div>
    </form>
  )
}
