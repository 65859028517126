import React from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/solid'

import AppLogo from '../../../components/AppLogo'
import {
  panelClass,
  panelClassActive,
  primaryButtonClass,
} from '../../../constants/classConstants'
import AVATAR from '../../../assets/avatars/User.svg'
import AVATAR_CHILD from '../../../assets/avatars/Child1.svg'
import { useRegister } from '../../../contexts/RegisterContext'
import { useGetClientInfo } from '../../../queries/onboarding/GetClientInfo'

const GetStarted: React.FC = () => {
  const { data: clientInfo } = useGetClientInfo()
  const { forSelf, setForSelf } = useRegister()
  const navigate = useNavigate()

  if (!clientInfo?.requireEligibility) return <Navigate to="/sign-in" />

  return (
    <div className="flex flex-col gap-8 text-text-primary">
      {/* Header */}
      <AppLogo classes="flex self-center" logoWithText />
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="whitespace-pre-wrap text-center text-lg font-semibold">
          Who is the care for?
        </p>
        <p className="text-center text-sm">Select one to continue</p>
      </div>

      {/* Choose care */}
      <div className="flex flex-col justify-center gap-4">
        <button
          className={`${
            forSelf === true ? panelClassActive : ''
          } ${panelClass}`}
          onClick={() => setForSelf(true)}
        >
          <img src={AVATAR} alt="self" className="h-18 w-18 xs:h-14 xs:w-14" />
          <p className="self-center text-left text-base font-semibold text-text-primary xs:text-sm">
            Myself
          </p>
        </button>

        <button
          className={`${
            forSelf === false ? panelClassActive : ''
          } ${panelClass}`}
          onClick={() => setForSelf(false)}
        >
          <img
            src={AVATAR_CHILD}
            alt="child"
            className="h-18 w-18 xs:h-14 xs:w-14"
          />
          <p className="self-center text-left text-base font-semibold text-text-primary xs:text-sm">
            My child or someone else
          </p>
        </button>
      </div>

      {/* Next */}
      <button
        type="submit"
        disabled={forSelf === null}
        className={`${primaryButtonClass} w-full`}
        onClick={() => navigate('/sign-up/verify/eligibility')}
      >
        Next
        <ChevronRightIcon className="h-5 w-5 text-white" />
      </button>

      {/* Already have an account */}
      <div className="flex justify-center sm:col-span-2">
        <Link to={'/sign-in'} className="text-center underline">
          I already have an account
        </Link>
      </div>
    </div>
  )
}

export default GetStarted
