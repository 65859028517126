import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { PlayIcon } from '@heroicons/react/outline'
import ReactPlayer from 'react-player'
import cloneDeep from 'lodash.clonedeep'

import { useGetCourseForPreview } from '../../../queries/courses/GetCourseForPreview'
import BookmarkButton from '../components/BookmarkButton'
import COURSES_ICON from '../../../assets/icons/CoursesVideoIcon.svg'
import {
  primaryButtonClassSmall,
  secondaryButtonClassSmall,
  tertiaryButtonClassSmall,
} from '../../../constants/classConstants'
import { useGetCourseRecommendations } from '../../../queries/courses/GetCourseRecommendations'
import CoursesGridDisplay from './CourseGridDisplay'
import type {
  Course,
  CoursePreviewVideo,
  CourseProgress,
  PreviewCourse,
  ReferenceContent,
  ResumeCourse,
} from '../../../types/Courses'
import StarsRating from '../components/StarsRating'
import ICON_TIME from '../../../assets/icons/Time.svg'
import CONFETTI from '../../../assets/images/SuccessGlobe.svg'
import { useAuth } from '../../../contexts/AuthProvider'
import CenteredModal from '../../../components/CenteredModal'
import { formatPossessiveNoun } from '../../../helpers/generic'
import { useStartCourse } from '../../../mutations/courses/StartCourse'
import { useMarkVideoAsComplete } from '../../../mutations/courses/MarkVideoAsComplete'
import useMediaQuery from '../../../hooks/useMediaQuery'
import CourseScreenLoader from './CourseScreenLoader'
import useTrackResourceVisit from '../../../hooks/useTrackResourceVisit'
import getAvatarSrc from '../../../helpers/getAvatarSrc'
import type { Patient } from '../../../types/Patient'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'

const ViewCourse: React.FC = () => {
  const location = useLocation()
  const isLargeEnough = useMediaQuery('(min-width: 640px)')
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { courseId } = useParams()
  const [patientId, setPatientId] = useState<string>(null)
  const [showWhoIsTakingTheCourseModal, setShowWhoIsTakingTheCourseModal] =
    useState<boolean>(false)
  const { mutate: callStartCourse } = useStartCourse()
  const { mutate: callMarkVideoAsComplete } = useMarkVideoAsComplete()
  const [course, setCourse] = useState<PreviewCourse>(null)
  const [currentVideo, setCurrentVideo] = useState<CoursePreviewVideo>(null)
  const [activeVideo, setActiveVideo] = useState<CoursePreviewVideo>(null)
  const [expandedTeacherInfo, setExpandedTeacherInfo] = useState<boolean>(true)
  const [revisittingCourse, setRevisittingCourse] = useState<boolean>(false)
  const [showUpNext, setShowUpNext] = useState<boolean>(false)
  const { data: currentCourse, isLoading } = useGetCourseForPreview({
    courseId,
    user,
  })
  const { data: recommendations, refetch: refetchRecommendations } =
    useGetCourseRecommendations({
      courseId,
      user,
      patientId:
        location?.state?.recommendForPatientId &&
        location?.state?.recommendForPatientId !== 'myself'
          ? location?.state?.recommendForPatientId
          : null,
    })
  const [onClickOnRecommended] = useTrackResourceVisit(course)

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [courseId])

  // refetch recommendations
  useEffect(() => {
    if (currentCourse?.id === courseId) return

    refetchRecommendations()
  }, [currentCourse])

  // set course on load
  useEffect(() => {
    if (isLoading) return

    setCourse(currentCourse)
  }, [isLoading, courseId])

  // resume course on load
  useEffect(() => {
    if (!course || patientId) return

    const resumeCourseForPatientId = location?.state?.resumeCourseForPatientId
    if (resumeCourseForPatientId) handleResumeCourse(resumeCourseForPatientId)
  }, [course, patientId])

  const startCourse = () => {
    callStartCourse({
      courseId,
      patientId: patientId === 'myself' ? null : patientId,
    })

    const newCourseData: PreviewCourse = {
      ...course,
      courseProgresses: [
        ...course.courseProgresses,
        {
          completedAt: null,
          completePercentage: 0,
          patient:
            patientId === 'myself'
              ? null
              : user.roster.find((p) => p.id === patientId),
          status: {
            id: 'in_progress',
            name: 'In Progress',
            orderId: 2,
          },
          completedVideoIds: [],
        },
      ],
      videos: course.videos.map((v) => ({ ...v, status: 'not_started' })),
    }

    trackMixPanel({
      eventName: MIXPANEL_EVENT.COURSE_STARTED,
      properties: {
        id: course.id,
        accountId: user.data.id,
        name: course.title,
        format: course.format,
        audiences: course.audiences,
        serviceLines: course.serviceLines,
        topicOfInterest: course.topicKeys,
      },
    })

    const video = newCourseData.videos[0]
    setShowWhoIsTakingTheCourseModal(false)
    setCurrentVideo(video)
    setActiveVideo(video)
    updateCourse(newCourseData)
  }

  const markVideoAsCompleted = () => {
    callMarkVideoAsComplete({
      courseId,
      moduleId: currentVideo?.id,
      patientId: patientId === 'myself' ? null : patientId,
    })

    const newCourseData: PreviewCourse = {
      ...course,
      courseProgresses: course.courseProgresses.map((cp) => {
        if (
          patientId && patientId !== 'myself'
            ? cp?.patient?.id === patientId
            : cp.patient === null
        ) {
          const completedVideoIds = [...cp.completedVideoIds, currentVideo?.id]

          return {
            ...cp,
            completedVideoIds,
            completePercentage: Math.floor(
              (completedVideoIds.length * 100) / course.videos.length
            ),
          }
        }

        return cp
      }),
      videos: course.videos.map((v) => {
        if (v.id === currentVideo?.id) {
          return { ...v, status: 'completed' }
        }

        return v
      }),
    }

    setCurrentVideo((curr) => ({ ...curr, status: 'completed' }))
    setActiveVideo((curr) => ({ ...curr, status: 'completed' }))

    if (!newCourseData.videos.some((v) => v.status === 'not_started'))
      markCourseAsCompleted()
    else {
      updateCourse(newCourseData)
      setShowUpNext(true)
    }
  }

  const markCourseAsCompleted = () => {
    const newCourseData: PreviewCourse = {
      ...course,
      courseProgresses: course.courseProgresses.map((cp) => {
        if (
          patientId && patientId !== 'myself'
            ? cp?.patient?.id === patientId
            : cp.patient === null
        ) {
          return {
            ...cp,
            completedVideoIds: [...cp.completedVideoIds, currentVideo?.id],
            completedAt: new Date().toLocaleDateString(),
            completePercentage: 100,
            status: {
              id: 'completed',
              name: 'Completed',
              orderId: 3,
            },
          }
        }

        return cp
      }),
      videos: course.videos.map((v) => {
        return { ...v, status: 'completed' }
      }),
    }

    trackMixPanel({
      eventName: MIXPANEL_EVENT.COURSE_COMPLETED,
      properties: {
        id: course.id,
        accountId: user.data.id,
        name: course.title,
        format: course.format,
        audiences: course.audiences,
        serviceLines: course.serviceLines,
        topicOfInterest: course.topicKeys,
      },
    })

    updateCourse(newCourseData)
  }

  const onClickUpNext = () => {
    const video = course.videos.find((v) => v.status === 'not_started')
    const newVideo = { ...video, status: 'in_progress' }

    setCurrentVideo(newVideo)
    setActiveVideo(newVideo)

    setShowUpNext(false)
  }

  const updateCourse = (
    newCourseData: PreviewCourse,
    isRevisitting = false
  ) => {
    const queries = queryClient
      .getQueryCache()
      .findAll()
      .filter((query) => {
        const queryKey: any = query.queryKey[0]
        return [
          'getCourseForPreview',
          'getCourses',
          'getResumeCourses',
        ].includes(queryKey)
      })

    queries.forEach((q) => {
      const cachedData = queryClient.getQueryData<any>(q.queryKey)
      const queryKey = q.queryKey[0]

      if (cachedData)
        switch (queryKey) {
          case 'getCourseForPreview':
            queryClient?.setQueryData(q.queryKey, newCourseData)
            break
          case 'getCourses':
            queryClient?.setQueryData(q.queryKey, {
              ...cachedData,
              resources: cachedData.resources.map((c: Course) => {
                if (c.id === newCourseData.id) {
                  return {
                    ...c,
                    courseProgresses: newCourseData.courseProgresses,
                  }
                }

                return c
              }),
            })
            break
          case 'getResumeCourses':
            if (isRevisitting) break

            queryClient?.setQueryData(q.queryKey, {
              ...cachedData,
              courses: (() => {
                const index = cachedData.courses.findIndex((c: ResumeCourse) =>
                  c.id === newCourseData.id &&
                  c.courseProgress &&
                  patientId &&
                  patientId !== 'myself'
                    ? c.courseProgress?.patient?.id === patientId
                    : c.courseProgress?.patient === null
                )

                const resumeCourseProgress =
                  newCourseData.courseProgresses.find((cp) =>
                    patientId && patientId !== 'myself'
                      ? cp.patient?.id === patientId
                      : cp.patient === null
                  )

                if (index !== -1 && resumeCourseProgress) {
                  return cachedData.courses.map(
                    (c: ResumeCourse, i: number) => {
                      if (i === index) {
                        return {
                          ...c,
                          courseProgress: resumeCourseProgress,
                        }
                      }
                      return c
                    }
                  )
                } else {
                  const video = currentVideo
                    ? currentVideo
                    : newCourseData.videos[0]
                  return [
                    {
                      id: newCourseData.id,
                      imageSrc: newCourseData.coverUrl,
                      numberOfVideos: newCourseData.videos.length,
                      title: newCourseData.title,
                      video: {
                        id: video.id,
                        title: video.title,
                        duration: video.duration,
                        imageSrc: video.imageSrc,
                      },
                      courseProgress: resumeCourseProgress,
                    },
                    ...cachedData.courses,
                  ]
                }
              })(),
            })
            break
          default:
            break
        }
    })

    setCourse(newCourseData)
  }

  const handleResumeCourse = (resumeCourseForPatientId: string) => {
    const myCurrentCourse = cloneDeep(course)

    const myCurrentProgress = myCurrentCourse?.courseProgresses.find((cp) => {
      if (
        resumeCourseForPatientId === null ||
        resumeCourseForPatientId === 'myself'
      ) {
        return cp.patient === null
      } else {
        return cp.patient?.id === resumeCourseForPatientId
      }
    })

    myCurrentCourse.videos = myCurrentCourse.videos.map((video) => ({
      ...video,
      status: myCurrentProgress?.completedVideoIds.some(
        (videoId) => videoId === video.id
      )
        ? 'completed'
        : 'not_started',
    }))

    let myCurrentVideo = myCurrentCourse.videos.find(
      (v) => v.status === 'not_started'
    )

    if (!myCurrentVideo)
      myCurrentVideo = myCurrentCourse.videos[myCurrentCourse.videos.length - 1]

    if (myCurrentProgress.status.id === 'completed') setRevisittingCourse(true)

    setActiveVideo(myCurrentVideo)
    setCurrentVideo(myCurrentVideo)
    setPatientId(resumeCourseForPatientId)
    updateCourse(myCurrentCourse, true)
  }

  const patientsThatCanStartCourse = [
    ...user.roster.filter((p) => p.relationship?.key !== 'myself'),
    {
      firstName: user.data.firstName,
      lastName: user.data.lastName,
      relationship: { key: 'myself' },
      id: 'myself',
    },
  ].filter(
    (p) =>
      !course?.courseProgresses?.find(
        (cp) =>
          cp.patient?.id === p.id ||
          (cp.patient === null ? p.id === 'myself' : false)
      )
  )

  const currentProgress: CourseProgress = course?.courseProgresses?.find((cp) =>
    patientId && patientId !== 'myself'
      ? cp.patient?.id === patientId
      : cp.patient === null
  )

  const notStartedCourse: boolean = !patientId || !currentProgress

  const isLoadingCourse: boolean = isLoading || !course

  if (!currentCourse?.id && !isLoading) return <Navigate to="/resources" />

  if (isLoadingCourse) return <CourseScreenLoader />

  return (
    <div className="flex w-full max-w-5xl flex-col gap-10 sm:items-center sm:py-20 xs:gap-6">
      {/* Back button */}
      <div className="mb-4 flex w-full items-start text-base font-semibold text-cta-default underline xs:mb-0">
        <button
          onClick={() => {
            const from = location.state?.fromPathname
            if (from) navigate(-1)
            else navigate('/resources')
          }}
          className="flex items-center"
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Back
        </button>
      </div>

      {/* Content */}
      <div className="flex w-full flex-col gap-10 rounded-2xl bg-white p-8 shadow-subtle xs:gap-6 xs:p-4">
        {/* Header */}
        <div className="flex flex-col xs:gap-4">
          {/* Buttons */}
          <div className="flex justify-between xs:flex-col xs:gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-xs font-semibold text-secondaryAccents-lightGreen">
                Self-Paced Courses
              </p>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-semibold xs:text-xl">
                  {course.title}
                </p>
                <BookmarkButton resource={course} iconClasses="h-6 w-6" />
              </div>
            </div>
            {patientsThatCanStartCourse.length > 0 && notStartedCourse && (
              <button
                className={`${primaryButtonClassSmall} sm:self-end`}
                onClick={() => setShowWhoIsTakingTheCourseModal(true)}
              >
                Start Course
              </button>
            )}
          </div>

          {/* No modules */}
          <div className="mt-1 flex items-center gap-2 text-base text-text-secondary xs:text-sm">
            <img src={COURSES_ICON} alt="icon" className="h-3.5" />
            {course.numberOfVideos} Module
            {course.numberOfVideos === 1 ? '' : 's'}
          </div>

          {/* Description */}
          <p className="whitespace-pre-wrap text-base sm:mt-3 xs:text-sm">
            {course.description}
          </p>

          {/* Progresses */}
          {React.Children.toArray(
            course.courseProgresses
              .filter((cp) =>
                notStartedCourse
                  ? true
                  : patientId && patientId !== 'myself'
                  ? cp?.patient?.id === patientId
                  : cp.patient === null
              )
              .map((cp) => (
                <div
                  className="mt-4 flex flex-col gap-2 rounded-lg bg-background-offwhite p-4"
                  key={cp?.patient?.id || 'myself'}
                >
                  <div className="flex flex-col-reverse justify-between gap-6 sm:flex-row sm:gap-0">
                    <div className="flex items-center gap-1 ">
                      <img
                        src={getAvatarSrc(user, cp?.patient)}
                        alt="patient-avatar"
                        className="h-10 w-10 rounded-full xs:h-6 xs:w-6"
                      />
                      <p className="text-base font-semibold">
                        {formatPossessiveNoun(
                          cp.patient
                            ? cp.patient.firstName
                            : user.data.firstName
                        )}{' '}
                        Progress
                      </p>
                    </div>
                    {notStartedCourse && (
                      <button
                        className={`${secondaryButtonClassSmall} w-full sm:w-46`}
                        onClick={() =>
                          handleResumeCourse(cp.patient?.id || 'myself')
                        }
                      >
                        {cp.completePercentage !== 100
                          ? 'Resume Course'
                          : 'Revisit Course'}
                      </button>
                    )}
                  </div>
                  <div className="flex items-center gap-6">
                    <div className="position-relative h-3 w-full rounded-3.5xl bg-white">
                      <div
                        className="position-absolute left-0 h-3 rounded-3.5xl bg-status-success"
                        style={{
                          width: `${
                            notStartedCourse
                              ? cp.completePercentage
                              : currentProgress?.completePercentage
                          }%`,
                        }}
                      />
                    </div>
                    {(
                      notStartedCourse
                        ? cp.completePercentage === 100
                        : currentProgress?.completePercentage === 100
                    ) ? (
                      <div className="flex items-center gap-2">
                        <CheckIcon className="h-5 w-5 text-status-success" />
                        <p className="font-semibold">Completed</p>
                      </div>
                    ) : (
                      <p className="whitespace-nowrap font-semibold">
                        {Math.floor(
                          notStartedCourse
                            ? cp.completePercentage
                            : currentProgress?.completePercentage
                        )}
                        % Complete
                      </p>
                    )}
                  </div>
                </div>
              ))
          )}
        </div>

        {/* Completed */}
        {patientId &&
          currentProgress?.status.id === 'completed' &&
          !revisittingCourse && (
            <div className="flex flex-col items-center justify-center">
              <p className="text-lg font-semibold">
                Great job! You've completed this course!
              </p>
              <img
                src={CONFETTI}
                alt="success confetti"
                className="h-24 w-24 sm:h-48 sm:w-48"
              />
              <StarsRating
                title={`Was this course helpful?`}
                resourceId={courseId}
                resource = {course}
              />
            </div>
          )}

        {/* Not started */}
        {(!patientId ||
          !currentProgress ||
          (currentProgress?.status.id === 'completed' &&
            !revisittingCourse)) && (
          <div className="grid grid-cols-3 gap-6 rounded-2xl border-4 border-components-paleBlue px-6 py-8 xs:grid-cols-1 xs:gap-4">
            {React.Children.toArray(
              course.videos.map((video: CoursePreviewVideo, index: number) => (
                <div className="flex flex-col gap-2 rounded-lg border border-components-fields">
                  <img
                    src={video.imageSrc}
                    alt="thumbnail"
                    className="h-56 w-72 rounded-t-lg object-cover"
                  />
                  <div className="flex flex-col gap-1 px-2 pb-4">
                    <p className="text-sm font-semibold line-clamp-2">
                      {index + 1}. {video.title}
                    </p>
                    <div className="flex gap-1 text-xs text-text-label">
                      <img src={ICON_TIME} alt="time" />
                      {video.duration}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}

        {/* In progress */}
        {patientId &&
          (currentProgress?.status.id === 'in_progress' ||
            (currentProgress?.status.id === 'completed' &&
              revisittingCourse)) && (
            <div className="flex flex-col gap-4 rounded-2xl border-4 border-components-paleBlue px-6 py-8 lg:flex-row lg:gap-6">
              {/* Video */}
              <div className="flex w-full max-w-xl flex-col gap-8">
                <ReactPlayer
                  url={currentVideo?.contentUrl}
                  width={isLargeEnough ? '584px' : '100%'}
                  height={isLargeEnough ? '348px' : 'auto'}
                  controls={true}
                />

                {/* Title and course button */}
                <div className="flex flex-col justify-between gap-4 lg:flex-row">
                  <div className="flex flex-col">
                    <p className="text-lg font-semibold lg:text-2xl">
                      {currentVideo?.title}
                    </p>
                    <div className="flex items-center gap-1 text-base text-text-label">
                      <img src={ICON_TIME} alt="time" className="h-4 w-4" />
                      {currentVideo?.duration}
                    </div>
                  </div>

                  {showUpNext &&
                  Boolean(
                    course.videos.find(
                      (v) =>
                        v.status === 'not_started' && v.id !== currentVideo?.id
                    )
                  ) ? (
                    <button
                      onClick={onClickUpNext}
                      className={`${primaryButtonClassSmall} max-w-xxs flex-shrink-0 self-start`}
                    >
                      <span className="line-clamp-1">
                        Up Next:{' '}
                        {
                          course.videos.find((v) => v.status === 'not_started')
                            ?.title
                        }
                      </span>
                      <ChevronRightIcon className="h-5 w-5 flex-shrink-0" />
                    </button>
                  ) : (
                    <button
                      className={`${secondaryButtonClassSmall} flex-shrink-0 self-start`}
                      onClick={markVideoAsCompleted}
                      disabled={
                        activeVideo?.id !== currentVideo?.id ||
                        currentVideo?.status === 'completed'
                      }
                    >
                      <CheckIcon className="h-5 w-5" />
                      {activeVideo?.id !== currentVideo?.id ||
                      currentVideo?.status === 'completed'
                        ? 'Completed'
                        : 'Mark Complete'}
                    </button>
                  )}
                </div>

                {/* Description */}
                <p className="whitespace-pre-wrap text-sm lg:text-base">
                  {currentVideo?.description}
                </p>

                {/* Referenced content */}
                {Boolean(currentVideo?.referencedContent?.length) && (
                  <div className="flex flex-col gap-4">
                    <p className="text-base font-semibold">
                      Referenced content:
                    </p>
                    <div className="flex w-full flex-wrap gap-4">
                      {React.Children.toArray(
                        currentVideo?.referencedContent?.map(
                          (item: ReferenceContent) => (
                            <button
                              className="flex w-full max-w-xxs gap-2 rounded-lg border border-components-fields"
                              onClick={() =>
                                navigate(`/view-resource/${item.id}`, {
                                  state: { fromPathname: location.pathname },
                                })
                              }
                            >
                              <img
                                src={item.src}
                                alt="content-pic"
                                className="h-20 w-20 rounded-tl-lg rounded-bl-lg object-cover"
                              />
                              <p className="my-2 text-left text-sm font-semibold line-clamp-3">
                                {item?.name}
                              </p>
                            </button>
                          )
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Playlist */}
              <div className="flex w-full flex-1 flex-col gap-2">
                <p className="text-base font-semibold lg:text-lg">
                  {currentCourse.title}
                </p>
                <div className="flex items-center gap-2 text-base text-text-secondary xs:text-sm">
                  <img src={COURSES_ICON} alt="icon" className="h-3.5" />
                  {course.numberOfVideos} Module
                  {course.numberOfVideos === 1 ? '' : 's'}
                </div>

                {/* Videos */}
                <div className="flex h-96 flex-col gap-1 overflow-y-auto rounded-lg bg-components-disabledFill">
                  {React.Children.toArray(
                    course.videos.map(
                      (video: CoursePreviewVideo, index: number) => {
                        const isAfterActiveVideo = Boolean(
                          activeVideo?.id &&
                            course.videos.findIndex(
                              (v) => v.id === activeVideo?.id
                            ) < index
                        )

                        return (
                          <button
                            onClick={
                              video.status === 'completed' ||
                              activeVideo?.id === video.id
                                ? () => setCurrentVideo(video)
                                : () => {}
                            }
                            className={`flex items-center gap-4 py-4 px-2 ${
                              currentVideo?.id === video.id ? 'bg-beige' : ''
                            } ${isAfterActiveVideo ? 'opacity-50' : ''}`}
                            disabled={isAfterActiveVideo}
                          >
                            {/* Index */}
                            <div className="flex-shrink-0">
                              {activeVideo?.status === 'in_progress' &&
                              video.id === activeVideo?.id ? (
                                <PlayIcon className="h-4 w-4 text-secondaryAccents-lightYellow" />
                              ) : (
                                <p className="w-4 text-sm">{index + 1}</p>
                              )}
                            </div>

                            {/* Video cover */}
                            <div className="relative flex-shrink-0">
                              {video.status === 'completed' && (
                                <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
                                  <div className="absolute top-0 left-0 h-full w-full rounded-md bg-secondaryAccents-lightGreen opacity-75" />
                                  <CheckIcon className="z-30 h-5 w-5 text-white" />
                                </div>
                              )}
                              <img
                                src={video.imageSrc}
                                alt="course-video-preview"
                                className="h-12 w-16 rounded-md object-cover"
                              />
                            </div>

                            {/* Title & duration */}
                            <div className="flex flex-col gap-1">
                              <p className="text-left text-sm font-semibold line-clamp-2">
                                {video.title}
                              </p>
                              <div className="flex gap-1 text-xs text-text-label">
                                <img src={ICON_TIME} alt="time" />
                                {video.duration}
                              </div>
                            </div>
                          </button>
                        )
                      }
                    )
                  )}
                </div>
              </div>
            </div>
          )}

        {/* About provider */}
        <div className="flex items-center gap-2">
          <p className="text-lg font-semibold">About {course.teacherName}</p>
          <button
            className="text-text-label"
            onClick={() => setExpandedTeacherInfo(!expandedTeacherInfo)}
          >
            {expandedTeacherInfo ? (
              <ChevronDownIcon className="h-7 w-7" />
            ) : (
              <ChevronUpIcon className="h-7 w-7" />
            )}
          </button>
        </div>

        {/* Expanded */}
        {expandedTeacherInfo && (
          <div className="flex gap-4 xs:flex-col xs:gap-4">
            <img
              src={course.teacherSrc}
              alt="teacher-src"
              className="h-50 w-50 rounded-full object-cover xs:h-32 xs:w-32"
            />
            <div className="flex flex-col gap-4 xs:gap-4">
              <div className="flex flex-col gap-1">
                <p className="text-base font-semibold">{course.teacherName}</p>
                <p className="text-xs">{course.teacherCredentials}</p>
              </div>
              <p className="text-xs">{course.teacherDescription}</p>
            </div>
          </div>
        )}
      </div>

      {/* Recommended */}
      {Boolean(recommendations?.length) && (
        <>
          {/* Divider */}
          <div className="w-full border-t border-text-secondary" />

          <div className="flex w-full flex-col gap-10 xs:pb-10">
            <p className="text-2xl font-semibold xs:text-center xs:text-xl">
              Recommended
            </p>
            <CoursesGridDisplay
              courses={recommendations}
              onClick={onClickOnRecommended}
            />
          </div>
        </>
      )}

      {/* Who is taking the course modal */}
      <CenteredModal
        open={showWhoIsTakingTheCourseModal}
        setOpen={setShowWhoIsTakingTheCourseModal}
      >
        <div className="flex flex-col gap-4">
          <p className="text-lg font-semibold xs:text-base">
            Who is taking the course ?
          </p>
          <p className="text-base xs:text-sm">Please select one to continue</p>

          {React.Children.toArray(
            patientsThatCanStartCourse.map((patient: Partial<Patient>) => (
              <button
                onClick={() => setPatientId(patient.id)}
                className={`flex items-center gap-4 rounded-lg px-4 py-2 shadow-subtle ${
                  patientId === patient?.id
                    ? 'border-2 border-cta-default bg-components-paleBlue'
                    : 'border border-components-fields'
                }`}
              >
                <img
                  src={getAvatarSrc(user, patient)}
                  alt="patient-avatar"
                  className="h-10 w-10 xs:h-8 xs:w-8"
                />
                <p className="text-base font-semibold xs:text-sm">
                  {patient.firstName} {patient.lastName}
                </p>
              </button>
            ))
          )}

          <div className="flex w-full justify-end gap-4">
            <button
              className={tertiaryButtonClassSmall}
              onClick={() => {
                setPatientId(null)
                setShowWhoIsTakingTheCourseModal(false)
              }}
            >
              Cancel
            </button>
            <button
              className={primaryButtonClassSmall}
              onClick={startCourse}
              disabled={!patientId}
            >
              Start Course
            </button>
          </div>
        </div>
      </CenteredModal>
    </div>
  )
}

export default ViewCourse
