import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { Patient } from '../../types/Patient'

interface GetAllowedToBookProps {
  patients: (Patient | Partial<Patient>)[]
}

interface AllowedToBookHistoryItem {
  changedTo: boolean
  modifiedOn: string
}

type AllowedToBookHistory = AllowedToBookHistoryItem[]

interface AllowedToBookHistoryResults {
  [patientId: string]: AllowedToBookHistory
}

export const UseGetAllowedToBookHistory = (
  params: GetAllowedToBookProps,
  deps = params
) =>
  useQuery(['getAllowedToBookHistory', deps?.patients?.length], async () =>
    getAllowedToBookHistory(params)
  )

const getAllowedToBookHistory = async ({
  patients,
}: GetAllowedToBookProps): Promise<AllowedToBookHistoryResults> => {
  const token: string = await auth.currentUser.getIdToken()

  const resultsObj: AllowedToBookHistoryResults = {}

  await Promise.all(
    patients.map((p) =>
      fetch(
        `${import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL}v1/patients/${
          p.id
        }/allowed-to-book-history`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((history: AllowedToBookHistory) => {
          resultsObj[p.id] = history
        })
    )
  )

  return resultsObj
}
