import { useMutation } from '@tanstack/react-query'

import type { InterestOption } from '../../screens/onboarding/WhatBringsYouHere'
import { auth } from '../../config/firebase'

interface SaveWhatBringsProps {
  userId: string
  interests: InterestOption[]
}

const saveInterest = async ({ userId, interests }: SaveWhatBringsProps) => {
  const token = await auth.currentUser.getIdToken()
  await fetch(`${import.meta.env.VITE_DOTCOM_URL}api/v1/users/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      user: {
        what_brings_you_here: interests.map((i: InterestOption) => i.key),
      },
    }),
  }).then((response) => response.json())
}

export const useSaveWhatBringsYouHere = () =>
  useMutation((params: SaveWhatBringsProps) => saveInterest(params))
