import { auth } from '../../config/firebase'
import { toCamelCase } from '../../helpers/serializers'

interface RemainingBulkProps {
  carePlanId: string
}

export interface RemainingBulkResult {
  isBulkModel: boolean
  remainingSessionsOnBulkModel: boolean
}

const getCarePlanRemainingBulkSessions = async ({
  carePlanId,
}: RemainingBulkProps): Promise<RemainingBulkResult> => {
  const token = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/care_plans/${carePlanId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  const bulkModelResult = toCamelCase(result)

  return {
    isBulkModel: bulkModelResult.isBulkModel,
    remainingSessionsOnBulkModel:
      bulkModelResult.remainingBulkModelSessionPerContract ||
      bulkModelResult.remainingBulkModelSessionPerProduct,
  }
}

export default getCarePlanRemainingBulkSessions
