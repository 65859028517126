import { differenceInCalendarYears, differenceInDays } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'

import {
  DEFAULT_DATE_REGEX,
  VALID_PHONE_NUMBER_REGEX,
} from '../constants/regex'
import { genderIdentities } from '../constants/values'
import type { CarePlan, Patient } from '../types/Patient'
import type { GenderIdentity } from '../types/Profile'
import type { Session } from '../types/Session'
import type { Therapist } from '../types/Therapist'

export const getMultipleRandomFromArray = (arr: any[], num: number) => {
  const shuffled = [...arr].sort(() => 0.5 - Math.random())

  return shuffled.slice(0, num)
}

export const splitArrayToChunks = (arr: any[], numberOfChunks: number) => {
  const arrayToProcess = [...arr]
  const result = []

  for (let i = numberOfChunks; i > 0; i--) {
    result.push(arrayToProcess.splice(0, Math.ceil(arrayToProcess?.length / i)))
  }

  return result
}

export const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const getAgeFromBirthdate = (birthDate: string): number => {
  const ageDifMs = Date.now() - new Date(birthDate).getTime()
  const ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const convertStringToSnakeCase = (str: string): string =>
  str.replaceAll(' ', '_').toLowerCase()

export const toBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result)
      } else {
        reject(new Error('Unexpected result type'))
      }
    }
    reader.onerror = (error) => reject(error)
  })
}

export const getGenderIdentity = (genderIdentity: string) =>
  genderIdentities.find(
    (gender: GenderIdentity) => gender?.key === genderIdentity
  )

export const convertFrontendDateToBackend = (date: string) => {
  if (!date) return null
  const myDate = date.split('/')
  return `${myDate[2]}-${myDate[0]}-${myDate[1]}`
}

export const convertBackendDateToFrontend = (date: string) => {
  if (!date) return null
  const myDate = date.split('-')
  return `${myDate[1]}/${myDate[2]}/${myDate[0]}`
}

export const dataURLtoFile = (dataUrl: string, fileName: string) => {
  const arr = dataUrl.split(',')
  const mime = arr[0].split(':')[1].split(';')[0]
  const binary = Buffer.from(arr[1], 'base64')
  const u8arr = new Uint8Array(binary)

  return new File([u8arr], fileName, { type: mime })
}

export const getTherapists = (carePlans: CarePlan[]): Therapist[] => {
  if (!carePlans?.length) return []

  return Object.values(
    carePlans.reduce((therapists, carePlan) => {
      carePlan.sessions.forEach((session: Session) => {
        if (!therapists[session.therapist.id]) {
          therapists[session.therapist.id] = session.therapist
        }
      })
      return therapists
    }, {})
  )
}

export function calculateAge(birthDate: Date) {
  const today = new Date()
  const ageInYears = differenceInCalendarYears(today, birthDate)

  const daysLeft = differenceInDays(
    today,
    new Date(
      birthDate.getFullYear() + ageInYears,
      birthDate.getMonth(),
      birthDate.getDate()
    )
  )

  return ageInYears + daysLeft / 365
}

export const isString = (str: string): boolean => {
  return Object.prototype.toString.call(str) === '[object String]'
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!isString(phoneNumber)) return phoneNumber

  const digitsRegex = /\d/
  const allowedCharsRegex = /[\d\s()-]/
  const tenDigitsAnywhereInStringRegex = /(\d\D*){10}/

  const value = phoneNumber
    .split('')
    .filter((char) => allowedCharsRegex.test(char))
    .join('')

  if (
    tenDigitsAnywhereInStringRegex.test(value) &&
    !VALID_PHONE_NUMBER_REGEX.test(value)
  ) {
    return value
      .split('')
      .filter((char) => digitsRegex.test(char))
      .join('')
      .slice(-10)
      .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3')
  }

  return value
}

export const isValidDefaultDate = (dateString: string): boolean => {
  if (!isString(dateString)) return false

  // Step 1: Match the pattern of the date
  const match = DEFAULT_DATE_REGEX.exec(dateString)

  if (!match) {
    return false
  }

  // Step 2: Extract MM, DD, and YYYY and convert them to numbers
  const month = parseInt(match[1], 10)
  const day = parseInt(match[2], 10)
  const year = parseInt(match[3], 10)

  // Step 3: Check if the year is valid
  if (year < 1900 || year > 9999) {
    return false
  }

  // Step 4: Check if the month is valid
  if (month < 1 || month > 12) {
    return false
  }

  // Step 5: Check if the day is valid for the given month and year
  const maxDay = new Date(year, month, 0).getDate()
  if (day < 1 || day > maxDay) {
    return false
  }

  return true
}

export const formatDefaultDateFormat = (dateString: string): string => {
  if (!isString(dateString)) return dateString

  const digitsRegex = /\d/
  const allowedCharsRegex = /[\d/]/
  const eightDigitsAnywhereInStringRegex = /(\d\D*){8}/

  const value = dateString
    .split('')
    .filter((char) => allowedCharsRegex.test(char))
    .join('')

  if (
    eightDigitsAnywhereInStringRegex.test(value) &&
    !DEFAULT_DATE_REGEX.test(value)
  ) {
    return value
      .split('')
      .filter((char) => digitsRegex.test(char))
      .join('')
      .replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3')
  }

  return value
}

export const formatPossessiveNoun = (value: string): string => {
  if (value && typeof value === 'string') {
    let suffix: string
    if (value.endsWith('s')) suffix = "'"
    else suffix = "'s"

    return `${value}${suffix}`
  }
  return ''
}

export const addMinutesRaw = (time: string, minutes: number): string => {
  let [hours, mins, suffix]: Array<string | number> = time.split(/:|\s/)
  hours = (parseInt(hours, 10) % 12) + (suffix === 'PM' ? 12 : 0)
  mins = parseInt(mins, 10) + minutes
  hours += Math.floor(mins / 60)
  mins %= 60
  suffix = hours < 12 || hours === 24 ? 'AM' : 'PM'
  if (hours === 0 || hours === 24) {
    hours = 12
  } else if (hours > 12) {
    hours -= 12
  }
  return `${hours.toString().padStart(2, '0')}:${mins
    .toString()
    .padStart(2, '0')} ${suffix}`
}

export const getTimeZones = (): { [key: string]: string } =>
  JSON.parse(localStorage.getItem('DOTCOM_TIMEZONES') || '{}')

export const fallbackTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const backendToFrontendTz = (backendTz: string): string => {
  const timezones = getTimeZones()

  if (!backendTz || Object.keys(timezones)?.length === 0)
    return fallbackTimeZone

  return timezones[backendTz] || fallbackTimeZone
}

export const frontendToBackendTz = (frontendTz: string): string => {
  const timezones = getTimeZones()
  return Object.keys(timezones).find((key) => timezones[key] === frontendTz)
}

// This to use instead of 'new Date()' when timezone matters.
export const newDate = (givenDate: string | Date, patientTz: string): Date => {
  const givenTzOffsetMs = getTimezoneOffset(patientTz)
  const date = new Date(givenDate)
  const userOffsetMs = date.getTimezoneOffset() * 60000

  // when console.log-ing this date, ignore the timezone part because it is not valid.
  // the date-time part is in the specified timezone (patientTz param) and the timezone part is in the browser's.
  // example what console.log could look like: Thu Mar 02 2023 13:43:33 GMT+0200 (Eastern European Standard Time)
  // valid part: Thu Mar 02 2023 13:43:33 --> date and time in the patientTz timezone.
  // invalid part: GMT+0200 (Eastern European Standard Time) --> timezone in the browser's.
  return new Date(date.getTime() + givenTzOffsetMs + userOffsetMs)
}

export const getTaskCompletionDeadlineDate = (
  patient: Patient | Partial<Patient>
) => {
  const date = patient.createdAt
    ? newDate(patient.createdAt, patient.timeZone)
    : newDate(new Date(), patient.timeZone)
  date.setDate(date.getDate() + 30)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()
  const dateString = `${month.toString().padStart(2, '0')}/${day
    .toString()
    .padStart(2, '0')}/${year.toString()}`
  return dateString
}

export const enumeratedElementsFromArray = (
  arr: any[] = [],
  bindingWord = 'and'
): string => {
  return (
    arr?.slice(0, -1).join(', ') +
    (arr?.length > 1 ? ` ${bindingWord} ` : '') +
    arr?.slice(-1)
  )
}
