import React, { useEffect, useState } from 'react'
import { formatInTimeZone } from 'date-fns-tz'
import { flushSync } from 'react-dom'

import WantDifferentTherapistModal from './Modals/WantDifferentTherapistModal'
import Tooltip from './Tooltip'
import type { ServiceLine } from '../types/ServiceLine'
import type { CarePlan, Condition, Patient } from '../types/Patient'
import type { Session } from '../types/Session'
import { getIsAssessmentStatus } from '../helpers/bookSessionStatusUtils'
import type { Product } from '../types/User'
import { useAuth } from '../contexts/AuthProvider'
import { formatPossessiveNoun } from '../helpers/generic'
import { usePatient } from '../contexts/PatientProvider'
import handleClickSupportEmail from '../helpers/supportClick'

const BookingStatusTooltip: React.FC<{
  children: React.ReactNode
  bookableLines: ServiceLine[]
  patient: Partial<Patient>
  serviceLine: ServiceLine
  providerNotAvailable: boolean
  position: 'top' | 'bottom' | 'left' | 'right'
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  children,
  bookableLines,
  patient,
  serviceLine,
  providerNotAvailable,
  position = 'top',
  setIsDisabled,
}) => {
  const { setPatient } = usePatient()
  const { user } = useAuth()
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode>(null)
  const [providerName, setProviderName] = useState<string>(null)
  const [openWantDifferentProvider, setOpenWantDifferentProvider] =
    useState<boolean>(false)

  const handleOnClickChangeTherapist = () =>
    flushSync(() => {
      setPatient(patient)
      setOpenWantDifferentProvider(true)
    })

  useEffect(() => {
    if (
      (!patient && user?.roster?.length > 1) ||
      !serviceLine ||
      !bookableLines ||
      // patient is eligibile, customer has product, patient does not have any non-iep conditions
      (patient.isEligible &&
        user.products.find((p) => !p.isIep) &&
        !patient.conditions.some((c) => !c.isIep))
    ) {
      setIsDisabled(false)
      setTooltipContent(null)
      return
    }

    const forSelf: boolean = patient?.relationship?.key === 'myself'

    const noTherapistAvailable = !bookableLines.some(
      (sl: ServiceLine) => sl.displayName === serviceLine.displayName
    )

    const isCurrentSessionAssessment = getIsAssessmentStatus(
      patient,
      serviceLine?.displayName
    )

    const currentCarePlan = patient?.conditions
      ?.find((c: Condition) => !c.isIep)
      ?.carePlans?.find(
        (cp: CarePlan) => cp?.displayName === serviceLine?.displayName
      )

    const currentProviderName = currentCarePlan?.sessions?.find(
      (s: Session) => s.therapist.id === currentCarePlan?.providerId
    )?.therapist?.preferredName

    setProviderName(currentProviderName)

    const anyPendingSessionInCurrentCareplan = currentCarePlan?.sessions?.some(
      (s: Session) => s.status === 'pending'
    )

    const productAdditionalSessionsType = user.products.find(
      (product: Product) =>
        product?.serviceLine?.serviceId === serviceLine?.serviceId &&
        !product?.isIep
    )?.additionalSessions

    const usedAllFreeSessions =
      typeof currentCarePlan?.remainingSessions === 'number' &&
      currentCarePlan?.remainingSessions <= 0

    const assessmentNotCompleted =
      isCurrentSessionAssessment &&
      anyPendingSessionInCurrentCareplan &&
      !currentCarePlan.bookingNonSponsoredInformationConfirmed

    const pendingSession: Session = currentCarePlan?.sessions?.find(
      (s: Session) => s.status === 'pending'
    )

    const maxedOut =
      usedAllFreeSessions && productAdditionalSessionsType === 'NOT_ALLOWED'

    const isBulkModelAndRanOutOfSessions =
      currentCarePlan?.bulkModel?.isBulkModel &&
      !currentCarePlan?.bulkModel?.remainingSessionsOnBulkModel

    const pendingCoveredSessions =
      anyPendingSessionInCurrentCareplan &&
      productAdditionalSessionsType === 'PATIENT_PAY' &&
      (usedAllFreeSessions || isBulkModelAndRanOutOfSessions) &&
      !currentCarePlan.bookingNonSponsoredInformationConfirmed

    const organizationRanOutOfSessions =
      currentCarePlan?.bulkModel?.isBulkModel &&
      !currentCarePlan?.bulkModel?.remainingSessionsOnBulkModel &&
      user?.products?.find((p) => p.id === currentCarePlan.productId)
        ?.additionalSessions === 'NOT_ALLOWED'

    const pendingInsuranceVerification =
      Boolean(patient?.insurance) &&
      !patient?.insurance?.verified &&
      patient?.insurance?.isMedicaid

    const tooltipText = {
      noTherapistAvailable: (
        <p>
          We do not have any {serviceLine.type} therapists available for booking
          at this time. If you have any questions, please contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="underline"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
        </p>
      ),
      assessmentNotCompleted: pendingSession && (
        <div className="flex flex-col">
          <p className="font-semibold">
            Looks like {forSelf ? 'you' : patient?.firstName} already{' '}
            {forSelf ? 'have' : 'has'} a session booked with{' '}
            {forSelf ? 'your' : 'their'} provider:
          </p>
          <p className="mt-2">{serviceLine.displayName} Assessment - 60 Min</p>
          <p>
            {formatInTimeZone(
              pendingSession?.startTime,
              patient?.timeZone,
              "EEEE',' MMMM d',' yyyy"
            )}
          </p>
          <p>{`${formatInTimeZone(
            pendingSession?.startTime,
            patient?.timeZone,
            'hh:mm aa'
          )} - ${formatInTimeZone(
            pendingSession?.endTime,
            patient?.timeZone,
            'hh:mm aa'
          )} ${formatInTimeZone(new Date(), patient?.timeZone, 'zzz')}`}</p>
          <p className="mt-2">
            We want to make sure that the care is right for{' '}
            {forSelf ? 'you' : patient?.firstName}. You can book additional
            sessions after the initial session is completed!
          </p>
        </div>
      ),
      pendingCoveredSessions: (
        <div className="flex flex-col">
          <p className="font-semibold">
            {`You cannot schedule any additional ${
              serviceLine.type
            } therapy sessions${
              forSelf ? ' ' : ` for ${patient?.firstName} `
            }at this time.`}
          </p>
          <p>
            It looks like {forSelf ? 'you' : patient?.firstName}{' '}
            {forSelf ? 'have' : 'has'} pending covered sessions. You can book
            additional sessions after these sessions are completed! Please
            contact{' '}
            <a
              href="mailto:support@huddleupcare.com"
              className="underline"
              onClick={() => handleClickSupportEmail(user, location)}
            >
              support@huddleupcare.com
            </a>{' '}
            if you have any questions.
          </p>
        </div>
      ),
      maxedOut: (
        <div className="flex flex-col">
          <p className="font-semibold">
            {`You cannot schedule any additional ${
              serviceLine.type
            } therapy sessions${
              forSelf ? ' ' : ` for ${patient?.firstName} `
            }at this time.`}
          </p>
          <p>
            It looks like {forSelf ? 'you' : patient?.firstName}{' '}
            {forSelf ? 'have' : 'has'} used or scheduled all of{' '}
            {forSelf ? 'your' : 'their'} covered sessions. Please contact{' '}
            <a
              href="mailto:support@huddleupcare.com"
              className="underline"
              onClick={() => handleClickSupportEmail(user, location)}
            >
              support@huddleupcare.com
            </a>{' '}
            if you have any questions.
          </p>
        </div>
      ),
      providerNotAvailable: (
        <p>
          Looks like {formatPossessiveNoun(patient?.firstName)}{' '}
          {serviceLine?.type} therapist
          {currentProviderName ? `, ${currentProviderName}, ` : ''} does not
          have any availability for booking at this time. You can{' '}
          <button onClick={handleOnClickChangeTherapist} className="underline">
            change to a different therapist
          </button>{' '}
          or contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="underline"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      ),
      organizationRanOutOfSessions: (
        <p>
          You cannot schedule any additional {serviceLine?.type} sessions
          {patient?.relationship?.key !== 'myself'
            ? ` for ${patient.firstName}`
            : ''}{' '}
          at this time. It looks like your organization has run out of covered
          sessions. Please contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="underline"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>{' '}
          if you have any questions.
        </p>
      ),
      pendingInsuranceVerification: (
        <p>
          We are verifying {formatPossessiveNoun(patient.firstName)} insurance
          coverage. Our goal is to offer affordable and accessible care, so if
          you have any questions, please contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="underline"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      ),
    }

    const getTextType = () => {
      switch (true) {
        case organizationRanOutOfSessions:
          return 'organizationRanOutOfSessions'
        case noTherapistAvailable:
          return 'noTherapistAvailable'
        case assessmentNotCompleted:
          return 'assessmentNotCompleted'
        case pendingCoveredSessions:
          return 'pendingCoveredSessions'
        case providerNotAvailable:
          return 'providerNotAvailable'
        case maxedOut:
          return 'maxedOut'
        case pendingInsuranceVerification:
          return 'pendingInsuranceVerification'
        default:
          return null
      }
    }

    const textType = getTextType()
    const text = textType ? tooltipText[textType] : null

    setIsDisabled(Boolean(text))
    setTooltipContent(text)
  }, [patient, serviceLine, bookableLines])

  return (
    <>
      <Tooltip
        isDisabled={!tooltipContent}
        content={tooltipContent}
        position={position}
      >
        {children}
      </Tooltip>
      <WantDifferentTherapistModal
        open={openWantDifferentProvider}
        setOpen={setOpenWantDifferentProvider}
        serviceLine={serviceLine}
        currentProviderName={providerName}
      />
    </>
  )
}

export default BookingStatusTooltip
