import { useEffect, useState } from 'react'

import { useAuth } from '../contexts/AuthProvider'
import type { CarePlan, Condition, Patient } from '../types/Patient'
import { UseGetAllowedToBookHistory } from '../queries/booking/UseGetAllowedToBookHistory'

const localStorageKey = 'DOTCOM_NOW_ALLOWED_BOOKING_MESSAGING'

const useHandleYesNowAllowedToBook = () => {
  const { user } = useAuth()

  const {
    data: allowedToBookHistories,
    isLoading: isLoadingAllowedToBookHistories,
  } = UseGetAllowedToBookHistory({
    patients: user.roster,
  })

  const [patientsNowAllowedToBook, setPatientsNowAllowedToBook] = useState<
    (Patient | Partial<Patient>)[]
  >([])

  useEffect(() => {
    if (!user?.roster?.length || isLoadingAllowedToBookHistories) return

    const dotcomNowAllowedBooking =
      JSON.parse(localStorage.getItem(localStorageKey)) || {}

    const tempPatientsNowAllowedToBook: (Patient | Partial<Patient>)[] = []

    user.roster.forEach((p) => {
      const hasPatientAnySessions = p?.conditions?.some((c: Condition) =>
        c?.carePlans?.some((cp: CarePlan) => cp?.sessions?.length > 0)
      )

      // patient went from not allowed to book to allowed AND has no sessions yet?
      const isConditionValidForDisplaying =
        !hasPatientAnySessions &&
        allowedToBookHistories[p.id]?.[0]?.changedTo === true &&
        allowedToBookHistories[p.id]?.[1]?.changedTo === false

      // is patient on the list? YES.
      if (dotcomNowAllowedBooking[p.id]) {
        // patient on the list but now NOT allowed to book? take patient out from the list.
        if (!isConditionValidForDisplaying) {
          delete dotcomNowAllowedBooking[p.id]

          localStorage.setItem(
            localStorageKey,
            JSON.stringify(dotcomNowAllowedBooking)
          )

          return
        } else {
          // patient on the list and now allowed to book? show msg for patient if status is TO_SHOW. (DISMISSED = do not show)
          if (dotcomNowAllowedBooking[p.id] === 'TO_SHOW') {
            tempPatientsNowAllowedToBook.push(p)
            return
          }
        }
      }
      // is patient on the list? NO.
      else {
        // patient NOT on the list and now allowed to book? put patient on the list and show msg for patient.
        if (isConditionValidForDisplaying) {
          tempPatientsNowAllowedToBook.push(p)

          dotcomNowAllowedBooking[p.id] = 'TO_SHOW'

          localStorage.setItem(
            localStorageKey,
            JSON.stringify(dotcomNowAllowedBooking)
          )

          return
        }
      }
    })

    setPatientsNowAllowedToBook(tempPatientsNowAllowedToBook)
  }, [user, isLoadingAllowedToBookHistories, allowedToBookHistories])

  const handleDismiss = () => {
    const dotcomNowAllowedBooking =
      JSON.parse(localStorage.getItem(localStorageKey)) || {}

    for (let i = 0; i < patientsNowAllowedToBook.length; i++) {
      const p = patientsNowAllowedToBook[i]
      dotcomNowAllowedBooking[p.id] = 'DISMISSED'
    }

    localStorage.setItem(
      localStorageKey,
      JSON.stringify(dotcomNowAllowedBooking)
    )

    setPatientsNowAllowedToBook([])
  }

  return {
    patientsToShowNowAllowedMsgFor: patientsNowAllowedToBook,
    handleDismissMsgNowAllowedPatients: handleDismiss,
  }
}

export default useHandleYesNowAllowedToBook
