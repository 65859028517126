import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { TimeSlot } from '../../types/Booking'
import type { Patient } from '../../types/Patient'

interface GetAppointmentSlotsProps {
  providerId: string
  sessionDuration: number
  patient: Patient | Partial<Patient>
}

export const useGetAppointmentSlots = (
  params: GetAppointmentSlotsProps,
  deps = params
) =>
  useQuery(
    ['getAppointmentSlots', deps],
    async () => getAppointmentSlots(params),
    {
      enabled: Boolean(params.providerId),
    }
  )

const getAppointmentSlots = async (
  params: GetAppointmentSlotsProps
): Promise<TimeSlot[]> => {
  const token: string = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/providers/${
      params.providerId
    }/appointment_slots?duration_in_minutes=${params.sessionDuration}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  const slots: TimeSlot[] = result.map((slot: string[]) => ({
    startOriginal: slot[0],
    endOriginal: slot[1],
  }))

  // sort dates ascendingly
  slots.sort(
    (a, b) =>
      new Date(a.startOriginal).valueOf() - new Date(b.startOriginal).valueOf()
  )

  return slots
}
