import type { PaymentMethod } from '../types/User'
import { convertBackendTopicForFrontend } from '../queries/resources/GetResourceTopics'
import type { ResourceTopic } from '../types/Resources'
import { auth } from '../config/firebase'
import type { RelationshipType } from '../types/Patient'
import { relationshipOptionsProfile } from '../constants/values'

export const getTopicsForPatient = async (
  patientId: string
): Promise<ResourceTopic[]> => {
  try {
    const token = await auth.currentUser.getIdToken()
    const backendTopics: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v1/preferences/patients/${patientId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return convertBackendTopicForFrontend(backendTopics || [])
  } catch (error) {
    return []
  }
}

export const getPaymentMethod = async (): Promise<PaymentMethod> => {
  try {
    const token = await auth.currentUser.getIdToken()
    const paymentMethods: PaymentMethod[] = await fetch(
      `${import.meta.env.VITE_DOTCOM_JAVA_PAYMENTS_URL}v1/payment-methods`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return paymentMethods?.[0] || null
  } catch (error) {
    return null
  }
}

export const getPatientRelationship = async (
  fromPersonId: string,
  toPersonId: string
): Promise<RelationshipType> => {
  try {
    if (fromPersonId === toPersonId)
      return relationshipOptionsProfile.find((r) => r.key === 'myself')

    const token = await auth.currentUser.getIdToken()
    const response = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
      }v1/relationships?fromPersonId=${fromPersonId}&toPersonId=${toPersonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return (
      relationshipOptionsProfile.find(
        (r) => r.key === response[0].type.toLowerCase()
      ) || null
    )
  } catch (error) {
    return null
  }
}

export const updatePatientRelationship = async (
  fromPersonId: string,
  toPersonId: string,
  relationshipType: string
): Promise<boolean> => {
  try {
    if (relationshipType === 'MYSELF') return true

    const token = await auth.currentUser.getIdToken()
    await fetch(
      `${import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL}v1/relationships`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          fromPersonId,
          toPersonId,
          type: relationshipType,
        }),
      }
    ).then((response) => response.json())

    return true
  } catch (error) {
    return false
  }
}
