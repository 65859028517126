import React, { useEffect, useState } from 'react'
import { ChevronRightIcon, RefreshIcon } from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { flushSync } from 'react-dom'

import AppLogo from '../../components/AppLogo'
import { primaryButtonClass } from '../../constants/classConstants'
import { useSaveWhatBringsYouHere } from '../../mutations/onboarding/SaveWhatBringsYouHere'
import { useAuth } from '../../contexts/AuthProvider'
import useIsIEPOnly from '../../hooks/useIsIEPOnly'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { ONBOARDING_STEP } from '../../types/Patient'

export type InterestOption = {
  name: string
  key: string
}

const MIXPANEL_DATA = {
  eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
  properties: { step: ONBOARDING_STEP.whatBringsYouHere },
}

export const options: InterestOption[] = [
  { key: 'therapy', name: 'Therapy' },
  { key: 'support_materials', name: 'Support materials' },
  { key: 'not_sure', name: 'Not sure what I need' },
]

const WhatBringsYouHere: React.FC = () => {
  const location = useLocation()
  const iepOnlyExperience = useIsIEPOnly()
  const [searchParams] = useSearchParams()
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  const { mutate: callSaveWhatBringsYouHere, isLoading } =
    useSaveWhatBringsYouHere()
  const [selectedOptions, setSelectedOptions] = useState<InterestOption[]>([])

  const toggleOption = (option: InterestOption) => {
    if (!selectedOptions.includes(option))
      setSelectedOptions((current) => [...current, option])
    else
      setSelectedOptions((current) =>
        current.filter((opt: InterestOption) => opt.key !== option.key)
      )
  }

  const handleNext = () =>
    callSaveWhatBringsYouHere(
      { userId: user.data.id, interests: selectedOptions },
      {
        onSettled: () => {
          const newUser = cloneDeep(user)
          newUser.data.whatBringsYouHere = selectedOptions
          flushSync(() => {
            setUser(newUser)
            goToYourStory()
          })
        },
      }
    )

  const currentSearchParams = new URLSearchParams(searchParams).toString()

  const goToYourStory = () =>
    navigate(`/onboarding/your-story?${currentSearchParams}`, {
      state: location.state,
    })

  useEffect(() => {
    if (!user) return

    trackMixPanel(MIXPANEL_DATA)

    const currentPatient = user.roster.find(
      (p) => p.id === searchParams.get('patientId')
    )

    if (user?.roster?.length && user?.data?.whatBringsYouHere?.length)
      navigate('/dashboard')
    else if (
      iepOnlyExperience ||
      (Boolean(currentPatient) && !currentPatient?.allowedToBook) ||
      !!currentPatient?.isSeparateLogin
    )
      goToYourStory()
  }, [user])

  return (
    <div className="flex flex-col gap-8 text-text-primary">
      {/* Header */}
      <AppLogo classes="flex self-center" />
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="whitespace-pre-wrap text-center text-lg font-semibold">
          What brings you to Huddle Up?
        </p>
        <p className="text-center text-sm">Please select all that apply</p>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-2 sm:gap-4">
        {options.map((option: InterestOption) => (
          <button
            key={option.key}
            onClick={() => toggleOption(option)}
            disabled={isLoading}
            className={`${
              selectedOptions.includes(option)
                ? 'border border-cta-default bg-components-paleBlue text-cta-default'
                : 'border border-components-fillBorders bg-components-fillBorders text-text-primary'
            } rounded-xl p-3 text-sm font-semibold sm:rounded-2xl sm:p-4 sm:text-base`}
          >
            {option?.name}
          </button>
        ))}
      </div>

      <button
        className={primaryButtonClass}
        disabled={selectedOptions?.length === 0 || isLoading}
        onClick={handleNext}
      >
        {isLoading ? (
          <>
            Loading <RefreshIcon className="loader h-5 w-5 text-white" />
          </>
        ) : (
          <>
            Next <ChevronRightIcon className="h-5 w-5" />
          </>
        )}
      </button>

      <SkipAndComeBackLater
        mixPanelData={{
          ...MIXPANEL_DATA,
          properties: {
            ...MIXPANEL_DATA.properties,
            skipped: true,
          },
        }}
      />
    </div>
  )
}

export default WhatBringsYouHere
