import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { DASHBOARD_RATING_SURVEY_ID } from '../../constants/values'

interface DashboardSurveyGetLatestResult {
  createdAt: number
  modifiedAt: number
  createdBy: string
  modifiedBy: string
  version: number
  id: string
  survey: {
    createdAt: number
    modifiedAt: number
    createdBy: string
    modifiedBy: string
    version: number
    id: string
    name: string
  }
  date: string
  userId: string
}

export const useGetDashboardSurveyGetLatest = () =>
  useQuery(['getDashboardSurveyGetLatest'], async () =>
    getDashboardSurveyGetLatest()
  )

const getDashboardSurveyGetLatest =
  async (): Promise<DashboardSurveyGetLatestResult | null> => {
    try {
      const token: string = await auth.currentUser.getIdToken()
      const result = await fetch(
        `${
          import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL
        }/v1/survey/${DASHBOARD_RATING_SURVEY_ID}/getLatest`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => response.json())

      return result
    } catch (error) {
      return Promise.reject(error)
    }
  }
