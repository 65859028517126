import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ChevronLeftIcon, DownloadIcon } from '@heroicons/react/solid'
import ReactPlayer from 'react-player'
import { Document, Page } from 'react-pdf'

import type { ResourceTopic } from '../../types/Resources'
import { primaryButtonClassSmall } from '../../constants/classConstants'
import ApplePodcastPlayer from './components/ApplePodcastPlayer'
import { useGetResourceForPreview } from '../../queries/resources/GetResourceForPreview'
import { useGetResourceTopics } from '../../queries/resources/GetResourceTopics'
import { useGetRecommendations } from '../../queries/resources/GetRecommendations'
import useMediaQuery from '../../hooks/useMediaQuery'
import { useGetResourceProgressId } from '../../queries/resources/GetResourceProgressId'
import { useTouchResourceProgress } from '../../mutations/resources/TouchResourceProgress'
import ResourcesGridDisplay from './components/ResourceGridDisplay'
import BookmarkButton from './components/BookmarkButton'
import StarsRating from './components/StarsRating'
import MultimediaScreenLoader from './components/MultimediaScreenLoader'
import useTrackResourceVisit from '../../hooks/useTrackResourceVisit'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { useAuth } from '../../contexts/AuthProvider'

const ViewResource: React.FC = () => {
  const isLargeEnough = useMediaQuery('(min-width: 640px)')
  const navigate = useNavigate()
  const location = useLocation()
  const user = useAuth()
  const { resourceId } = useParams()
  const [numPages, setNumPages] = useState<number>(0)
  const { data: resource, isLoading } = useGetResourceForPreview({ resourceId })
  const { data: resourceProgressId } = useGetResourceProgressId({ resourceId })
  const { mutate: callTouchResourceProgress } = useTouchResourceProgress()
  const { data: topics, isLoading: isLoadingTopics } = useGetResourceTopics()
  const { data: recommendations, refetch: refetchRecommendations } =
    useGetRecommendations({
      resourceId,
      patientId:
        location?.state?.recommendForPatientId &&
        location?.state?.recommendForPatientId !== 'myself'
          ? location?.state?.recommendForPatientId
          : null,
    })
  const [onClickOnRecommended] = useTrackResourceVisit(resource)

  // refresh after 1 hour of inactivity
  const timeout: number = 60 * 60 * 1000
  const timeoutRef = useRef(null)

  const handlePlay = () => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.RESOURCE_PLAYED,
      properties: {
        id: resource?.id,
        name: resource?.title,
        accountID: user.user.data.id,
        format: resource.format,
        audiences: resource.audiences,
        serviceLines: resource.serviceLines,
        topicOfInterest: resource.topicKeys,
      },
    })
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => {
          window.location.reload()
        }, timeout)
      } else {
        clearTimeout(timeoutRef.current)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      clearTimeout(timeoutRef.current)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [timeout])

  // touch progress on resource
  useEffect(() => {
    if (!resourceProgressId) return

    let isTabFocused = document.visibilityState === 'visible'

    const handleVisibilityChange = () => {
      isTabFocused = document.visibilityState === 'visible'
    }

    const interval = setInterval(() => {
      if (isTabFocused) {
        callTouchResourceProgress({ resourceProgressId })
      }
    }, 10000)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      clearInterval(interval)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [resourceProgressId])

  // refetch recommendations
  useEffect(() => {
    if (resource?.id === resourceId) return

    refetchRecommendations()
    setNumPages(0)
  }, [resource])

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [resourceId])

  if (!resource?.id && !isLoading) return <Navigate to="/resources" />

  if (isLoading) return <MultimediaScreenLoader />

  return (
    <div className="flex w-full max-w-5xl flex-col gap-10 sm:items-center sm:py-20 xs:gap-6">
      {/* Back button */}
      <div className="mb-4 flex w-full items-start text-base font-semibold text-cta-default underline xs:mb-0">
        <button
          onClick={() => {
            const from = location.state?.fromPathname
            if (from) navigate(-1)
            else navigate('/resources')
          }}
          className="flex items-center"
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Back
        </button>
      </div>

      {/* Content */}
      <div className="resources-content flex w-full flex-col gap-10 rounded-2xl bg-white shadow-subtle xs:gap-6">
        {/* Header */}
        <div className="flex flex-col gap-4 p-8 pb-0 xs:gap-2 xs:p-4">
          <div className="flex items-center gap-2">
            <p className="text-2xl font-semibold xs:text-xl">
              {resource.title}
            </p>
            <BookmarkButton resource={resource} iconClasses="h-6 w-6" />
          </div>
          <div className="flex items-center gap-2 text-base text-text-secondary xs:text-sm">
            <img src={resource?.format?.icon} alt="icon" className="h-3.5" />
            {resource.duration} min {resource?.format?.activityName}
          </div>
          <div className="flex justify-between xs:flex-col xs:gap-4">
            <div className="my-0.5 flex flex-wrap gap-2">
              {!isLoadingTopics &&
                React.Children.toArray(
                  topics
                    .filter((t: ResourceTopic) =>
                      resource.topicKeys.includes(t.key)
                    )
                    ?.map((t: ResourceTopic) => (
                      <div className="rounded-lg border border-components-fields px-4 py-2 text-sm font-semibold text-text-secondary xs:px-2 xs:py-1 xs:text-xs">
                        {t?.name}
                      </div>
                    ))
                )}
            </div>
            {['PRINTABLE'].includes(resource.format.id) && (
              <button
                className={`${primaryButtonClassSmall} self-end`}
                onClick={() => {
                  trackMixPanel({
                    eventName: MIXPANEL_EVENT.FILE_DOWNLOADED,
                    properties: {
                      id: resource?.id,
                      name: resource?.title,
                      accountID: user.user.data.id,
                      fileType: 'Resource',
                    },
                  })
                  window.open(resource.contentUrl, '_blank')
                }}
              >
                <DownloadIcon className="h-5 w-5" /> Download
              </button>
            )}
          </div>
          <p className="whitespace-pre-wrap text-base xs:text-sm">
            {resource.description}
          </p>
        </div>

        {/* Body */}
        {resource.format.id === 'ARTICLE' ||
        resource.format.id === 'PRINTABLE' ? (
          <div className="mx-auto mb-8 h-full max-h-[50vh] overflow-y-auto overflow-x-hidden sm:max-h-[90vh] xs:mb-4">
            <Document
              file={resource.contentUrl}
              className="space-y-0 sm:space-y-2"
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {numPages > 0 &&
                React.Children.toArray(
                  [...Array(numPages)].map((_, page) => (
                    <Page
                      pageNumber={page + 1}
                      className="-mx-6 border-b shadow-none last:border-b-0 sm:mx-auto sm:border-b-0 sm:shadow-subtle [&>*]:!h-full [&>*]:!w-full"
                    />
                  ))
                )}
            </Document>
          </div>
        ) : resource.format.id === 'AUDIO' ? (
          <div className="flex w-full flex-col gap-8 p-8 pt-0">
            <ApplePodcastPlayer
              podcastUrl={resource.contentUrl}
              resourceItem={resource}
            />
          </div>
        ) : (
          resource.format.id === 'VIDEO' && (
            <ReactPlayer
              url={resource?.contentUrl}
              className="react-player"
              width="100%"
              height={isLargeEnough ? '585px' : '275px'}
              style={{ borderRadius: '100px' }}
              controls={true}
              onPlay={handlePlay}
            />
          )
        )}
      </div>

      {/* Stars */}
      <StarsRating
        title={`Was this ${resource.format.activityName} helpful?`}
        resourceId={resourceId}
        resource={resource}
      />

      {/* Divider */}
      <div className="w-full border-t border-text-secondary" />

      {/* Recommended */}
      {recommendations ? (
        <div className="flex w-full flex-col gap-10 xs:pb-10">
          <p className="text-2xl font-semibold xs:text-center xs:text-xl">
            Recommended
          </p>
          <ResourcesGridDisplay
            resources={recommendations}
            onClick={onClickOnRecommended}
            patientId={location?.state?.recommendForPatientId}
          />
        </div>
      ) : (
        <p className="text-base">No recommendations.</p>
      )}
    </div>
  )
}

export default ViewResource
