import {
  ChevronLeftIcon,
  ExclamationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import {
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { yupResolver } from '@hookform/resolvers/yup'
import { EMAIL_REGEX } from '../../constants/regex'
import type { User } from 'firebase/auth'
import { updateEmail } from 'firebase/auth'
import { useToastContext } from '../../contexts/ToastContext'
import { auth } from '../../config/firebase'
import { useSendEmailVerification } from '../../mutations/emails/SendEmailVerification'
import { useGetClientInfo } from '../../queries/onboarding/GetClientInfo'
import { useUpdateUserEmail } from '../../mutations/user/UpdateUserEmail'
import { parseJwt } from '../../helpers/utils'

type EmailForm = {
  email: string
}

const formSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your email.')
    .matches(EMAIL_REGEX, 'Please enter a valid email address.')
    .trim(),
})

const ResendConfirmationEmail: React.FC = () => {
  const { data: clientInfo } = useGetClientInfo()
  const navigate = useNavigate()
  const currentUser: User = auth.currentUser
  const [editEmail, setEditEmail] = useState<boolean>(false)
  const [loadingSendingEmail, setLoadingSendingEmail] = useState<boolean>(false)
  const addToast = useToastContext()
  const { mutateAsync: callSendEmailVerification } = useSendEmailVerification()
  const { mutateAsync: callUpdateUserEmail } = useUpdateUserEmail()

  const goBack = () => {
    if (editEmail) setEditEmail(false)
    else navigate('/sign-in')
  }

  const resendEmail = async () => {
    try {
      setLoadingSendingEmail(true)

      await callSendEmailVerification({
        clientId: clientInfo?.clientId,
      })

      navigate('/confirmation/success')
    } catch (e) {
      addToast('error', 'Something went wrong.')
    } finally {
      setLoadingSendingEmail(false)
    }
  }

  const onChangeLoginEmail = async (data: EmailForm) => {
    try {
      setLoadingSendingEmail(true)

      await updateEmail(currentUser, data.email)

      const token: string = await currentUser.getIdToken()

      const id: string = parseJwt(token).dct_user_id

      await callUpdateUserEmail({ email: data.email, userId: id })

      await callSendEmailVerification({
        clientId: clientInfo?.clientId,
      })

      navigate('/confirmation/success')
    } catch (e) {
      addToast('error', 'Something went wrong.')
    } finally {
      setLoadingSendingEmail(false)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailForm>({
    mode: 'all',
    resolver: yupResolver(formSchema),
  })

  if (!currentUser) return <Navigate to="/sign-in" />

  return (
    <div className="flex flex-col items-center gap-8 text-text-primary">
      {/* Back link */}
      <div className="mt-6 flex w-full items-start">
        <button
          onClick={goBack}
          className="absolute flex items-start sm:top-6 sm:left-4 sm:w-2/3 xs:top-4 xs:left-2 xs:w-full"
        >
          <ChevronLeftIcon className="h-5 w-5" />
          <span className="font-semibold">Back</span>
        </button>
      </div>

      <p className="text-2xl font-semibold">Resend confirmation email</p>

      {editEmail ? (
        <form
          className="flex w-full flex-col"
          onSubmit={handleSubmit(onChangeLoginEmail)}
        >
          <p className="text-center text-base font-semibold">
            What is your correct email?
          </p>
          <p className="mt-2 mb-8 text-center text-base">
            This will update your login email.
          </p>

          <label
            htmlFor="email"
            className="block text-base font-semibold xs:text-sm"
          >
            Email
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={errors.email ? inputErrorClass : inputValidClass}
              placeholder="Email address"
              defaultValue={currentUser.email}
              {...register('email')}
            />
            {errors.email && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.email?.message}
          </p>

          <button
            className={`${primaryButtonClass} mt-8 w-full`}
            type="submit"
            disabled={loadingSendingEmail}
          >
            {loadingSendingEmail ? (
              <>
                <RefreshIcon
                  className="loader h-5 w-5 text-white"
                  aria-hidden="true"
                />
                Loading
              </>
            ) : (
              'Resend confirmation'
            )}
          </button>
        </form>
      ) : (
        <div className="flex w-full flex-col items-center">
          <p className="text-center text-base font-semibold">
            Is this your correct email?
          </p>
          <p className="mt-4 text-center text-base">{currentUser.email}</p>

          <div className="mt-8 flex w-full gap-2">
            <button
              className={`${tertiaryButtonClass} w-1/2`}
              onClick={() => setEditEmail(true)}
              disabled={loadingSendingEmail}
            >
              No, edit email
            </button>
            <button
              className={`${primaryButtonClass} w-1/2`}
              onClick={resendEmail}
              disabled={loadingSendingEmail}
            >
              {loadingSendingEmail ? (
                <>
                  <RefreshIcon
                    className="loader h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  Loading
                </>
              ) : (
                'Yes, resend'
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ResendConfirmationEmail
