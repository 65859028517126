import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import CONFETTI from '../../../assets/images/SuccessGlobe.svg'
import { tertiaryButtonClass } from '../../../constants/classConstants'
import { useRegister } from '../../../contexts/RegisterContext'
import { useGetClientInfo } from '../../../queries/onboarding/GetClientInfo'

export const EligibilityVerified: React.FC = () => {
  const { data: clientInfo } = useGetClientInfo()
  const { userInfo, setUseWorkEmail } = useRegister()
  const navigate = useNavigate()

  const continueToCreateAnAccount = (useWorkEmailValue: boolean) => {
    setUseWorkEmail(useWorkEmailValue)
    navigate('/sign-up/create-login')
  }

  if (!clientInfo?.requireEligibility) return <Navigate to="/sign-in" />

  return (
    <div className="max-w-6xl items-center text-center sm:px-4">
      <p className="flex flex-wrap justify-center whitespace-pre text-base font-semibold sm:text-2xl">
        Eligibility verified, Yay!
      </p>

      <img
        src={CONFETTI}
        alt="success confetti"
        className="h-40 w-40 sm:h-48 sm:w-48"
      />

      <div>
        <p className="text-center text-lg font-semibold xs:text-sm">
          Let's create a login to make it official!
        </p>
        <p className="text-base xs:text-xs">
          Would you like to use the following email?{' '}
        </p>
      </div>

      <p className="text-base">{userInfo?.email}</p>

      <div className="flex gap-4">
        <button
          className={tertiaryButtonClass}
          onClick={() => continueToCreateAnAccount(true)}
        >
          Yes, use my work email
        </button>
        <button
          className={tertiaryButtonClass}
          onClick={() => continueToCreateAnAccount(false)}
        >
          No, use a different email
        </button>
      </div>
    </div>
  )
}
