import React, { useEffect, useState } from 'react'
import { PencilIcon } from '@heroicons/react/outline'
import cloneDeep from 'lodash.clonedeep'
import { useSearchParams, useLocation } from 'react-router-dom'

import AccountInfoModal from '../../components/Modals/AccountInfoModal'
import AVATAR from '../../assets/avatars/User.svg'
import { tertiaryButtonClass } from '../../constants/classConstants'
import { useAuth } from '../../contexts/AuthProvider'
import type { AccountInfoData } from '../../types/Dashboard'
import { useSetAccountSettings } from '../../mutations/dashboard/UpdateAccountSettings'
import { useToastContext } from '../../contexts/ToastContext'
import { updatePassword } from 'firebase/auth'
import { auth } from '../../config/firebase'
import type { Patient } from '../../types/Patient'
import { getCardBrandIcon, getSourceFromLocation } from '../../helpers/utils'
import EditCreditCardModal from '../../components/Modals/EditCreditCardModal'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

const AccountSettings: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const addToast = useToastContext()
  const { user, setUser } = useAuth()
  const [isOpenAccountInfoModal, setIsOpenAccountInfoModal] =
    useState<boolean>(false)
  const [isOpenEditCreditCardModal, setIsOpenEditCreditCardModal] =
    useState<boolean>(false)
  const { mutate: callUpdateAccountSetting } = useSetAccountSettings()
  const { mutate: callUpdateProfile } = useUpdateProfile()
  const [accountInfo, setAccountInfo] = useState<AccountInfoData>({
    firstName: user.data.firstName,
    lastName: user.data.lastName,
    preferredName: user.data.preferredName,
    email: user.data.email,
    phoneNumber: user.data.phoneNumber,
  })
  const paymentMethod = user.paymentMethod

  useEffect(() => {
    const shouldUpdateCreditCard = searchParams.get('updateCreditCard')
    if (shouldUpdateCreditCard === 'true') {
      setIsOpenEditCreditCardModal(true)
      searchParams.delete('updateCreditCard')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  const handleOnFinishAccountInfoModal = (data: Partial<AccountInfoData>) => {
    setAccountInfo((crt) => ({ ...crt, ...data }))
    callUpdateAccountSetting(
      { user, data },
      {
        onSettled: async () => {
          try {
            if (data.password)
              await updatePassword(auth.currentUser, data.password)
          } catch (err) {
            addToast(
              'error',
              'Something went wrong when changing password. Please log out and try again.'
            )
          }

          try {
            // update user
            const newUser = cloneDeep(user)
            newUser.data = { ...newUser.data, ...data }

            // update patient with parent relationship as 'myself'
            const myselfAsPatient = newUser.roster.find(
              (p: Patient) => p?.relationship?.key === 'myself'
            )
            if (myselfAsPatient) {
              myselfAsPatient.firstName = data.firstName
              myselfAsPatient.lastName = data.lastName
              myselfAsPatient.preferredName = data.preferredName

              callUpdateProfile({ patient: myselfAsPatient, user: newUser })
              const patientIndex = newUser.roster.findIndex(
                (p: Patient) => p.id === myselfAsPatient.id
              )
              newUser.roster[patientIndex] = myselfAsPatient
            }

            setUser(newUser)

            trackMixPanel({
              eventName: MIXPANEL_EVENT.ACCOUNT_UPDATED,
              properties: {
                category: 'Account Settings',
                accountId: user.data.id,
                source: getSourceFromLocation(location),
              },
            })

            addToast('success', 'Account updated successfully.')
          } catch (err) {
            addToast(
              'error',
              'Something went wrong when chaning account settings. Please log out and try again.'
            )
          }
        },
      }
    )
  }

  return (
    <>
      <div className="mb-auto mt-10 flex w-full flex-col gap-6 pt-1 pb-6 text-text-primary sm:mt-24 sm:w-2/3 sm:gap-20">
        <h1 className="pl-4 text-lg font-semibold sm:pl-0 sm:text-2xl">
          Account Settings
        </h1>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-6 bg-white p-4 shadow-subtle sm:rounded-lg sm:border sm:border-components-fields sm:p-6">
            <h2 className="text-base font-medium sm:text-2xl">
              Account Information
            </h2>
            <div className="flex flex-col gap-6 rounded-lg border border-components-fields px-4 pt-4 pb-4 sm:gap-4 sm:pb-6">
              <div className="flex flex-row items-center gap-2 sm:gap-4">
                <img
                  src={AVATAR}
                  alt="patient-avatar"
                  className="h-10 w-10 sm:h-16 sm:w-16"
                />
                <h3 className="flex-1 text-sm font-semibold sm:text-lg">
                  {`${accountInfo.firstName} ${accountInfo.lastName} ${
                    accountInfo.preferredName
                      ? '(' + accountInfo.preferredName + ')'
                      : ''
                  }`}
                </h3>
                <button
                  onClick={() => setIsOpenAccountInfoModal(true)}
                  className={`${tertiaryButtonClass} my-0 space-x-1.5 sm:my-1.5 xs:p-2.5`}
                >
                  <PencilIcon className="h-5 w-5" />
                  <span className="hidden sm:flex">Edit</span>
                </button>
              </div>
              <div className="ml-0 flex flex-col items-start gap-4 whitespace-nowrap pl-0 text-text-secondary sm:ml-16 sm:flex-row sm:flex-wrap sm:items-center sm:gap-6 sm:pl-2">
                <div className="flex flex-1 flex-col gap-1">
                  <p className="text-sm font-semibold sm:text-base ">
                    Phone number
                  </p>
                  <p className="text-sm font-normal sm:text-base">
                    {accountInfo.phoneNumber || '-'}
                  </p>
                </div>
                <div className="flex flex-1 flex-col gap-1">
                  <p className="text-sm font-semibold sm:text-base ">Email</p>
                  <p className="text-sm font-normal sm:text-base">
                    {accountInfo.email}
                  </p>
                </div>
                <div className="flex flex-1 flex-col gap-1">
                  <p className="text-sm font-semibold sm:text-base ">
                    Password
                  </p>
                  <p className="text-sm font-normal sm:text-base">**********</p>
                </div>
              </div>
            </div>
          </div>
          {Boolean(paymentMethod?.cardLastDigits) && (
            <div className="flex flex-col gap-6 bg-white p-6 shadow-subtle sm:rounded-lg sm:border sm:border-components-fields">
              <h2 className="text-base font-medium sm:text-2xl">
                Payment Information
              </h2>
              <div className="flex flex-row items-center gap-6 rounded-lg border border-components-fields p-4">
                <div className="flex flex-1 flex-col justify-center gap-1">
                  {paymentMethod ? (
                    <>
                      <p className="text-sm font-semibold sm:text-base">
                        Credit/Debit Card
                      </p>
                      <div className="flex flex-row items-center gap-6">
                        <img
                          src={getCardBrandIcon(paymentMethod.cardBrand)}
                          alt="card"
                          className="h-6 w-9"
                        />
                        <p className="text-xs font-normal text-text-secondary sm:text-base">
                          -{paymentMethod.cardLastDigits}
                        </p>
                      </div>
                    </>
                  ) : (
                    <p className="text-base xs:text-sm">
                      No payment information.
                    </p>
                  )}
                </div>
                <button
                  className={`${tertiaryButtonClass} space-x-1.5 xs:p-2.5`}
                  onClick={() => setIsOpenEditCreditCardModal(true)}
                >
                  <PencilIcon className="h-5 w-5" />
                  <span className="hidden sm:flex">Edit</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <AccountInfoModal
        isOpen={isOpenAccountInfoModal}
        setIsOpen={setIsOpenAccountInfoModal}
        defaultValues={accountInfo}
        onFinish={handleOnFinishAccountInfoModal}
      />
      <EditCreditCardModal
        open={isOpenEditCreditCardModal}
        setOpen={setIsOpenEditCreditCardModal}
      />
    </>
  )
}

export default AccountSettings
