import { useAuth } from '../contexts/AuthProvider'
import type { Patient } from '../types/Patient'
import type { Product } from '../types/User'

const useIsIEPOnly = () => {
  const { user } = useAuth()
  const clientIsEducation = user.data.clientData.clientType === 'EDUCATION'
  const allProductsAreIEP =
    user?.products?.length > 0 && user.products.every((p: Product) => p.isIep)
  const allUsersAreIEP = user.roster.every((p: Patient) => p.isIepOnly)
  const allUsersAreIEPAndNotEligibile = user.roster.every(
    (p: Patient) => p.isIepOnly && !p.isEligible
  )
  const isEligibilityOn = user.data.clientData.requireEligibility

  // no roster
  if (!user.roster.length) return false

  // not education, not IEP
  if (!clientIsEducation) return false

  // only IEP products
  if (allProductsAreIEP) return true

  // some products are Gen Ed from now on

  if (isEligibilityOn)
    // all users must be IEP and ineligible
    return allUsersAreIEPAndNotEligibile
  // all users must be IEP
  else return allUsersAreIEP
}

export default useIsIEPOnly
