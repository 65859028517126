import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { User } from '../../types/User'
import type { Patient } from '../../types/Patient'

export type Payment = {
  id: string
  patientName: string
  patientId: string
  description: string
  appointmentDate?: string
  price: string
  chargedAt?: string
  lastFailedAt?: string
  refundedAt?: string
  status?: string
}

type GetPaymentsParams = {
  user: User
}

export const useGetPayments = (props: GetPaymentsParams) =>
  useQuery(['getPayments'], async () => getPayments(props.user))

const getPayments = async (user: User): Promise<Payment[]> => {
  const token: string = await auth.currentUser.getIdToken()
  const data: any = await fetch(
    `${import.meta.env.VITE_DOTCOM_JAVA_PAYMENTS_URL}v1/payments`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  return data.map((payment: any) => {
    const patient = user.roster.find((p: Patient) => p.id === payment.patientId)
    return {
      id: payment.id,
      patientName: `${patient?.firstName} ${patient?.lastName}`,
      patientId: patient.id,
      description: payment.description,
      appointmentDate: payment?.extraDescription,
      price: `$${(payment?.price / 100)?.toFixed(2)}` || '-',
      chargedAt: payment.chargedAt
        ? new Date(payment.chargedAt).toLocaleDateString()
        : null,
      lastFailedAt: payment.lastFailedAt
        ? new Date(payment.lastFailedAt).toLocaleDateString()
        : null,
      refundedAt: payment.refundedAt
        ? new Date(payment.refundedAt).toLocaleDateString()
        : null,
      status: ['PAID', 'REFUNDED', 'FAILED'].includes(payment.status)
        ? payment.status
        : 'PROCESSING',
    }
  })
}
