import React from 'react'

import DashboardElement from '../DashboardElement'
import { useAuth } from '../../../contexts/AuthProvider'
import {
  enumeratedElementsFromArray,
  formatPossessiveNoun,
} from '../../../helpers/generic'
import { useGetClientInfo } from '../../../queries/onboarding/GetClientInfo'
import handleClickSupportEmail from '../../../helpers/supportClick'

const BulkRanOutMessaging: React.FC = () => {
  const { data: clientInfo } = useGetClientInfo()
  const { user } = useAuth()

  const patientsWithNoMoreBulkModelSessions = user?.roster
    ?.filter((patient) =>
      patient?.conditions
        ?.find((c) => !c.isIep)
        ?.carePlans.some(
          (cp) =>
            cp?.bulkModel?.isBulkModel &&
            !cp?.bulkModel?.remainingSessionsOnBulkModel &&
            !cp.sessions.some((s) => s.status === 'pending') &&
            user?.products?.find((p) => p.id === cp.productId)
              ?.additionalSessions === 'NOT_ALLOWED'
        )
    )
    .map((p) => p.firstName)

  const schoolOrOrganization =
    clientInfo?.clientType === 'EDUCATION' ? 'school' : 'organization'

  if (!patientsWithNoMoreBulkModelSessions?.length) return null

  return (
    <DashboardElement
      noGap
      title={
        <span>
          Looks like{' '}
          <span className="text-cta-default">
            {formatPossessiveNoun(
              enumeratedElementsFromArray(patientsWithNoMoreBulkModelSessions)
            )}
          </span>{' '}
          {schoolOrOrganization} has run out of covered sessions.
        </span>
      }
    >
      <p className="text-base xs:text-sm">
        Your {schoolOrOrganization} does not allow booking beyond their covered
        sessions. For more information please contact your{' '}
        {schoolOrOrganization}.
      </p>
      <p className="text-base xs:text-sm">
        If you have any other questions don't hesitate to contact us at{' '}
        <a
          href="mailto:support@huddleupcare.com"
          className="font-medium text-cta-default underline "
          onClick={() => handleClickSupportEmail(user, location)}
        >
          support@huddleupcare.com
        </a>
        .
      </p>
    </DashboardElement>
  )
}

export default BulkRanOutMessaging
