import type { KeyboardEvent } from 'react'
import React, { Fragment, useEffect, useState } from 'react'
import { CalendarIcon } from '@heroicons/react/outline'
import {
  ChevronDownIcon,
  ExclamationCircleIcon,
  PencilIcon,
  PlusIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { useForm, useController, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Listbox, Transition } from '@headlessui/react'
import cloneDeep from 'lodash.clonedeep'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  comboBoxClass,
  comboBoxErrorClass,
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  primaryButtonClassSmall,
  tertiaryButtonClass,
  tertiaryButtonClassSmall,
} from '../../../constants/classConstants'
import {
  emergencyContactRelationships,
  genderIdentities,
  genderOptions,
  languages,
  preferredPronouns as preferredPronounsArray,
  raceOptions,
  relationshipOptionsProfile,
  states,
} from '../../../constants/values'
import type { State } from '../../../types/State'
import { useToastContext } from '../../../contexts/ToastContext'
import type {
  CarePlan,
  Condition,
  Patient,
  RelationshipType,
} from '../../../types/Patient'
import type {
  CancelledTherapists,
  GenderIdentity,
  Race,
} from '../../../types/Profile'
import {
  calculateAge,
  capitalize,
  formatPhoneNumber,
  frontendToBackendTz,
  getTimeZones,
  isValidDefaultDate,
} from '../../../helpers/generic'
import { useUpdateProfile } from '../../../mutations/dashboard/UpdateProfile'
import { useAuth } from '../../../contexts/AuthProvider'
import DatePicker from '../../../components/DatePicker'
import {
  DEFAULT_DATE_REGEX,
  EMAIL_REGEX,
  VALID_PHONE_NUMBER_REGEX,
} from '../../../constants/regex'
import { useSetAccountSettings } from '../../../mutations/dashboard/UpdateAccountSettings'
import type { ServiceLine } from '../../../types/ServiceLine'
import { useGetClientServiceLines } from '../../../queries/onboarding/GetClientServiceLines'
import AddAreasOfFocusModal from '../../../components/Modals/AddAreasOfFocusModal'
import { usePatient } from '../../../contexts/PatientProvider'
import { useGetProvidersLength } from '../../../queries/booking/GetProvidersLength'
import BookNewAreaOfFocusSession from '../../../components/Modals/BookNewAreaOfFocusSession'
import { flushSync } from 'react-dom'
import { getSourceFromLocation, uniqueCarePlans } from '../../../helpers/utils'
import type { Product } from '../../../types/User'
import Tooltip from '../../../components/Tooltip'
import INFO from '../../../assets/icons/info.svg'
import { useGetPatientAccessors } from '../../../queries/dashboard/GetPatientAccessors'
import PatientChangedStateModal from '../../../components/Modals/PatientChangedStateModal'
import { SERVICE_LINES_ARRAY } from '../../../constants/serviceLine'
import handleClickSupportEmail from '../../../helpers/supportClick'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'

const formSchema = (
  forSelf: boolean,
  isBookingNonSponsored: boolean,
  isEligibilityOn: boolean
) =>
  Yup.object().shape({
    carePlans: Yup.mixed(),
    firstName: Yup.string().required('First name is required.').trim(),
    lastName: Yup.string().required('Last name is required.').trim(),
    preferredName: Yup.mixed(),
    birthDate: Yup.string()
      .required('Birthdate is required.')
      .matches(
        DEFAULT_DATE_REGEX,
        'Birthdate must be in valid MM/DD/YYYY format.'
      )
      .test('valid-date', 'Birthdate is not valid.', (enteredDate: string) =>
        isValidDefaultDate(enteredDate)
      )
      .test(
        'test-birth-date-future',
        'Birthdate cannot be in the future.',
        (enteredDate: string) => {
          const date = new Date(enteredDate)
          const now = new Date()
          return date <= now
        }
      )
      .test(
        'test-birth-date-age',
        'Age is not valid',
        (enteredDate: string) => {
          if (isEligibilityOn) return true

          const age = calculateAge(new Date(enteredDate))

          if (forSelf) return age >= 18
          else return age < 18
        }
      )
      .trim(),
    gender: Yup.mixed(),
    genderIdentity: Yup.mixed(),
    preferredPronouns: Yup.mixed(),
    preferredLanguage: Yup.mixed(),
    relationship: Yup.mixed(),
    streetAddress: Yup.string().when([], {
      is: () => isBookingNonSponsored,
      then: (schema) => schema.required('Street address is required.').trim(),
      otherwise: (schema) => schema.trim(),
    }),
    apartmentUnit: Yup.mixed(),
    city: Yup.string().when([], {
      is: () => isBookingNonSponsored,
      then: (schema) => schema.required('City is required.').trim(),
      otherwise: (schema) => schema.trim(),
    }),
    state: Yup.mixed(),
    zip: Yup.string().when([], {
      is: () => isBookingNonSponsored,
      then: (schema) => schema.required('Zip is required.').trim(),
      otherwise: (schema) => schema.trim(),
    }),
    billingStreetAddress: Yup.mixed(),
    billingApartmentUnit: Yup.mixed(),
    billingCity: Yup.mixed(),
    billingState: Yup.mixed(),
    billingZip: Yup.mixed(),
    studentId: Yup.string().nullable(),
    emergencyContact: Yup.object().shape(
      {
        firstName: Yup.string().when(
          ['lastName', 'relationship', 'phoneNumber'],
          {
            is: (
              lastName: string,
              relationship: string,
              phoneNumber: string
            ): boolean => Boolean(lastName || relationship || phoneNumber),
            then: (schema) => schema.required('First name is required.').trim(),
            otherwise: (schema) => schema,
          }
        ),
        lastName: Yup.string().when(
          ['firstName', 'relationship', 'phoneNumber'],
          {
            is: (
              firstName: string,
              relationship: string,
              phoneNumber: string
            ): boolean => Boolean(firstName || relationship || phoneNumber),
            then: (schema) => schema.required('Last name is required.').trim(),
            otherwise: (schema) => schema,
          }
        ),
        relationship: Yup.string().when(
          ['firstName', 'lastName', 'phoneNumber'],
          {
            is: (
              firstName: string,
              lastName: string,
              phoneNumber: string
            ): boolean => Boolean(firstName || lastName || phoneNumber),
            then: (schema) =>
              schema
                .required('Their relationship to this person is required.')
                .trim(),
            otherwise: (schema) => schema,
          }
        ),
        phoneNumber: Yup.string().when(
          ['firstName', 'lastName', 'relationship'],
          {
            is: (
              firstName: string,
              lastName: string,
              relationship: string
            ): boolean => Boolean(firstName || lastName || relationship),
            then: (schema) =>
              schema
                .required('Phone number is required.')
                .trim()
                .matches(VALID_PHONE_NUMBER_REGEX, {
                  name: 'validPhoneNumber',
                  message: 'Please complete the phone number validly.',
                }),
            otherwise: (schema) => schema,
          }
        ),
      },
      [
        ['firstName', 'lastName'],
        ['firstName', 'relationship'],
        ['firstName', 'phoneNumber'],
        ['lastName', 'relationship'],
        ['lastName', 'phoneNumber'],
        ['relationship', 'phoneNumber'],
      ]
    ),
    email: Yup.string()
      .matches(EMAIL_REGEX, {
        message: 'Please enter a valid email address.',
        excludeEmptyString: true,
      })
      .nullable()
      .trim(),
    timeZone: Yup.mixed().required('Time zone is required.'),
    race: Yup.mixed(),
  })

const ProfileTab: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [editMode, setEditMode] = useState<boolean>(false)
  const { user, setUser } = useAuth()
  const { patientId } = useParams()
  const { patient, setPatient } = usePatient()
  const isEligibilityOn = user.data.clientData.requireEligibility
  const addToast = useToastContext()
  const { mutate: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()
  const { mutate: callUpdateAccountSetting } = useSetAccountSettings()
  const forSelf: boolean = patient?.relationship?.key === 'myself'
  const emergencyContactDefault = patient.emergencyContact
    ? cloneDeep(patient.emergencyContact)
    : {
        firstName: '',
        lastName: '',
        relationship: '',
        phoneNumber: '',
      }

  const patientCarePlans: CarePlan[] =
    patient?.conditions?.find((c: Condition) => !c.isIep)?.carePlans || []

  const { data: usersWithAccess, isLoading: isLoadingAccessors } =
    useGetPatientAccessors({ patientId })

  const uniquePatientCarePlans = uniqueCarePlans(
    patient?.conditions?.flatMap((c: Condition) => c.carePlans)
  )

  const isBookingNonSponsored = Boolean(
    patientCarePlans?.some(
      (cp: CarePlan) => cp.bookingNonSponsoredInformationConfirmed
    )
  )

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    reset,
    watch,
  } = useForm<Patient>({
    defaultValues: {
      ...patient,
      streetAddress: patient?.streetAddress || '',
      zip: patient?.zip || '',
      city: patient?.city || '',
      emergencyContact: cloneDeep(emergencyContactDefault),
      email: forSelf ? user.data.email : patient.email || '',
    },
    mode: 'all',
    resolver: yupResolver(
      formSchema(forSelf, isBookingNonSponsored, isEligibilityOn)
    ),
  })

  const formStateWatcher = watch('state')
  const formLanguageWatcher = watch('preferredLanguage')

  const { data: clientAvailableSLs, isLoading: isLoadingClientAvailableSLs } =
    useGetClientServiceLines({
      clientId: user.data.clientId,
      enabled: editMode,
      clientType: user.data.clientData.clientType,
    })
  const [newAreasOfFocusAdded, setNewAreasOfFocusAdded] = useState<
    ServiceLine[]
  >([])
  const [isOpenAddAreasOfFocusModal, setIsOpenAddAreasOfFocusModal] =
    useState<boolean>(false)
  const [isOpenChangedStateModal, setIsOpenChangedStateModal] =
    useState<boolean>(false)
  const [isOpenBookNewAreaOfFocusSession, setIsOpenBookNewAreaOfFocusSession] =
    useState<boolean>(false)
  const [serviceLineOptions, setServiceLineOptions] = useState<ServiceLine[]>(
    []
  )

  const { data: providersLengthReturnValue, isFetching: isFetchingProviders } =
    useGetProvidersLength({
      patient,
      serviceLines: clientAvailableSLs?.filter(
        (sl: ServiceLine) =>
          !patientCarePlans?.some(
            (cp: CarePlan) => cp.displayName === sl.displayName
          )
      ),
      licenseJurisdiction: states.find(
        (s: State) => s?.name === formStateWatcher
      )?.abbrev,
      language: formLanguageWatcher,
      options: {
        enabled: Boolean(
          editMode &&
            user.data.clientData?.clientType !== 'EDUCATION' &&
            !isLoadingClientAvailableSLs &&
            clientAvailableSLs?.length &&
            patientCarePlans
        ),
      },
    })

  useEffect(() => {
    if (
      !editMode ||
      user.data.clientData?.clientType === 'EDUCATION' ||
      !providersLengthReturnValue ||
      !clientAvailableSLs?.length ||
      !patientCarePlans
    )
      return

    const serviceLinesToCheck: ServiceLine[] = clientAvailableSLs.filter(
      (sl: ServiceLine) =>
        !patientCarePlans.some(
          (cp: CarePlan) => cp.displayName === sl.displayName
        )
    )

    const serviceLinesNoTherapistsInState: string[] = Object.entries(
      providersLengthReturnValue
    ).reduce((acc, [key, value]) => {
      if (value === 0) {
        return [
          ...acc,
          serviceLinesToCheck.find((sl: ServiceLine) => sl.serviceType === key)
            .type,
        ]
      }

      return acc
    }, [])

    setServiceLineOptions(
      serviceLinesToCheck.filter(
        (sl: ServiceLine) =>
          !serviceLinesNoTherapistsInState.some(
            (slType: string) => slType === sl.type
          )
      )
    )
  }, [editMode, providersLengthReturnValue, clientAvailableSLs, patient, user])

  const { field: fieldGender } = useController({
    control: control,
    name: 'gender',
    defaultValue: '',
  })

  const { field: fieldGenderIdentity } = useController({
    control: control,
    name: 'genderIdentity',
    defaultValue: null,
  })

  const { field: fieldPreferredPronouns } = useController({
    control: control,
    name: 'preferredPronouns',
    defaultValue: '',
  })

  const { field: fieldPreferredLanguage } = useController({
    control: control,
    name: 'preferredLanguage',
    defaultValue: '',
  })

  const { field: fieldRace } = useController({
    control: control,
    name: 'race',
    defaultValue: null,
  })

  const { field: fieldRelationship } = useController({
    control: control,
    name: 'relationship',
    defaultValue: null,
  })

  const { field: fieldState } = useController({
    control: control,
    name: 'state',
    defaultValue: '',
  })

  const { field: fieldStateBilling } = useController({
    control: control,
    name: 'billingState',
    defaultValue: '',
  })

  const { field: fieldTimeZone } = useController({
    control: control,
    name: 'timeZone',
    defaultValue: '',
  })

  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

  const onSubmit = (newPatient: Patient) => {
    const newConditions: Condition[] = []

    if (newAreasOfFocusAdded?.length) {
      const newCarePlans = []

      newAreasOfFocusAdded.forEach((sl: ServiceLine) => {
        newCarePlans.push({
          serviceType: sl.serviceType,
          language: newPatient.preferredLanguage,
          productId: user.products.find(
            (p: Product) =>
              p.serviceLine.serviceType === sl.serviceType && !p.isIep
          )?.id,
        })
      })

      newConditions.push({
        id: newPatient.conditions?.find((c: Condition) => !c.isIep)?.id,
        carePlans: newCarePlans,
      })
    }

    callUpdateProfile(
      {
        patient: {
          ...newPatient,
          conditions: newConditions,
        },
        user,
      },
      {
        onSuccess: (newlyResultedPatient: Patient) => {
          const newUser = cloneDeep(user)

          if (newPatient?.email?.length && !newlyResultedPatient?.email?.length)
            addToast(
              'warning',
              'Hmm, something went wrong. Please contact support@huddleupcare.com if you have any questions.'
            )

          // also update account settings if patient is updating himself
          if (newPatient?.relationship?.key === 'myself') {
            callUpdateAccountSetting({
              user: newUser,
              data: {
                firstName: newPatient.firstName,
                lastName: newPatient.lastName,
                preferredName: newPatient.preferredName,
              },
            })
            newUser.data.firstName = newPatient.firstName
            newUser.data.lastName = newPatient.lastName
            newUser.data.preferredName = newPatient.preferredName
          }

          let newPatientEmergencyContact = cloneDeep(
            newPatient.emergencyContact
          )

          if (
            Object.values(newPatientEmergencyContact).some((v: string) => !v)
          ) {
            newPatientEmergencyContact = null
          }

          const myNewPatient = {
            ...newlyResultedPatient,
            emergencyContact: newPatientEmergencyContact,
          }

          const patientIndex = newUser.roster.findIndex(
            (p: Patient) => p.id === patientId
          )
          newUser.roster[patientIndex] = myNewPatient

          setEditMode(false)
          if (
            serviceLineOptions.some((sl: ServiceLine) =>
              newAreasOfFocusAdded.includes(sl)
            )
          )
            setIsOpenBookNewAreaOfFocusSession(true)
          flushSync(() => {
            setUser(newUser)
            setPatient(myNewPatient)
          })
          setNewAreasOfFocusAdded([])

          trackMixPanel({
            eventName: MIXPANEL_EVENT.ACCOUNT_UPDATED,
            properties: {
              category: 'Profile',
              accountId: user.data.id,
              source: getSourceFromLocation(location),
            },
          })

          addToast('success', 'Saved successfully!')
        },
        onError: () => {
          addToast('error', 'Something went wrong.')
        },
      }
    )
  }

  const addAreaOfFocusFirstTime = () => {
    if (clientAvailableSLs?.length > 1) navigate('/onboarding/area-focus')
    else setNewAreasOfFocusAdded([clientAvailableSLs[0]])
  }

  const cancelledTherapists: CancelledTherapists[] =
    patient?.conditions
      ?.find((c) => !c.isIep)
      ?.carePlans?.map((cp) => {
        const currentProvider = cp?.sessions[cp.sessions.length - 1]?.therapist

        if (!currentProvider) return null

        if (
          !currentProvider.licenseCredentials.includes(
            `US-${formStateWatcher}`
          ) &&
          formStateWatcher !== patient?.state
        )
          return {
            name: currentProvider.preferredName,
            therapyType: SERVICE_LINES_ARRAY.find(
              (sl) => sl.displayName === cp.displayName
            ).type,
          }
      })
      ?.filter((item) => Boolean(item)) || []

  const onClickSaveButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (Object.keys(errors)?.length || !isValid) {
      addToast('warning', 'Please check errors before saving.')
      return
    }
    e.currentTarget.blur()

    // TODO DCT-3286 reverted
    // if (cancelledTherapists?.length) {
    //   e.preventDefault()
    //   setIsOpenChangedStateModal(true)
    // }
  }

  const restrictedIfSeparateLogin = patient?.isSeparateLogin ? forSelf : true

  return (
    <form
      className="text-text-primary"
      onSubmit={handleSubmit((data: Patient) => onSubmit(data))}
    >
      {/* Header */}
      <div className="flex items-center justify-between">
        <p className="font-mediu text-2xl xs:text-base xs:font-semibold">
          Basic Information
        </p>

        {/* Edit */}
        {!patient?.isDisabled && (
          <div className="flex justify-end xs:hidden">
            <button
              className={`${tertiaryButtonClass} ${editMode ? 'hidden' : ''}`}
              type="button"
              onClick={(e) => {
                setEditMode(true)
                e.currentTarget.blur()
              }}
            >
              <PencilIcon className="h-5 w-5" />{' '}
              <span className="xs:hidden">Edit</span>
            </button>
            <div
              className={`grid grid-cols-2 gap-2 sm:gap-4 ${
                !editMode ? 'hidden' : ''
              }`}
            >
              <button
                disabled={isLoadingUpdateProfile}
                className={tertiaryButtonClass}
                onClick={(e) => {
                  setEditMode(false)
                  reset()
                  e.currentTarget.blur()
                  setNewAreasOfFocusAdded([])
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                disabled={isLoadingUpdateProfile}
                className={primaryButtonClass}
                onClick={(e) => onClickSaveButton(e)}
                type="submit"
              >
                {isLoadingUpdateProfile ? (
                  <>
                    <RefreshIcon className="loader h-5 w-5 text-white" />{' '}
                    Loading
                  </>
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Content */}
      <div className="mt-10 flex flex-col">
        <div className="grid grid-cols-2 gap-6 px-8 xs:gap-4 xs:px-0">
          {/* Area of focus */}
          <div className="col-span-2">
            <p className="text-base font-semibold xs:text-sm">
              Area
              {(uniquePatientCarePlans?.length || 0) +
                (newAreasOfFocusAdded?.length || 0) >
              1
                ? 's'
                : ''}{' '}
              of Focus
            </p>
            <div className="flex flex-wrap gap-2 sm:mt-4 xs:mt-1">
              {uniquePatientCarePlans?.length ||
              newAreasOfFocusAdded?.length ? (
                <>
                  {React.Children.toArray(
                    newAreasOfFocusAdded.map((sl: ServiceLine) => (
                      <div className="rounded-lg border border-components-fields sm:py-2 sm:px-4 xs:py-1.5 xs:px-2">
                        <p className="text-xs font-semibold text-text-secondary sm:text-sm">
                          {sl.displayName}
                        </p>
                      </div>
                    ))
                  )}
                  {React.Children.toArray(
                    uniquePatientCarePlans.map((obj: CarePlan) => (
                      <div className="rounded-lg border border-components-fields sm:py-2 sm:px-4 xs:py-1.5 xs:px-2">
                        <p className="text-xs font-semibold text-text-secondary sm:text-sm">
                          {obj.displayName}
                        </p>
                      </div>
                    ))
                  )}
                </>
              ) : (
                !editMode && (
                  <div className="flex items-center justify-center">-</div>
                )
              )}
              {editMode &&
              user.data.clientData?.clientType !== 'EDUCATION' &&
              (isLoadingClientAvailableSLs || isFetchingProviders) ? (
                <div className="flex items-center justify-center">
                  <RefreshIcon className="loader h-4 w-4" aria-hidden="true" />
                </div>
              ) : (
                editMode &&
                (patientCarePlans?.length === 0 &&
                newAreasOfFocusAdded?.length < clientAvailableSLs?.length ? (
                  <button
                    type="button"
                    onClick={addAreaOfFocusFirstTime}
                    className="flex flex-row items-center justify-center gap-1 rounded-lg border border-dotted border-cta-default bg-components-paleBlue sm:py-2 sm:px-4 xs:py-1.5 xs:px-2"
                  >
                    <PlusIcon className="h-4 w-4 text-cta-default" />
                    <p className="text-xs font-semibold text-cta-default sm:text-sm">
                      Add area of focus
                    </p>
                  </button>
                ) : (
                  user.data.clientData?.clientType !== 'EDUCATION' &&
                  patientCarePlans?.length !== clientAvailableSLs?.length && (
                    <>
                      {[...patientCarePlans, ...newAreasOfFocusAdded]
                        ?.length !== clientAvailableSLs?.length && (
                        <button
                          onClick={() => setIsOpenAddAreasOfFocusModal(true)}
                          type="button"
                          className="flex flex-row items-center justify-center gap-1 rounded-lg border border-dotted border-cta-default bg-components-paleBlue sm:py-2 sm:px-4 xs:py-1.5 xs:px-2"
                        >
                          <PlusIcon className="h-4 w-4 text-cta-default" />
                          <p className="text-xs font-semibold text-cta-default sm:text-sm">
                            Add more
                          </p>
                        </button>
                      )}
                      <AddAreasOfFocusModal
                        open={isOpenAddAreasOfFocusModal}
                        setOpen={setIsOpenAddAreasOfFocusModal}
                        onFinish={(newServiceLines: ServiceLine[]) => {
                          setNewAreasOfFocusAdded(newServiceLines)
                          setIsOpenAddAreasOfFocusModal(false)
                        }}
                        onCancel={() => setIsOpenAddAreasOfFocusModal(false)}
                        options={clientAvailableSLs.filter(
                          (sl: ServiceLine) =>
                            !uniquePatientCarePlans.find(
                              (cp: CarePlan) =>
                                cp.displayName === sl.displayName
                            )
                        )}
                      />
                    </>
                  )
                ))
              )}
            </div>
          </div>

          {/* First name */}
          <div className="xs:col-span-2">
            <p className="text-base font-semibold xs:text-sm">First name</p>
            {!editMode ? (
              <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                {patient.firstName}
              </p>
            ) : (
              <>
                <input
                  type="text"
                  className={`${
                    errors.firstName ? inputErrorClass : inputValidClass
                  }`}
                  placeholder="First name"
                  disabled
                  {...register('firstName')}
                />
                {errors.firstName && (
                  <p className="mt-1 text-sm text-status-error">
                    {errors.firstName.message}
                  </p>
                )}
              </>
            )}
          </div>

          {/* Last name */}
          <div className="xs:col-span-2">
            <p className="text-base font-semibold xs:text-sm">Last name</p>
            {!editMode ? (
              <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                {patient.lastName}
              </p>
            ) : (
              <>
                <input
                  type="text"
                  className={`${
                    errors.lastName ? inputErrorClass : inputValidClass
                  }`}
                  placeholder="Last name"
                  disabled
                  {...register('lastName')}
                />
                {errors.lastName && (
                  <p className="mt-1 text-sm text-status-error">
                    {errors.lastName.message}
                  </p>
                )}
              </>
            )}
          </div>

          {/* Preferred name */}
          {restrictedIfSeparateLogin && (
            <div className="xs:col-span-2">
              <p className="text-base font-semibold xs:text-sm">
                Preferred name {editMode && '(optional)'}
              </p>
              {!editMode ? (
                <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                  {patient.preferredName || '-'}
                </p>
              ) : (
                <input
                  type="text"
                  className={`${
                    errors.preferredName ? inputErrorClass : inputValidClass
                  }`}
                  placeholder="Preferred name"
                  {...register('preferredName')}
                />
              )}
            </div>
          )}

          {/* Birthdate */}
          <div className="xs:col-span-2">
            <p className="text-base font-semibold xs:text-sm">Date of birth</p>
            {!editMode ? (
              <div className="flex items-center gap-2 py-3.5 px-4 text-base font-normal xs:text-sm">
                <CalendarIcon className="h-5 w-5 text-text-label" />{' '}
                <span>{patient.birthDate}</span>
              </div>
            ) : (
              <DatePicker
                setValue={setValue}
                control={control}
                error={errors.birthDate}
                fieldKey="birthDate"
                isDisabled
              />
            )}
          </div>

          {/* Student ID or Work email */}
          {user.data.clientData?.clientType === 'EDUCATION' ? (
            <div className="xs:col-span-2">
              <p className="text-base font-semibold xs:text-sm">Student ID</p>
              {!editMode ? (
                <p className="break-words py-3.5 px-4 text-base font-normal xs:text-sm">
                  {patient.studentId || '-'}
                </p>
              ) : (
                <input
                  type="text"
                  className={`${
                    errors.studentId ? inputErrorClass : inputValidClass
                  }`}
                  placeholder="12345"
                  {...register('studentId')}
                  disabled
                />
              )}
            </div>
          ) : (
            user.data.clientData?.clientType === 'EMPLOYER' &&
            user.data.clientData.requireEligibility &&
            patient?.relationship?.name === 'Myself' && (
              <div className="col-span-2">
                <p className="text-base font-semibold xs:text-sm">Work email</p>
                {!editMode ? (
                  <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                    {patient.email || user.data.email || '-'}
                  </p>
                ) : (
                  <input
                    type="text"
                    className={`${
                      errors.email ? inputErrorClass : inputValidClass
                    }`}
                    placeholder="Email"
                    {...register('email')}
                    disabled
                  />
                )}
              </div>
            )
          )}

          {/* Gender */}
          <div className="xs:col-span-2">
            <p className="text-base font-semibold xs:text-sm">Sex at birth</p>
            {!editMode ? (
              <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                {capitalize(patient?.gender || '') || '-'}
              </p>
            ) : (
              <Listbox {...fieldGender}>
                {({ open }) => (
                  <div className="relative">
                    <Listbox.Button
                      onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                        const match = genderOptions.find((g: string) =>
                          g.toLowerCase().startsWith(e.key.toLowerCase())
                        )
                        if (match) {
                          setValue('gender', match, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          })
                        }
                      }}
                      className={`${
                        errors.gender ? comboBoxErrorClass : comboBoxClass
                      }`}
                    >
                      <span className="block truncate">
                        {capitalize(fieldGender?.value || '') || 'Select'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {genderOptions.map((gender: string) => (
                          <Listbox.Option
                            key={gender}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                active && 'bg-components-fillBorders'
                              )
                            }
                            value={gender}
                          >
                            <span className={'block truncate'}>{gender}</span>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            )}
          </div>

          {/* Gender identity */}
          {restrictedIfSeparateLogin && (
            <div className="xs:col-span-2">
              <p className="text-base font-semibold xs:text-sm">
                Gender identity
              </p>
              {!editMode ? (
                <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                  {patient?.genderIdentity?.displayName || '-'}
                </p>
              ) : (
                <Listbox {...fieldGenderIdentity}>
                  {({ open }) => (
                    <div className="relative">
                      <Listbox.Button
                        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                          const match = genderIdentities.find(
                            (g: GenderIdentity) =>
                              g.displayName
                                .toLowerCase()
                                .startsWith(e.key.toLowerCase())
                          )
                          if (match) {
                            setValue('genderIdentity', match, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            })
                          }
                        }}
                        className={`${
                          errors.genderIdentity
                            ? comboBoxErrorClass
                            : comboBoxClass
                        }`}
                      >
                        <span className="block truncate">
                          {fieldGenderIdentity?.value?.displayName || 'Select'}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {genderIdentities.map((gender: GenderIdentity) => (
                            <Listbox.Option
                              key={gender?.key}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                  active && 'bg-components-fillBorders'
                                )
                              }
                              value={gender}
                            >
                              <span className={'block truncate'}>
                                {gender.displayName}
                              </span>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  )}
                </Listbox>
              )}
            </div>
          )}

          {/* Pronouns */}
          {restrictedIfSeparateLogin && (
            <div className="xs:col-span-2">
              <p className="text-base font-semibold xs:text-sm">
                Pronouns {editMode && '(optional)'}
              </p>
              {!editMode ? (
                <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                  {patient.preferredPronouns || '-'}
                </p>
              ) : (
                <Listbox {...fieldPreferredPronouns}>
                  {({ open }) => (
                    <div className="relative">
                      <Listbox.Button
                        onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                          const match = preferredPronounsArray.find(
                            (p: string) =>
                              p.toLowerCase().startsWith(e.key.toLowerCase())
                          )
                          if (match) {
                            setValue('preferredPronouns', match, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            })
                          }
                        }}
                        className={`${
                          errors.preferredPronouns
                            ? comboBoxErrorClass
                            : comboBoxClass
                        }`}
                      >
                        <span
                          className={`${
                            fieldPreferredPronouns?.value
                              ? ''
                              : 'text-text-placeholder'
                          } block truncate`}
                        >
                          {fieldPreferredPronouns?.value || 'Select'}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {preferredPronounsArray.map((pronoun: string) => (
                            <Listbox.Option
                              key={pronoun}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                  active && 'bg-components-fillBorders'
                                )
                              }
                              value={pronoun}
                            >
                              <span className={'block truncate'}>
                                {pronoun || '-'}
                              </span>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  )}
                </Listbox>
              )}
            </div>
          )}

          {/* Preferred language */}
          <div className="xs:col-span-2">
            <p className="text-base font-semibold xs:text-sm">
              Preferred language
            </p>
            {!editMode ? (
              <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                {patient.preferredLanguage || '-'}
              </p>
            ) : (
              <Listbox {...fieldPreferredLanguage}>
                {({ open }) => (
                  <div className="relative">
                    <Listbox.Button
                      onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                        const match = languages.find((l: string) =>
                          l.toLowerCase().startsWith(e.key.toLowerCase())
                        )
                        if (match) {
                          setValue('preferredLanguage', match, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          })
                        }
                      }}
                      className={`${
                        errors.preferredLanguage
                          ? comboBoxErrorClass
                          : comboBoxClass
                      }`}
                    >
                      <span className="block truncate">
                        {fieldPreferredLanguage?.value || 'Select'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {languages.map((language: string) => (
                          <Listbox.Option
                            key={language}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                active && 'bg-components-fillBorders'
                              )
                            }
                            value={language}
                          >
                            <span className={'block truncate'}>{language}</span>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            )}
          </div>

          {/* Race */}
          <div className="xs:col-span-2">
            <p className="text-base font-semibold xs:text-sm">
              Race & Ethnicity {editMode && '(optional)'}
            </p>
            {!editMode ? (
              <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                {patient?.race?.displayName || '-'}
              </p>
            ) : (
              <Listbox {...fieldRace}>
                {({ open }) => (
                  <div className="relative">
                    <Listbox.Button
                      onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                        const match = raceOptions.find((r: Race) =>
                          r.displayName
                            .toLowerCase()
                            .startsWith(e.key.toLowerCase())
                        )
                        if (match) {
                          setValue('race', match, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          })
                        }
                      }}
                      className={`${
                        errors.race ? comboBoxErrorClass : comboBoxClass
                      }`}
                    >
                      <span
                        className={`${
                          fieldRace?.value?.displayName
                            ? ''
                            : 'text-text-placeholder'
                        } block truncate`}
                      >
                        {fieldRace?.value?.displayName || 'Select'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {raceOptions.map((race: Race) => (
                          <Listbox.Option
                            key={race.key}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                active && 'bg-components-fillBorders'
                              )
                            }
                            value={race}
                          >
                            <span className="block">
                              {race.displayName || '-'}
                            </span>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            )}
          </div>

          {/* Your relationship to this person */}
          <div className="xs:col-span-2">
            <p className="text-base font-semibold xs:text-sm">
              Your relationship to this person
            </p>
            {!editMode ? (
              <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                {capitalize(patient?.relationship?.name || '') || '-'}
              </p>
            ) : (
              <Listbox {...fieldRelationship} disabled={forSelf}>
                {({ open }) => (
                  <div className="relative">
                    <Listbox.Button
                      onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                        if (forSelf) return

                        const match = relationshipOptionsProfile
                          .slice(1, relationshipOptionsProfile?.length)
                          .find((r: RelationshipType) =>
                            r.name.toLowerCase().startsWith(e.key.toLowerCase())
                          )
                        if (match) {
                          setValue('relationship', match, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          })
                        }
                      }}
                      className={`${
                        errors.relationship ? comboBoxErrorClass : comboBoxClass
                      }`}
                    >
                      <span className="block truncate">
                        {capitalize(fieldRelationship?.value?.name || '') ||
                          'Select'}
                      </span>
                      {!forSelf && (
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {relationshipOptionsProfile
                          .slice(1, relationshipOptionsProfile?.length)
                          .map((relationship: RelationshipType) => (
                            <Listbox.Option
                              key={relationship?.key}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                  active && 'bg-components-fillBorders'
                                )
                              }
                              value={relationship}
                            >
                              <span className={'block truncate'}>
                                {relationship.name}
                              </span>
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            )}
          </div>

          {/* Time zone */}
          <div className="xs:col-span-2">
            <p className="text-base font-semibold xs:text-sm">Time zone</p>
            {!editMode ? (
              <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                {frontendToBackendTz(patient.timeZone) || '-'}
              </p>
            ) : (
              <Listbox {...fieldTimeZone}>
                {({ open }) => (
                  <div className="relative">
                    <Listbox.Button
                      onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                        const match = Object.entries(getTimeZones()).find(
                          ([key]) =>
                            key.toLowerCase().startsWith(e.key.toLowerCase())
                        )
                        if (match) {
                          setValue('timeZone', match[1], {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          })
                        }
                      }}
                      className={`${
                        errors.timeZone ? comboBoxErrorClass : comboBoxClass
                      }`}
                    >
                      <span className="block truncate">
                        {frontendToBackendTz(fieldTimeZone?.value) || 'Select'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {Object.values(getTimeZones()).map(
                          (timeZoneValue: string) => (
                            <Listbox.Option
                              key={timeZoneValue}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                                  active && 'bg-components-fillBorders'
                                )
                              }
                              value={timeZoneValue}
                            >
                              <span className={'block truncate'}>
                                {frontendToBackendTz(timeZoneValue)}
                              </span>
                            </Listbox.Option>
                          )
                        )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            )}
          </div>

          {/* Physical & billing address */}
          {!editMode ? (
            <>
              {/* Physical address */}
              <div className="col-span-2">
                <p className="text-base font-semibold xs:text-sm">
                  Physical address
                </p>
                <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                  {[
                    patient.streetAddress,
                    patient.apartmentUnit,
                    patient.city,
                    patient.state,
                    patient.zip,
                  ]
                    .filter((obj: string) => Boolean(obj?.length))
                    .join(', ')}
                </p>
              </div>

              {/* Billing address */}
              <div className="col-span-2">
                <p className="text-base font-semibold xs:text-sm">
                  Billing address
                </p>
                <p className="py-3.5 px-4 text-base font-normal xs:text-sm">
                  {[
                    patient.billingStreetAddress,
                    patient.billingApartmentUnit,
                    patient.billingCity,
                    patient.billingState,
                    patient.billingZip,
                  ]
                    .filter((obj: string) => Boolean(obj?.length))
                    .join(', ') || '-'}
                </p>
              </div>
            </>
          ) : (
            <>
              {/* Address Divider */}
              <div className="relative col-span-2 mt-8">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-center text-base font-semibold text-text-secondary xs:text-sm">
                    Physical Address
                  </span>
                </div>
              </div>

              {/* Street Address */}
              <div className="col-span-2">
                <p className="text-base font-semibold xs:text-sm">
                  Street address
                </p>
                <input
                  type="text"
                  className={`${
                    errors.streetAddress ? inputErrorClass : inputValidClass
                  }`}
                  placeholder="Street address"
                  {...register('streetAddress')}
                />
                {Boolean(errors.streetAddress) && (
                  <p className="mt-1 text-sm text-status-error">
                    {errors.streetAddress.message}
                  </p>
                )}
              </div>

              {/* Apt/Unit */}
              <div className="col-span-2">
                <p className="text-base font-semibold xs:text-sm">
                  Apt/Unit{' '}
                  <span className="text-xs font-light">(optional)</span>
                </p>
                <input
                  type="text"
                  className={`${
                    errors.apartmentUnit ? inputErrorClass : inputValidClass
                  }`}
                  placeholder="Apartment unit"
                  {...register('apartmentUnit')}
                />
              </div>

              {/* City, State, Zip code */}
              <div className="col-span-2 flex gap-6 xs:flex-col xs:gap-4">
                {/* City */}
                <div className="w-1/3 xs:w-full">
                  <p className="text-base font-semibold xs:text-sm">City</p>
                  <input
                    type="text"
                    className={`${
                      errors.city ? inputErrorClass : inputValidClass
                    }`}
                    placeholder="City"
                    {...register('city')}
                  />
                  {Boolean(errors.city) && (
                    <p className="mt-1 text-sm text-status-error">
                      {errors.city.message}
                    </p>
                  )}
                </div>

                {/* State */}
                <div className="w-1/3 xs:w-full">
                  <p className="text-base font-semibold xs:text-sm">State</p>
                  <Listbox {...fieldState}>
                    {({ open }) => (
                      <div className="relative">
                        <Listbox.Button
                          onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                            const match = states.find((s: State) =>
                              s.name
                                .toLowerCase()
                                .startsWith(e.key.toLowerCase())
                            )
                            if (match) {
                              setValue('state', match.abbrev, {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true,
                              })
                            }
                          }}
                          className={`${
                            errors.state ? comboBoxErrorClass : comboBoxClass
                          }`}
                        >
                          <span
                            className={`${
                              fieldState?.value ? '' : 'text-text-placeholder'
                            } block truncate`}
                          >
                            {states?.find(
                              (s: State) =>
                                s.abbrev === fieldState?.value ||
                                s.name === fieldState?.value
                            )?.name || 'Select'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {states.map((state: State) => (
                              <Listbox.Option
                                key={state?.abbrev}
                                className={({ active }) =>
                                  classNames(
                                    'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                    active && 'bg-components-fillBorders'
                                  )
                                }
                                value={state?.abbrev}
                              >
                                <span className={'block truncate'}>
                                  {state.name}
                                </span>
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    )}
                  </Listbox>
                </div>

                {/* Zip */}
                <div className="w-1/3 xs:w-full">
                  <p className="text-base font-semibold xs:text-sm">Zip</p>
                  <input
                    type="text"
                    className={`${
                      errors.zip ? inputErrorClass : inputValidClass
                    }`}
                    placeholder="Zip"
                    {...register('zip')}
                  />
                  {Boolean(errors.zip) && (
                    <p className="mt-1 text-sm text-status-error">
                      {errors.zip.message}
                    </p>
                  )}
                </div>
              </div>

              {/* Billing Divider */}
              <div className="relative col-span-2 mt-8">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-center text-base font-semibold text-text-secondary xs:text-sm">
                    Billing Address
                  </span>
                </div>
              </div>

              {/* Street Address */}
              <div className="col-span-2">
                <p className="text-base font-semibold xs:text-sm">
                  Street address
                </p>
                <input
                  type="text"
                  className={`${
                    errors.billingStreetAddress
                      ? inputErrorClass
                      : inputValidClass
                  }`}
                  placeholder="Street address"
                  {...register('billingStreetAddress')}
                />
              </div>

              {/* Apt/Unit */}
              <div className="col-span-2">
                <p className="text-base font-semibold xs:text-sm">
                  Apt/Unit{' '}
                  <span className="text-xs font-light">(optional)</span>
                </p>
                <input
                  type="text"
                  className={`${
                    errors.billingApartmentUnit
                      ? inputErrorClass
                      : inputValidClass
                  }`}
                  placeholder="Apartment unit"
                  {...register('billingApartmentUnit')}
                />
              </div>

              {/* City, State, Zip code */}
              <div className="col-span-2 flex gap-6 xs:flex-col xs:gap-4">
                {/* City */}
                <div className="w-1/3 xs:w-full">
                  <p className="text-base font-semibold xs:text-sm">City</p>
                  <input
                    type="text"
                    className={`${
                      errors.billingCity ? inputErrorClass : inputValidClass
                    }`}
                    placeholder="City"
                    {...register('billingCity')}
                  />
                </div>

                {/* State */}
                <div className="w-1/3 xs:w-full">
                  <p className="text-base font-semibold xs:text-sm">State</p>
                  <Listbox {...fieldStateBilling}>
                    {({ open }) => (
                      <div className="relative">
                        <Listbox.Button
                          onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                            const match = states.find((s: State) =>
                              s.name
                                .toLowerCase()
                                .startsWith(e.key.toLowerCase())
                            )
                            if (match) {
                              setValue('billingState', match.abbrev, {
                                shouldDirty: true,
                                shouldTouch: true,
                                shouldValidate: true,
                              })
                            }
                          }}
                          className={`${
                            errors.billingState
                              ? comboBoxErrorClass
                              : comboBoxClass
                          }`}
                        >
                          <span
                            className={`${
                              fieldStateBilling?.value
                                ? ''
                                : 'text-text-placeholder'
                            } block truncate`}
                          >
                            {states?.find(
                              (s: State) =>
                                s.abbrev === fieldStateBilling?.value ||
                                s.name === fieldStateBilling?.value
                            )?.name || 'Select'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {states.map((state: State) => (
                              <Listbox.Option
                                key={state?.abbrev}
                                className={({ active }) =>
                                  classNames(
                                    'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                                    active && 'bg-components-fillBorders'
                                  )
                                }
                                value={state.abbrev}
                              >
                                <span className={'block truncate'}>
                                  {state.name}
                                </span>
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    )}
                  </Listbox>
                </div>

                {/* Zip */}
                <div className="w-1/3 xs:w-full">
                  <p className="text-base font-semibold xs:text-sm">Zip</p>
                  <input
                    type="text"
                    className={`${
                      errors.billingZip ? inputErrorClass : inputValidClass
                    }`}
                    placeholder="Zip"
                    {...register('billingZip')}
                  />
                </div>
              </div>
            </>
          )}

          {/* Emergency contact */}
          {editMode ? (
            <>
              {/* Emergency Contact Divider */}
              <div className="relative col-span-2 mt-8">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-center text-base font-semibold text-text-secondary xs:text-sm">
                    Emergency Contact
                  </span>
                </div>
              </div>

              {/* First name */}
              <Controller
                control={control}
                name="emergencyContact.firstName"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
                    <label
                      htmlFor="emergencyContact.firstName"
                      className="text-sm font-semibold sm:text-base"
                    >
                      First name
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={`${
                          error ? inputErrorClass : inputValidClass
                        } font-normal`}
                        placeholder="First"
                        {...field}
                      />
                      {Boolean(error) && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(error) && (
                      <p className="text-sm text-status-error">
                        {error.message}
                      </p>
                    )}
                  </div>
                )}
              />

              {/* Last name */}
              <Controller
                control={control}
                name="emergencyContact.lastName"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
                    <label
                      htmlFor="emergencyContact.lastName"
                      className="text-sm font-semibold sm:text-base"
                    >
                      Last name
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={`${
                          error ? inputErrorClass : inputValidClass
                        } font-normal`}
                        placeholder="Last"
                        {...field}
                      />
                      {Boolean(error) && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(error) && (
                      <p className="text-sm text-status-error">
                        {error.message}
                      </p>
                    )}
                  </div>
                )}
              />

              {/* Relationship */}
              <Controller
                control={control}
                name="emergencyContact.relationship"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
                    <Listbox
                      {...field}
                      disabled={Boolean(
                        patient?.emergencyContact?.relationship
                      )}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="font-semibold">
                            {`Their relationship to ${
                              forSelf ? 'you' : `${patient.firstName}`
                            }`}
                          </Listbox.Label>
                          <div className="relative">
                            <Listbox.Button
                              onKeyDown={(
                                e: KeyboardEvent<HTMLButtonElement>
                              ) => {
                                const match = emergencyContactRelationships
                                  .slice(
                                    1,
                                    emergencyContactRelationships?.length
                                  )
                                  .find((r: RelationshipType) =>
                                    r.name
                                      .toLowerCase()
                                      .startsWith(e.key.toLowerCase())
                                  )
                                if (match) {
                                  setValue(
                                    'emergencyContact.relationship',
                                    match.key,
                                    {
                                      shouldDirty: true,
                                      shouldTouch: true,
                                      shouldValidate: true,
                                    }
                                  )
                                }
                              }}
                              className={
                                error ? comboBoxErrorClass : comboBoxClass
                              }
                            >
                              <span
                                className={`${
                                  !patient?.emergencyContact?.relationship &&
                                  !field.value
                                    ? 'text-text-placeholder'
                                    : ''
                                } block truncate`}
                              >
                                {(field.value &&
                                  emergencyContactRelationships.find(
                                    (r: RelationshipType) =>
                                      r.key === field.value
                                  )?.name) ||
                                  'Select'}
                              </span>
                              {!patient?.emergencyContact?.relationship && (
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                                  {error ? (
                                    <ExclamationCircleIcon
                                      className="h-5 w-5 text-status-error"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ChevronDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              )}
                            </Listbox.Button>
                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-left text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {emergencyContactRelationships
                                  .slice(
                                    1,
                                    emergencyContactRelationships?.length
                                  )
                                  .map((r: RelationshipType) => (
                                    <Listbox.Option
                                      key={r.key}
                                      className={({ active }) =>
                                        classNames(
                                          'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                                          active && 'bg-components-fillBorders'
                                        )
                                      }
                                      value={r.key}
                                    >
                                      <span>{r.name}</span>
                                    </Listbox.Option>
                                  ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                          {Boolean(error) && (
                            <p className="text-sm text-status-error">
                              {error.type === 'required'
                                ? error.message.replace(
                                    'this person',
                                    `${
                                      forSelf ? 'you' : `${patient.firstName}`
                                    }`
                                  )
                                : error.message}
                            </p>
                          )}
                        </>
                      )}
                    </Listbox>
                  </div>
                )}
              />

              {/* Phone number */}
              <Controller
                control={control}
                name="emergencyContact.phoneNumber"
                defaultValue=""
                rules={{ maxLength: 14 }}
                render={({ field, fieldState: { error } }) => (
                  <div className="col-span-2 flex flex-col gap-1 sm:col-span-1">
                    <label
                      htmlFor="emergencyContact.phoneNumber"
                      className="text-sm font-semibold sm:text-base"
                    >
                      Phone number
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={`${
                          error && error.type !== 'validPhoneNumber'
                            ? inputErrorClass
                            : inputValidClass
                        } font-normal`}
                        placeholder="(000) 000-0000"
                        {...field}
                        onChange={(e) => {
                          field.onChange(formatPhoneNumber(e.target.value))
                        }}
                        maxLength={14}
                      />
                      {Boolean(error && error.type !== 'validPhoneNumber') && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-status-error"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {Boolean(error) && (
                      <p
                        className={`text-sm ${
                          error && error.type !== 'validPhoneNumber'
                            ? 'text-status-error'
                            : ''
                        }`}
                      >
                        {error.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </>
          ) : (
            Boolean(patient.emergencyContact) && (
              <div className="col-span-2 flex flex-col gap-0 sm:gap-1">
                <p className="text-sm font-semibold sm:text-base">
                  Emergency Contact
                </p>
                <div className="grid grid-cols-3 gap-2 py-3.5 px-4 sm:gap-2.5">
                  <p className="text-sm sm:text-base">{`${patient.emergencyContact.firstName} ${patient.emergencyContact.lastName}`}</p>
                  <p className="text-sm sm:text-base">
                    {
                      emergencyContactRelationships.find(
                        (r: RelationshipType) =>
                          r.key === patient.emergencyContact.relationship
                      )?.name
                    }
                  </p>
                  <p className="text-sm sm:text-base">
                    {patient.emergencyContact.phoneNumber}
                  </p>
                </div>
              </div>
            )
          )}

          {/* Who has access */}
          {!editMode && !isLoadingAccessors && (
            <div className="col-span-2 flex flex-col gap-0 sm:gap-1">
              <p className="text-sm font-semibold sm:text-base">
                Who has access to this profile
              </p>
              <div className="flex flex-wrap gap-2 py-3.5 px-4 sm:gap-2.5">
                {React.Children.toArray(
                  usersWithAccess.map(
                    (userWithAccess: { name: string; id: string }) => (
                      <Tooltip
                        isDisabled={user.data.id === userWithAccess.id}
                        position="right"
                        content={
                          <p>
                            If this person is not supposed to have access to
                            this profile, please contact us at{' '}
                            <a
                              href="mailto:support@huddleupcare.com"
                              className="underline"
                              onClick={() =>
                                handleClickSupportEmail(user, location)
                              }
                            >
                              support@huddleupcare.com
                            </a>
                            .
                          </p>
                        }
                      >
                        <div className="rounded-lg border border-components-fields sm:py-2 sm:px-4 xs:py-1.5 xs:px-2">
                          <div className="flex items-center gap-1">
                            <p className="text-xs font-semibold text-text-secondary sm:text-sm">
                              {userWithAccess.name}
                            </p>
                            {user.data.id !== userWithAccess.id && (
                              <img src={INFO} alt="info" className="h-4 w-4" />
                            )}
                          </div>
                        </div>
                      </Tooltip>
                    )
                  )
                )}
              </div>
            </div>
          )}

          {/* Edit */}
          {!patient.isDisabled && (
            <div className="col-span-2 flex w-full gap-2 sm:hidden">
              <button
                className={`${tertiaryButtonClassSmall} ${
                  editMode ? 'hidden' : ''
                } w-full`}
                type="button"
                onClick={(e) => {
                  setEditMode(true)
                  e.currentTarget.blur()
                }}
              >
                <PencilIcon className="h-5 w-5" /> Edit
              </button>
              <button
                disabled={isLoadingUpdateProfile}
                className={`${tertiaryButtonClassSmall} ${
                  !editMode ? 'hidden' : ''
                } w-full`}
                onClick={(e) => {
                  setEditMode(false)
                  reset()
                  e.currentTarget.blur()
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                disabled={isLoadingUpdateProfile}
                className={`${primaryButtonClassSmall} ${
                  !editMode ? 'hidden' : ''
                } w-full`}
                onClick={(e) => onClickSaveButton(e)}
                type="submit"
              >
                {isLoadingUpdateProfile ? (
                  <>
                    <RefreshIcon className="loader h-5 w-5 text-white" />{' '}
                    Loading
                  </>
                ) : (
                  'Save'
                )}
              </button>
            </div>
          )}
        </div>
      </div>

      <BookNewAreaOfFocusSession
        newServiceLines={serviceLineOptions.filter((sl: ServiceLine) =>
          uniquePatientCarePlans.find(
            (cp: CarePlan) => cp.displayName === sl.displayName
          )
        )}
        open={isOpenBookNewAreaOfFocusSession}
        setOpen={setIsOpenBookNewAreaOfFocusSession}
      />

      <PatientChangedStateModal
        open={isOpenChangedStateModal}
        setOpen={setIsOpenChangedStateModal}
        onConfirm={handleSubmit((data: Patient) => onSubmit(data))}
        cancelledTherapists={cancelledTherapists}
      />
    </form>
  )
}

export default ProfileTab
