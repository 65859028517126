import { useQuery } from '@tanstack/react-query'

import { toCamelCase } from '../../helpers/serializers'
import type { Therapist } from '../../types/Therapist'
import { toTherapist } from '../../helpers/transformers'
import type { Patient } from '../../types/Patient'
import { fetchProviders } from './UseGetProviders'

export interface GetProvidersProps {
  patient: Partial<Patient>
  serviceType: string
  language?: string
  licenseJurisdiction?: string
  ignoreTakingNewPrivatePatients?: boolean
  sortBy?: string
  withFirstAvailability?: boolean
  durationInMinutes?: number
}

export const useGetProviders = (params: GetProvidersProps) =>
  useQuery(
    [
      'getProviders',
      {
        ...params,
      },
    ],
    async () => getProviders(params)
  )

export const buildQueryParamsForProviders = (params: GetProvidersProps) =>
  [
    'first_availability=1',
    params.ignoreTakingNewPrivatePatients
      ? ''
      : 'taking_new_private_patients=1',
    params.serviceType ? `service_type=${params.serviceType}` : '',
    params.language ? `language=${params.language}` : '',
    params.licenseJurisdiction
      ? `license_jurisdiction=US-${params.licenseJurisdiction}`
      : '',
    params.sortBy ? `sort_by=${params.sortBy}` : '',
    `duration_in_minutes=${params.durationInMinutes || 30}`,
  ].filter((qp) => qp)

export const getProviders = async ({
  withFirstAvailability = true,
  ...params
}: GetProvidersProps): Promise<Therapist[]> => {
  try {
    const queryParams = buildQueryParamsForProviders(params)

    const providers: any = await fetchProviders(queryParams.join('&'))

    if (providers?.error) return []

    const resultProviders: Therapist[] = providers?.map((provider: any) =>
      toTherapist(toCamelCase(provider))
    )

    return withFirstAvailability
      ? resultProviders.filter((p: Therapist) => Boolean(p.firstAvailability))
      : resultProviders
  } catch (error) {
    return []
  }
}
