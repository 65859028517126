import { signOut } from 'firebase/auth'
import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { Product, User } from '../../types/User'
import { toPatient, toProduct, toUserData } from '../../helpers/transformers'
import { toCamelCase } from '../../helpers/serializers'
import getVanityUrl from '../../helpers/getVanityUrl'
import type { Patient } from '../../types/Patient'
import { getEligibilityForAccountHolder } from '../eligibility/VerifyUserEligibility'
import { parseJwt } from '../../helpers/utils'
import { getPaymentMethod } from '../../helpers/externalCalls'

const getUserData = async (): Promise<User> => {
  const token: string = await auth.currentUser.getIdToken(true)

  // get backend timezones and save them into localstorage for easy global access
  const timeZonesFromBE = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/time_zones`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  localStorage.setItem('DOTCOM_TIMEZONES', JSON.stringify(timeZonesFromBE))

  // get current user id by firebase token
  const id: string = parseJwt(token).dct_user_id

  // get user data by DOT user_id
  let userData: any = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/users/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  // get client data by client_id
  const clientData: any = await fetch(
    `${import.meta.env.VITE_DOTCOM_JAVA_CLIENTS_URL}v1/clients/${
      userData.client_id
    }/customer`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  // check if employer account holder is eligibile
  let isEligible = null
  if (clientData.clientType === 'EMPLOYER' && clientData.requireEligibilityFile)
    isEligible = await getEligibilityForAccountHolder()

  userData = toUserData(
    toCamelCase({
      ...userData,
      email: userData.email,
      clientId: userData.client_id,
      personId: userData.person_id,
      isEligible,
      clientData: {
        clientName: clientData?.name,
        clientType: clientData?.clientType,
        vanityUrl: clientData?.vanityUrl,
        clientSchoolStart: clientData?.startTime,
        clientSchoolEnd: clientData?.endTime,
        requireEligibility: clientData?.requireEligibilityFile,
      },
    })
  )

  // redirect to vanity url if in the wrong place
  const currentVanityUrl = getVanityUrl()
  const userVanityUrl = userData.clientData.vanityUrl

  if (userVanityUrl !== currentVanityUrl) {
    signOut(auth)

    window.location.href = `${window.location.protocol}//${
      window.location.hostname.includes('localhost')
        ? userVanityUrl?.replace('huddleupcare.com', 'localhost:5173')
        : userVanityUrl
    }/sign-in?email=${encodeURIComponent(userData.email)}`
  }

  // get patients by user_id
  let roster: any = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/users/${id}/patients`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  // get client products to see if 'Allowed, patient pays' is checked or not for continuation of services
  const products: any = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_CLIENTS_URL
    }v1/products/for-customer?clientId=${userData.clientId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  const userProducts: Product[] = products
    .map((p: any) => toProduct(p))
    .filter((p: Product) => p)
    .filter((p: Product) => {
      if (clientData.clientType === 'EDUCATION' && !p.isIep)
        return p.serviceLine.serviceId === 4
      return true
    })

  roster = toCamelCase(roster || [])
  const paymentMethod = await getPaymentMethod()

  for (let i = 0; i < roster?.length; i++) {
    const element = roster[i]
    roster[i] = await toPatient(element, {
      data: userData,
      products: userProducts,
      paymentMethod,
    })
  }

  if (userData?.messages?.length) throw new Error('Something went wrong.')

  return {
    data: userData,
    roster: roster.filter((p: Patient) => p),
    products: userProducts,
    paymentMethod,
  }
}

export const useGetUserData = () => useMutation(() => getUserData())
