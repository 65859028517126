import {
  SERVICE_LINES_ARRAY,
  THERAPIST_NETWORK_INFORMATIONS,
} from '../constants/serviceLine'
import {
  genderOptions,
  insurancePatientRelationships,
  raceOptions,
  resourceFormatOptions,
  resourceStatusOptions,
  states,
} from '../constants/values'
import type { CarePlan, Condition, Patient } from '../types/Patient'
import type { ServiceLine } from '../types/ServiceLine'
import type { Insurance, InsuranceRelationship } from '../types/Insurance'
import type { Product, User, UserInfo } from '../types/User'
import {
  capitalize,
  convertBackendDateToFrontend,
  fallbackTimeZone,
  getAgeFromBirthdate,
  getGenderIdentity,
} from './generic'
import type { Therapist } from '../types/Therapist'
import {
  mapSessionName,
  mapSessionStatus,
  type Session,
} from '../types/Session'
import type { State } from '../types/State'
import type {
  PreviewResource,
  Resource,
  ResourceFormat,
  ResourceTopic,
} from '../types/Resources'
import type { InterestOption } from '../screens/onboarding/WhatBringsYouHere'
import { options } from '../screens/onboarding/WhatBringsYouHere'
import type { Race } from '../types/Profile'
import type { Document } from '../queries/dashboard/GetDocuments'
import type { DisplayDocument } from '../screens/view-patient/tabs/DocumentsTab'
import type { ResponseFromEligibility } from '../mutations/eligibility/VerifyUserEligibility'
import { getEligibilityType } from '../mutations/eligibility/VerifyUserEligibility'
import type { Course } from '../types/Courses'
import type { CheckInTask } from '../queries/outcomes/GetTasks'
import { getCheckInTask } from '../queries/outcomes/GetTasks'
import type { RemainingBulkResult } from '../mutations/booking/GetCarePlanRemainingBulkSessions'
import getCarePlanRemainingBulkSessions from '../mutations/booking/GetCarePlanRemainingBulkSessions'
import { getPatientRelationship, getTopicsForPatient } from './externalCalls'

export const toDocument = (document: Document): DisplayDocument => {
  const isPatientPacket: boolean = document.documentType === 'PATIENT_PACKET'
  const isSeparateLoginConsentForm: boolean =
    document.documentType === 'SEPARATE_LOGIN_CONSENT'
  const addedBy: string = document.tags.find((t) => t.key === 'addedBy')?.value

  return {
    id: document.id,
    name: isPatientPacket
      ? 'Patient Packet'
      : isSeparateLoginConsentForm
      ? 'Separate Login Consent Form'
      : document.tags.find((t) => t.key === 'name')?.value,
    date: document.createdAt,
    addedBy:
      isPatientPacket || isSeparateLoginConsentForm ? 'Huddle Up' : addedBy,
  }
}

export const toSession = (
  appointment: any,
  patient: Patient | Partial<Patient>,
  planId: string,
  conditionId: string,
  provider?: Therapist
): Session => {
  const therapist: Therapist =
    provider || toTherapist(appointment.apptProviderData)

  return {
    patientId: patient.id,
    patientName: `${patient.firstName} ${patient.lastName}`,
    startTime: appointment.start,
    endTime: appointment.end,
    type: therapist.serviceLine,
    therapist: therapist,
    zoomLink: appointment?.event?.zoomJoinUrl || null,
    canceledAt: appointment.canceledAt,
    status: mapSessionStatus(appointment.status, patient.isIep),
    rating: appointment.rating,
    id: appointment.id,
    carePlanId: planId,
    conditionId: conditionId,
    name: mapSessionName(therapist.serviceLine, appointment.sessionType),
    scheduledBy: {
      name: appointment?.accountHolderName || 'someone else',
      id: appointment?.accountHolderId,
    },
    sessionType: appointment.sessionType,
  }
}

export const toPatient = async (
  patient: any,
  user: User | Partial<User>
): Promise<Patient> => {
  const clientId: string = user.data.clientId
  const requireEligibility: boolean = user.data.clientData?.requireEligibility
  const isEmployer = user.data.clientData.clientType === 'EMPLOYER'
  const isAccountHolderEligible = user.data.isEligible
  let eligibilityFields: ResponseFromEligibility = null

  let isDisabled = false
  let isEligible = false

  // decide on eligibility
  if (requireEligibility) {
    if (isEmployer) {
      isEligible = isAccountHolderEligible
      isDisabled = !isEligible
    } else {
      const responseFromEligibility: ResponseFromEligibility =
        await getEligibilityType(
          clientId,
          patient.firstName,
          patient.lastName,
          patient.dateOfBirth,
          patient?.studentId || patient?.email
        )

      if (
        !responseFromEligibility.personId ||
        (!responseFromEligibility.isEligible && !responseFromEligibility.isIep)
      )
        isDisabled = true

      isEligible = responseFromEligibility.isEligible
      eligibilityFields = responseFromEligibility
    }
  }

  const allProductsAreIEP = user.products.every((p: Product) => p.isIep)
  const isEligibilityOn = user.data.clientData?.requireEligibility
  const isIepOnly =
    eligibilityFields?.isIep &&
    ((isEligibilityOn ? !eligibilityFields?.isEligible : false) ||
      allProductsAreIEP)

  const conditions: Condition[] = []
  let language = null
  let insurance = patient?.unassociatedInsuranceMemberships?.[0] ?? null
  let insuranceVerified = false

  for (const condition of patient?.conditions || []) {
    const carePlans: CarePlan[] = []

    for (const plan of condition?.carePlans || []) {
      const serviceTypeDisplayName: string = SERVICE_LINES_ARRAY.find(
        (line: ServiceLine) => line.serviceType === plan.serviceType
      )?.displayName

      const sessions: Session[] = []

      // get sessions of patient
      if (plan.appointments?.length)
        for (let i = 0; i < plan.appointments?.length; i++) {
          const appointment = plan.appointments[i]

          // expect the following session statuses should not show “IEP meeting”, “Direct Supervision”, and “Created in error”
          if (
            [
              'iep_meeting_attendance',
              'direct_supervision',
              'created_in_error',
            ].includes(appointment.status)
          )
            continue

          sessions.push(
            toSession(
              appointment,
              { ...patient, isIep: eligibilityFields?.isIep },
              plan.id,
              condition.id
            )
          )
        }

      const directSessionDurations: number[] = []
      if (plan?.directSession_30) directSessionDurations.push(30)
      if (plan?.directSession_60) directSessionDurations.push(60)
      if (!directSessionDurations.length) directSessionDurations.push(30)

      if (!language) language = plan.language
      if (!insurance) insurance = plan.primaryInsuranceMembership

      const bulkModelResult: RemainingBulkResult =
        await getCarePlanRemainingBulkSessions({
          carePlanId: plan.id,
        })

      const sortedSessions = sessions.sort(
        (a: Session, b: Session) =>
          new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf()
      )

      if (plan.insuranceEligible) insuranceVerified = true

      carePlans.push({
        displayName: serviceTypeDisplayName,
        id: plan.id,
        providerId: plan.providerId,
        sessions: sortedSessions,
        remainingSessions: plan?.remainingFreeSessions,
        allowedSessions: plan.allowedSessions,
        bookingNonSponsoredInformationConfirmed:
          plan.bookingNonSponsoredInformationConfirmed &&
          Boolean(user.paymentMethod),
        billingInformationConfirmed: plan.billingInformationConfirmed,
        dontHaveInsurance: plan.dontHaveInsurance,
        directSessionDurations,
        productId: plan.productId,
        bulkModel: bulkModelResult,
      })
    }

    const sortedCarePlans = carePlans.sort((a: CarePlan, b: CarePlan) => {
      const orderA = SERVICE_LINES_ARRAY.find(
        (sl) => sl.displayName === a.displayName
      ).orderId
      const orderB = SERVICE_LINES_ARRAY.find(
        (sl) => sl.displayName === b.displayName
      ).orderId

      // First, compare by orderId
      if (orderA < orderB) return -1
      if (orderA > orderB) return 1

      // If orderId is equal, compare by allowedSession
      return b.allowedSessions - a.allowedSessions
    })

    conditions?.push({
      carePlans: sortedCarePlans,
      id: condition.id,
      isIep: user.products.some(
        (p: Product) =>
          p.isIep && carePlans.some((cp: CarePlan) => cp.productId === p.id)
      ),
    })
  }

  const insuranceInfo: Insurance = insurance
    ? {
        insurance: insurance.payer || {
          name: 'Other',
          id: 'other',
          other: true,
        },
        apartmentUnit: insurance.insuredAddressLine_2,
        backInsurance: insurance.backUrl,
        birthDate: convertBackendDateToFrontend(insurance.insuredDateOfBirth),
        city: insurance.insuredCity,
        firstName: insurance.insuredFirstName,
        frontInsurance: insurance.frontUrl,
        gender: capitalize(insurance.insuredGenderAtBirth),
        groupId: insurance.groupNumber,
        lastName: insurance.insuredLastName,
        memberId: insurance.memberId,
        relationship: insurancePatientRelationships.find(
          (rel: InsuranceRelationship) =>
            rel.key === insurance.relationshipToInsured
        ),
        state: states.find((s: State) => s.abbrev === insurance.insuredState)
          ?.name,
        streetAddress: insurance.insuredAddressLine_1,
        zip: insurance.insuredZipCode,
        id: insurance.id,
        otherInsuranceName: insurance?.payerNameEnteredByPatient,
        verified: insuranceVerified,
        isMedicaid:
          insurance?.payer?.name === 'Medicaid' ||
          insurance?.payer?.insuranceType === 'Medicaid',
      }
    : null

  // get topics of interest for each patient
  const topicsOfInterests: ResourceTopic[] = await getTopicsForPatient(
    patient.id
  )

  // get task for each patient
  const checkInTask: CheckInTask = await getCheckInTask(patient.personId)

  const patientGender = capitalize(patient.gender)

  const relationship = await getPatientRelationship(
    user.data.personId,
    patient.personId
  )

  return {
    id: patient.id,
    personId: patient?.personId,
    isIep: eligibilityFields?.isIep,
    isIepOnly: isIepOnly,
    isDisabled,
    isEligible,
    locationId: eligibilityFields?.locationId || null,
    firstName: patient.firstName,
    lastName: patient.lastName,
    preferredName: patient.preferredName,
    age: getAgeFromBirthdate(patient.dateOfBirth),
    birthDate: convertBackendDateToFrontend(patient.dateOfBirth),
    gender: genderOptions.includes(patientGender) ? patientGender : null,
    genderIdentity: getGenderIdentity(patient.genderIdentity),
    preferredPronouns: patient.pronouns,
    preferredLanguage: language,
    relationship,
    race: raceOptions.find((r: Race) => r.key === patient?.race),
    insurance: insuranceInfo,
    unassociatedInsurance: Boolean(insuranceInfo?.otherInsuranceName),
    streetAddress: patient.addressLine_1,
    apartmentUnit: patient.addressLine_2,
    city: patient.city,
    state: patient?.state ? toState(patient.state) : null,
    zip: patient.zipCode,
    conditions,
    billingCity: patient.billingInformation?.billingCity || '',
    billingState: toState(patient.billingInformation?.billingState) || '',
    billingZip: patient.billingInformation?.billingZipCode || '',
    billingStreetAddress:
      patient.billingInformation?.billingAddressLine_1 || '',
    billingApartmentUnit:
      patient.billingInformation?.billingAddressLine_2 || '',
    email:
      user.data.clientData.clientType !== 'EDUCATION' && requireEligibility
        ? eligibilityFields?.externalId
        : patient.email,
    createdAt: patient.createdAt,
    studentId:
      user.data.clientData.clientType === 'EDUCATION' && requireEligibility
        ? eligibilityFields?.externalId
        : patient?.studentId,
    timeZone: patient?.timezone || fallbackTimeZone,
    emergencyContact: [
      patient.emergencyContactFirstName,
      patient.emergencyContactLastName,
      patient.emergencyContactPhoneNumber,
      patient.emergencyContactRelationship,
    ].some((e) => !e)
      ? null
      : {
          firstName: patient.emergencyContactFirstName,
          lastName: patient.emergencyContactLastName,
          phoneNumber: patient.emergencyContactPhoneNumber,
          relationship: patient.emergencyContactRelationship,
        },
    interests: topicsOfInterests,
    sponsoredSessionTaken: patient.sponsoredSessionTaken,
    userId: patient.userId,
    hasSeparateLogin: Boolean(patient.invitedAt && patient.userId),
    isSeparateLogin: Boolean(
      user.data.id === patient.userId && patient.invitedAt
    ),
    separateLoginInvitedAt: patient.invitedAt,
    ableToBookAndCancel: patient.ableToBookAndCancel,
    invitedForSeparateLogin: patient.invitedAt && !patient.userId,
    checkInTask,
    allowedToBook: patient.allowedToBook,
  }
}

export const toState = (givenState: string) => {
  if (!givenState) return ''

  const state = states.find(
    (s: State) => s?.name === givenState || s?.abbrev.endsWith(givenState)
  )

  return state?.name || givenState
}

export const toUserData = (user: any): UserInfo => ({
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  id: user.id,
  preferredName: user.preferredFirstName,
  phoneNumber: user.phoneNumber,
  clientId: user.clientId,
  whatBringsYouHere: options.filter((i: InterestOption) =>
    user?.whatBringsYouHere?.includes(i.key)
  ),
  clientData: user.clientData,
  isEligible: user.isEligible,
  personId: user.personId,
})

export const toTherapist = (provider: any): Therapist => {
  const fullName = `${provider.firstName} ${provider.lastName}`

  return {
    id: provider.id,
    name: fullName,
    src: `${import.meta.env.VITE_DOTCOM_URL}/${provider?.avatar}`,
    languages: provider.languages?.map((obj: any) => obj.language) || [],
    licenseCredentials:
      provider.licenses?.map((license: any) => license.jurisdiction) || [],
    networkInformation: provider?.network?.some(
      (n: any) =>
        n.networkStatus.value === 0 &&
        n.networkStatus.description === 'in_network'
    )
      ? THERAPIST_NETWORK_INFORMATIONS.inNetwork
      : THERAPIST_NETWORK_INFORMATIONS.outOfNetwork,
    serviceLine: SERVICE_LINES_ARRAY.find(
      (line: ServiceLine) =>
        line.serviceType === (provider?.serviceLine || provider?.serviceType)
    )?.displayName,
    description: provider.intro || '',
    email: provider.email,
    preferredName: provider.preferredPatientFacingFullName || fullName,
    firstAvailability: provider.firstAvailability,
  }
}

export const toProduct = (product: any): Product => {
  const serviceLine = SERVICE_LINES_ARRAY.find(
    (sl: ServiceLine) => sl.serviceId === product.serviceLineId
  )

  if (!serviceLine) return null

  return {
    additionalSessions: product.additionalSessions,
    id: product.id,
    name: product?.name,
    serviceLine,
    isIep: product.isIEP,
    claimToPatient: product.claimToPatient,
  }
}

export const toResource = (resource: any): Resource => ({
  id: resource.id,
  imageSrc: resource.coverUrl,
  title: resource.title,
  description: resource.description,
  format: resourceFormatOptions.find(
    (rf: ResourceFormat) => rf.id === resource.resourceType
  ),
  duration: resource.duration,
  isBookmarked: resource.bookmarked,
  previewText: resource.previewText,
  type: 'MULTIMEDIA',
  audiences: resource.audiences,
  serviceLines: resource.serviceLines,
  topicKeys: resource.topics,
})

export const toCourse = (course: any): Course => ({
  id: course.id,
  imageSrc: course.coverUrl,
  title: course.title,
  description: course.description,
  numberOfVideos: course.modulesCount,
  teacherName: course.therapistName,
  teacherCredentials: course.therapistTitle,
  isBookmarked: course.bookmarked,
  previewText: course.previewText,
  type: 'COURSE',
  courseProgresses: course.courseProgresses.map((cp: any) => ({
    status: resourceStatusOptions.find((s) =>
      cp.progress === 100 ? s.id === 'completed' : s.id === 'in_progress'
    ),
    completedAt: new Date(cp.completedAt).toLocaleDateString(),
    completePercentage: cp.progress,
    patient: cp.patient || null,
  })),
  audiences: course.audiences,
  serviceLines: course.serviceLines,
  topicKeys: course.topicKeys,
  format: 'COURSE',
})

export const toPreviewResource = (resource: any): PreviewResource => ({
  contentUrl: resource.contentUrl,
  description: resource.description,
  duration: resource.duration,
  format: resourceFormatOptions.find(
    (rf: ResourceFormat) => rf.id === resource.resourceType
  ),
  id: resource.id,
  imageSrc: resource.coverUrl,
  title: resource.title,
  topicKeys: resource.topics,
  isBookmarked: resource.bookmarked,
  type: 'MULTIMEDIA',
  audiences: resource.audiences,
  serviceLines: resource.serviceLines,
})
