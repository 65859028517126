import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import {
  addDays,
  addMonths,
  eachDayOfInterval,
  format,
  getDay,
  getMonth,
  isPast,
  isSameDay,
  isSameMonth,
  isToday,
  setMonth,
  startOfMonth,
} from 'date-fns'

import { useGetAppointmentSlots } from '../../../queries/booking/AppointmentSlots'
import { DAY_LABELS, MONTH_LABELS } from '../../../constants/values'
import type { DisplayDate, TimeSlot } from '../../../types/Booking'
import { newDate } from '../../../helpers/generic'
import { usePatient } from '../../../contexts/PatientProvider'

interface AppointmentAvailabilityCalendarProps {
  outsideGivenSelectedDate: Date
  onChangeInternalSelectedDate:
    | React.Dispatch<React.SetStateAction<Date>>
    | ((date: Date) => any)
  sessionDuration: number
}

const AppointmentAvailabilityCalendar: React.FC<
  AppointmentAvailabilityCalendarProps
> = ({
  outsideGivenSelectedDate,
  onChangeInternalSelectedDate,
  sessionDuration,
}) => {
  const { patient } = usePatient()
  const { providerId } = useParams()
  const [selectedDate, setSelectedDate] = useState<Date>(
    outsideGivenSelectedDate
  )
  const [currentDateCursor, setCurrentDateCursor] = useState<Date>(
    outsideGivenSelectedDate
  )
  const [dates, setDates] = useState<DisplayDate[]>([])

  useEffect(() => {
    setSelectedDate(outsideGivenSelectedDate)
    setCurrentDateCursor(outsideGivenSelectedDate)
  }, [outsideGivenSelectedDate])

  const currentMonth = currentDateCursor.getMonth()
  const currentYear = currentDateCursor.getFullYear()
  const currentMonthLabel = MONTH_LABELS[currentMonth]

  const { data: appointmentSlots } = useGetAppointmentSlots({
    providerId,
    sessionDuration,
    patient,
  })

  useEffect(() => {
    if (!appointmentSlots || !selectedDate || !currentDateCursor || !patient)
      return

    let startDate = startOfMonth(currentDateCursor)
    const daysNeededForLastMonth = getDay(startDate)
    startDate = addDays(startDate, -daysNeededForLastMonth)

    // generate 6 full weeks to keep the formatting consistent
    const endDate = addDays(startDate, 41)

    const allDates: DisplayDate[] = eachDayOfInterval({
      start: startDate,
      end: endDate,
    }).map((date) => ({
      date,
      isCurrentMonth: isSameMonth(currentDateCursor, date),
      isSelected: selectedDate && isSameDay(selectedDate, date),
      isNotSelectable:
        (isPast(date) && !isToday(date)) ||
        !appointmentSlots.some((slot: TimeSlot) =>
          isSameDay(newDate(slot.startOriginal, patient.timeZone), date)
        ),
    }))

    setDates(allDates)
  }, [currentDateCursor, selectedDate, appointmentSlots, patient])

  useEffect(() => {
    onChangeInternalSelectedDate(selectedDate)
  }, [selectedDate])

  const handleCalendarDayClick = (day: DisplayDate) => {
    if (day.isNotSelectable) return

    setSelectedDate(day.date)

    if (!day.isCurrentMonth) {
      const selectedMonth = getMonth(day.date)
      setCurrentDateCursor(setMonth(currentDateCursor, selectedMonth))
    }
  }

  const handleGoToPreviousMonth = () => {
    setCurrentDateCursor(addMonths(currentDateCursor, -1))
  }

  const handleGoToNextMonth = () => {
    setCurrentDateCursor(addMonths(currentDateCursor, 1))
  }

  return (
    <div className="flex flex-col gap-2 self-stretch rounded-lg border border-components-fields px-2 pt-4 pb-2 sm:gap-6 sm:p-4">
      <div className="flex flex-row justify-between">
        <button onClick={handleGoToPreviousMonth} className="text-text-label">
          <ChevronLeftIcon className="h-4 w-4 sm:h-5 sm:w-5" />
        </button>
        <div className="flex flex-row items-center justify-center gap-1">
          <p className="text-xs font-semibold text-text-primary sm:text-base">
            {currentMonthLabel}
          </p>
          <p className="text-xs font-semibold text-text-primary sm:text-base">
            {currentYear}
          </p>
        </div>
        <button onClick={handleGoToNextMonth} className="text-text-label">
          <ChevronRightIcon className="h-4 w-4 sm:h-5 sm:w-5" />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-x-1.5 gap-y-2 sm:gap-x-6 sm:gap-y-4">
        {React.Children.toArray(
          DAY_LABELS.map((day) => (
            <p className="p-2.5 text-xs font-normal text-text-label sm:p-3 sm:text-sm">
              {day}
            </p>
          ))
        )}
        {React.Children.toArray(
          dates.map((day) => (
            <button
              className={`rounded-full p-2.5 sm:p-3 ${
                day.isSelected ? 'bg-components-paleBlue text-cta-default' : ''
              } ${day.isNotSelectable ? 'cursor-default' : ''}`}
              onClick={() => handleCalendarDayClick(day)}
            >
              <p
                className={`text-xs sm:text-sm ${
                  day.isNotSelectable
                    ? 'font-normal text-text-placeholder'
                    : day.isSelected
                    ? 'font-semibold text-cta-default'
                    : 'font-normal text-text-primary'
                }`}
              >
                {format(day.date, 'd')}
              </p>
            </button>
          ))
        )}
      </div>
    </div>
  )
}

export default AppointmentAvailabilityCalendar
