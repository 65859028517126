import type { InsuranceRelationship } from '../types/Insurance'
import type { State } from '../types/State'
import type { GenderIdentity, Race } from '../types/Profile'
import type { RelationshipType } from '../types/Patient'
import type { ResourceFormat, ResourceStatus } from '../types/Resources'
import BOOK_ICON from '../assets/icons/Book.svg'
import AUDIO_ICON from '../assets/icons/Audio.svg'
import FILE_ICON from '../assets/icons/Document.svg'
import VIDEO_ICON from '../assets/icons/Video.svg'
import CREDIT_CARD_ICON from '../assets/icons/GreenCreditCard.svg'
import VISA_ICON from '../assets/icons/Visa.svg'
import MASTERCARD_ICON from '../assets/icons/Mastercard.svg'
import DISCOVER_ICON from '../assets/icons/Discover.svg'
import AMERICAN_EXPRESS_ICON from '../assets/icons/AmericanExpress.svg'

export const CHANGE_PROVIDER_SURVEY_ID = 'e9ceb68c-e44f-4ddc-908e-9fae776552ce'

export const DASHBOARD_RATING_SURVEY_ID = '07c562f6-65f2-4cf2-81e8-4fd2bf2227c5'

export const genderOptions: string[] = ['Male', 'Female']

export const languages: string[] = [
  'English',
  'American Sign Language',
  'French',
  'German',
  'Mandarin Chinese',
  'Spanish',
  'Tagalog',
  'Vietnamese',
]

export const genderIdentities: GenderIdentity[] = [
  { displayName: 'Male/Man', key: 'male' }, // male
  { displayName: 'Female/Woman', key: 'female' }, // female
  { displayName: 'TransFemale/TransWoman', key: 'trans_woman' }, // trans_woman
  { displayName: 'TransMale/TransMan', key: 'trans_man' }, // trans_man
  {
    displayName: 'Genderqueer/Gender nonconforming',
    key: 'queer_nonconforming', // queer_nonconforming
  },
  { displayName: 'Non binary', key: 'non_binary' }, // non_binary
  { displayName: 'Decline to answer', key: 'unknown' }, // unknown
]

export const insurancePatientRelationships: InsuranceRelationship[] = [
  { displayName: 'Spouse', key: '01' },
  { displayName: 'Self', key: '18' },
  { displayName: 'Child ', key: '19' },
  { displayName: 'Step Child', key: 'C4' },
  { displayName: 'Grandchild', key: '95' },
  { displayName: 'Foster Child', key: '10' },
  { displayName: 'Ward of the Court', key: '15' },
  { displayName: 'Handicapped Dependent', key: '22' },
  { displayName: 'Sponsored Dependent', key: '23' },
  { displayName: 'Significant Other', key: '29' },
  { displayName: 'Mother', key: '32' },
  { displayName: 'Father', key: '33' },
  { displayName: 'Life Partner', key: '53' },
]

export const relationshipOptionsProfile: RelationshipType[] = [
  { name: 'Myself', key: 'myself' },
  { name: 'Parent', key: 'parent' },
  { name: 'Guardian', key: 'guardian_to_patient' },
]

export const emergencyContactRelationships: RelationshipType[] = [
  { name: 'Parent', key: 'parents' },
  { name: 'Guardian', key: 'guardian' },
  { name: 'Grandparent', key: 'grandparent' },
  { name: 'Relative', key: 'relative' },
  { name: 'Family Friend', key: 'family_friend' },
  { name: 'Other', key: 'other' },
]

export const states: State[] = [
  { name: 'Alabama', abbrev: 'AL' },
  { name: 'Alaska', abbrev: 'AK' },
  { name: 'Arizona', abbrev: 'AZ' },
  { name: 'Arkansas', abbrev: 'AR' },
  { name: 'California', abbrev: 'CA' },
  { name: 'Colorado', abbrev: 'CO' },
  { name: 'Connecticut', abbrev: 'CT' },
  { name: 'Delaware', abbrev: 'DE' },
  { name: 'District of Columbia', abbrev: 'DC' },
  { name: 'Florida', abbrev: 'FL' },
  { name: 'Georgia', abbrev: 'GA' },
  { name: 'Hawaii', abbrev: 'HI' },
  { name: 'Idaho', abbrev: 'ID' },
  { name: 'Illinois', abbrev: 'IL' },
  { name: 'Indiana', abbrev: 'IN' },
  { name: 'Iowa', abbrev: 'IA' },
  { name: 'Kansas', abbrev: 'KS' },
  { name: 'Kentucky', abbrev: 'KY' },
  { name: 'Louisiana', abbrev: 'LA' },
  { name: 'Maine', abbrev: 'ME' },
  { name: 'Maryland', abbrev: 'MD' },
  { name: 'Massachusetts', abbrev: 'MA' },
  { name: 'Michigan', abbrev: 'MI' },
  { name: 'Minnesota', abbrev: 'MN' },
  { name: 'Mississippi', abbrev: 'MS' },
  { name: 'Missouri', abbrev: 'MO' },
  { name: 'Montana', abbrev: 'MT' },
  { name: 'Nebraska', abbrev: 'NE' },
  { name: 'Nevada', abbrev: 'NV' },
  { name: 'New Hampshire', abbrev: 'NH' },
  { name: 'New Jersey', abbrev: 'NJ' },
  { name: 'New Mexico', abbrev: 'NM' },
  { name: 'New York', abbrev: 'NY' },
  { name: 'North Carolina', abbrev: 'NC' },
  { name: 'North Dakota', abbrev: 'ND' },
  { name: 'Ohio', abbrev: 'OH' },
  { name: 'Oklahoma', abbrev: 'OK' },
  { name: 'Oregon', abbrev: 'OR' },
  { name: 'Pennsylvania', abbrev: 'PA' },
  { name: 'Rhode Island', abbrev: 'RI' },
  { name: 'South Carolina', abbrev: 'SC' },
  { name: 'South Dakota', abbrev: 'SD' },
  { name: 'Tennessee', abbrev: 'TN' },
  { name: 'Texas', abbrev: 'TX' },
  { name: 'Utah', abbrev: 'UT' },
  { name: 'Vermont', abbrev: 'VT' },
  { name: 'Virginia', abbrev: 'VA' },
  { name: 'Washington', abbrev: 'WA' },
  { name: 'West Virginia', abbrev: 'WV' },
  { name: 'Wisconsin', abbrev: 'WI' },
  { name: 'Wyoming', abbrev: 'WY' },
]

export const preferredPronouns: string[] = [
  null,
  'He/Him',
  'She/Her',
  'They/Them',
]

export const genericErrors = {
  'auth/user-not-found': 'User does not exist.',
  'auth/wrong-password':
    'Wrong email and password combination. Try again or click forgot password below to reset it.',
  'auth/email-unverified': 'Please verify your email before signing in.',
  'auth/something-wrong':
    'Something went wrong, please try again later or contact us at support@huddleupcare.com if you have any questions.',
  'auth/email-already-in-use':
    'Looks like you already have an account. Please login or recover your password on the sign-in screen.',
  'auth/too-many-requests':
    'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
}

export const createAccountErrors = {
  'auth/email-already-in-use':
    'Looks like you already have an account. Please login or recover your password by clicking "Forgot my password".',
  'auth/unauthorized-continue-uri': 'Domain not whitelisted by project.',
}

export const DAY_LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
export const MONTH_LABELS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const LICENSE_CREDENTIAL = {
  speech_therapy: 'CCC-SLP',
  occupational_therapy: 'OT/R',
  audiology: 'CCC-A',
  social_work: 'ACSW',
  psychology: 'NCSP',
  applied_behavior_analysis: 'BCBA',
  special_education: 'Sp Ed',
}

export const resourceFormatOptions: ResourceFormat[] = [
  {
    id: 'ARTICLE',
    displayName: 'Article',
    activityName: 'article',
    icon: BOOK_ICON,
  },
  {
    id: 'AUDIO',
    displayName: 'Audio',
    activityName: 'audio',
    icon: AUDIO_ICON,
  },
  {
    id: 'PRINTABLE',
    displayName: 'Printable',
    activityName: 'printable',
    icon: FILE_ICON,
  },
  {
    id: 'VIDEO',
    displayName: 'Video',
    activityName: 'video',
    icon: VIDEO_ICON,
  },
]

export const resourceStatusOptions: ResourceStatus[] = [
  {
    id: 'not_started',
    name: 'Not Started',
    orderId: 1,
  },
  {
    id: 'in_progress',
    name: 'In Progress',
    orderId: 2,
  },
  {
    id: 'completed',
    name: 'Completed',
    orderId: 3,
  },
]

export const raceOptions: Race[] = [
  { displayName: null, key: null },
  { displayName: 'Hispanic', key: 'R1' },
  { displayName: 'White alone, non-Hispanic', key: 'R2' },
  {
    displayName: 'Black or African American alone, non-Hispanic',
    key: 'R3',
  },
  {
    displayName: 'American Indian and Alaska Native alone, non-Hispanic',
    key: 'R4',
  },
  { displayName: 'Asian alone, non-Hispanic', key: 'R5' },
  {
    displayName:
      'Native Hawaiian and Other Pacific Islander alone, non-Hispanic',
    key: 'R6',
  },
  { displayName: 'Some Other Race alone, non-Hispanic', key: 'R7' },
]

// https://stripe.com/docs/card-brand-choice#card-brands
export const CARD_BRAND_TO_ICON: Record<string, string> = {
  amex: AMERICAN_EXPRESS_ICON,
  discover: DISCOVER_ICON,
  mastercard: MASTERCARD_ICON,
  visa: VISA_ICON,
  unknown: CREDIT_CARD_ICON,
}

export const CARD_BRAND_TO_TEXT: Record<string, string> = {
  amex: 'American Express Card',
  discover: 'Discover Card',
  mastercard: 'Mastercard',
  visa: 'Visa Card',
}

export const CARD_SAVING_ERROR_TEXT =
  'Something went wrong while saving the card information. Please try again or contact us at support@huddleupcare.com if you have any questions.'

export const EXTERNAL_LINKS = {
  RESOURCES:
    'https://assets-global.website-files.com/660d846a9d2c22f168518817/66158af6e87e27121ec69bf9_Mental%20Health%20Resources.pdf',
  TERMS_CONDITIONS:
    'https://assets-global.website-files.com/660d846a9d2c22f168518817/662ad3e84ffebdb9adae3369_Terms%20and%20Conditions.pdf',
  PRIVACY_POLICY:
    'https://assets-global.website-files.com/660d846a9d2c22f168518817/662ad5c41942f8063ef9eb59_Notice%20of%20Privacy%20Practices.pdf',
  CALIFORNIA_PRIVACY_POLICY:
    'https://info.huddleupcare.com/hubfs/California%20Privacy%20Policies.pdf',
  CONSENT_FORM:
    'https://assets-global.website-files.com/660d846a9d2c22f168518817/662ad3e8312c4f75c47d4b25_Teletherapy%20Informed%20Consent.pdf',
  DOT_ACADEMY: 'https://www.huddleupcare.com/educator-hub',
}
