import React from 'react'
import { Link, Navigate } from 'react-router-dom'

import AppLogo from '../../../components/AppLogo'
import { useGetClientInfo } from '../../../queries/onboarding/GetClientInfo'

const SubmittedContactInfo: React.FC = () => {
  const { data: clientInfo } = useGetClientInfo()

  if (!clientInfo?.requireEligibility) return <Navigate to="/sign-in" />

  return (
    <div className="flex flex-col gap-8 text-text-primary">
      {/* Header */}
      <AppLogo classes="flex self-center" />
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="whitespace-pre-wrap text-center text-lg font-semibold">
          Thank you for submitting your information!
        </p>
        <p className="text-center text-sm">
          Our support team will contact you to get this figured out!
        </p>
      </div>

      <div className="flex justify-center">
        <Link to={'/sign-in'} className="text-center font-semibold underline">
          Back to Sign In
        </Link>
      </div>
    </div>
  )
}

export default SubmittedContactInfo
