import { useEffect } from 'react'

import type { PreviewResource } from '../types/Resources'
import type { PreviewCourse } from '../types/Courses'
import trackMixPanel, { MIXPANEL_EVENT } from './useMixPanel'
import { useAuth } from '../contexts/AuthProvider'
import { convertSecondsToHumanReadable } from '../helpers/utils'

type AnyResource = PreviewResource | PreviewCourse

const useTrackResourceVisit = (resource: AnyResource) => {
  const user = useAuth()

  useEffect(() => {
    if (!resource) return

    let seconds = 0
    const interval = setInterval(() => {
      seconds += 1
    }, 1000)

    return () => {
      clearInterval(interval)
      trackMixPanel({
        eventName: MIXPANEL_EVENT.RESOURCE_COMPLETED,
        properties: {
          id: resource.id,
          accountId: user.user.data.id,
          name: resource.title,
          timeSpent: convertSecondsToHumanReadable(seconds),
          format: resource.format,
          audiences: resource.audiences,
          serviceLines: resource.serviceLines,
          topicOfInterest: resource.topicKeys,
        },
      })
    }
  }, [resource])

  const onClickOnRecommended = (clickedResource: AnyResource) => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.RESOURCE_VIEWED,
      properties: {
        source: `Recommended`,
        recommendedByName: resource.title,
        recommendedById: resource.id,
        id: clickedResource.id,
        accountId: user.user.data.id,
        name: clickedResource.title,
        format: resource.format,
        audiences: resource.audiences,
        serviceLines: resource.serviceLines,
        topicOfInterest: resource.topicKeys,
      },
    })
  }

  return [onClickOnRecommended]
}

export default useTrackResourceVisit