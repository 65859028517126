import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import type { AccountInfoData } from '../../types/Dashboard'

interface UpdateCurrentUserProps {
  userId: string
  data: Partial<AccountInfoData>
  clientId: string
}

const updateCurrentUser = async ({
  userId,
  data,
  clientId,
}: UpdateCurrentUserProps) => {
  const token: string = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/users/${userId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user: {
          client_id: clientId,
          first_name: data.firstName,
          last_name: data.lastName,
          phone_number: data.phoneNumber,
        },
      }),
    }
  ).then((response) => response.json())

  if (result?.error) throw new Error('Something went wrong.')
}

export const useUpdateCurrentUser = () =>
  useMutation((params: UpdateCurrentUserProps) => updateCurrentUser(params))
