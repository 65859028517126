import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

const activateAccountHolderAndClaim = async (): Promise<boolean> => {
  try {
    const searchParams = new URLSearchParams(window.location.search)
    const securedToken = searchParams.get('securedToken')

    const token = await auth.currentUser.getIdToken()

    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_PERSONS_URL
      }v1/user-actions/activate-account-holder-and-claim`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          securedToken,
        }),
      }
    )

    if (!response.ok) throw new Error()

    return true
  } catch (error) {
    throw new Error("Couldn't mark user as verified")
  }
}

export const useActivateAccountHolderAndClaim = () =>
  useMutation(() => activateAccountHolderAndClaim())
