import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import {
  convertFrontendDateToBackend,
  frontendToBackendTz,
} from '../../helpers/generic'
import { toCamelCase } from '../../helpers/serializers'
import { toPatient } from '../../helpers/transformers'
import type { CarePlan, Condition, Patient } from '../../types/Patient'
import type { ServiceLine } from '../../types/ServiceLine'
import type { User } from '../../types/User'
import { states } from '../../constants/values'
import type { State } from '../../types/State'
import { updatePatientRelationship } from '../../helpers/externalCalls'

interface UpdateProfileProps {
  user: User
  patient: Patient | Partial<Patient>
}

const toNewPatientData = (patient: Patient | Partial<Patient>) => ({
  first_name: patient.firstName,
  last_name: patient.lastName,
  date_of_birth: patient.birthDate
    ? convertFrontendDateToBackend(patient.birthDate)
    : undefined,
  gender: patient.gender?.toLowerCase(),
  gender_identity: patient.genderIdentity?.key,
  pronouns: patient.preferredPronouns,
  city: patient.city,
  zip_code: patient.zip,
  address_line_1: patient.streetAddress,
  address_line_2: patient.apartmentUnit,
  allowed_to_book: patient.allowedToBook,
  state:
    states?.find((s: State) => s?.name === patient?.state)?.abbrev ||
    patient?.state,
  preferred_name: patient.preferredName,
  student_id: patient.studentId,
  location_id: patient.locationId,
  email: patient.email,
  time_zone: patient.timeZone
    ? frontendToBackendTz(patient.timeZone)
    : undefined,
  race: patient?.race?.key,
  conditions_attributes: patient?.conditions?.map((c: Condition) => {
    return {
      id: c.id,
      care_plans_attributes: c?.carePlans?.map(
        (plan: CarePlan & { serviceType: string; language: string }) => ({
          id: plan.id,
          service_type:
            plan?.serviceType ||
            SERVICE_LINES_ARRAY.find(
              (line: ServiceLine) => line.displayName === plan.displayName
            )?.serviceType,
          language: patient.preferredLanguage,
          provider_id: plan.providerId,
          patient_onboarding_note: plan?.notes,
          billing_information_confirmed: plan.billingInformationConfirmed,
          dont_have_insurance: plan.dontHaveInsurance,
          product_id: plan.productId,
        })
      ),
    }
  }),
  emergency_contact_first_name: patient?.emergencyContact?.firstName,
  emergency_contact_last_name: patient?.emergencyContact?.lastName,
  emergency_contact_relationship:
    patient?.emergencyContact?.relationship === 'parent'
      ? 'parents'
      : patient?.emergencyContact?.relationship,
  emergency_contact_phone_number: patient?.emergencyContact?.phoneNumber,
  billing_information: {
    billing_address_line_1: patient?.billingStreetAddress,
    billing_address_line_2: patient?.billingApartmentUnit,
    billing_city: patient?.billingCity,
    billing_state: patient?.billingState,
    billing_zip_code: patient?.billingZip,
  },
  person_id: patient?.personId,
  able_to_book_and_cancel: patient?.ableToBookAndCancel,
  invited_at: patient?.separateLoginInvitedAt,
  user_id: patient?.userId,
})

const updateProfile = async ({
  user,
  patient,
}: UpdateProfileProps): Promise<Patient> => {
  const token: string = await auth.currentUser.getIdToken()

  const newPatientData: any = toNewPatientData(patient)

  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/users/${user.data.id}/patients/${
      patient.id
    }`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        patient: { ...newPatientData, client_id: user.data.clientId },
      }),
    }
  ).then((response) => response.json())

  await updatePatientRelationship(
    user.data.personId,
    patient.personId,
    patient?.relationship?.key?.toUpperCase()
  )

  if (!result.id) throw new Error('Something went wrong.')

  return await toPatient({ ...toCamelCase(result) }, user)
}

export const useUpdateProfile = () =>
  useMutation((params: UpdateProfileProps) => updateProfile(params))
