import { auth } from '../../config/firebase'

export type CheckInTask = {
  completedAt: string
  nextSurveyIds: string[]
}

export const getCheckInTask = async (personId: string) => {
  try {
    const token: string = await auth.currentUser.getIdToken()
    const result = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_SURVEYS_URL
      }/v1/survey/getCheckIn?personId=${personId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    return result
  } catch (error) {
    return null
  }
}
