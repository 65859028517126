import { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthProvider'
import { useGetPayments } from '../queries/payments/GetPayments'

const useHasAnyPayments = () => {
  const [hasAnyPayments, setHasAnyPayments] = useState<boolean>(false)

  const { user } = useAuth()
  const { data: payments } = useGetPayments({
    user,
  })

  useEffect(() => {
    setHasAnyPayments(Boolean(payments?.length))
  }, [payments])

  return hasAnyPayments
}

export default useHasAnyPayments
