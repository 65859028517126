import { useQuery } from '@tanstack/react-query'

import type { PreviewCourse } from '../../types/Courses'
import { auth } from '../../config/firebase'
import { resourceStatusOptions } from '../../constants/values'
import type { User } from '../../types/User'
import { formatModuleDuration } from '../../helpers/utils'

interface GetCoursePreviewProps {
  courseId: string
  user: User
}

export const useGetCourseForPreview = (
  params: GetCoursePreviewProps,
  deps = params
) =>
  useQuery(['getCourseForPreview', deps], async () =>
    getCourseForPreview(params)
  )

const getCourseForPreview = async ({
  courseId,
  user,
}: GetCoursePreviewProps): Promise<PreviewCourse> => {
  try {
    const token: string = await auth.currentUser.getIdToken()

    const response: any = await fetch(
      `${
        import.meta.env.VITE_DOTCOM_JAVA_RESOURCES_URL
      }v2/display/courses/${courseId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => response.json())

    const videos = response.modules.map((module: any) => ({
      id: module.id,
      title: module.title,
      duration: formatModuleDuration(module.duration),
      status: 'not_started',
      imageSrc: module.coverUrl,
      contentUrl: module.contentUrl,
      referencedContent: module.supplementalResources.map((resource: any) => ({
        id: resource.id,
        name: resource.title,
        src: resource.coverUrl,
      })),
      description: module.description,
    }))

    return {
      id: courseId,
      title: response.title,
      description: response.description,
      courseProgresses: response.courseProgresses.map((cp: any) => ({
        completedAt: new Date(cp.completedAt).toLocaleDateString(),
        completePercentage: cp.progress,
        patient: cp.patientId
          ? user.roster.find((p) => p.id === cp.patientId)
          : null,
        status:
          cp.progress === 100
            ? resourceStatusOptions.find((s) => s.id === 'completed')
            : resourceStatusOptions.find((s) => s.id === 'in_progress'),
        completedVideoIds: cp.modulesCompleted,
      })),
      numberOfVideos: videos.length,
      videos: videos,
      teacherName: response.therapistName,
      teacherCredentials: response.therapistTitle,
      teacherDescription: response.therapistInfo,
      teacherSrc: response.therapistHeadshotDocumentUrl,
      isBookmarked: response.bookmarked,
      type: 'COURSE',
      coverUrl: response.coverUrl,
      audiences: response.audiences,
      serviceLines: response.serviceLines,
      topicKeys: response.topics,
      format: 'COURSE',
    }
  } catch (error) {
    return null
  }
}
